import { Tooltip } from '@mui/material'
import { ButtonIcon } from 'components'
import SpinnerLoader from 'components/spinner-loader/smallSpinnerLoader'
import { FaRegSquareCheck } from 'react-icons/fa6'
import { MdOutlineDisabledByDefault } from 'react-icons/md'

export function createFieldLabel(el: string) {
  const split = el.split('_')
  if (split.length !== 1) {
    let res = `${split[0][0].toUpperCase()}${split[0].slice(1)}`
    split.shift()
    split.forEach((el) => {
      res += ` ${el[0].toUpperCase()}${el.slice(1)}`
    })
    return res
  }
  return `${el[0].toUpperCase()}${el.slice(1)}`
}

export const isString = (column: string) => {
  switch (column) {
    case 'effective_cost':
      return false
    case 'list_cost':
      return false
    case 'list_unit_price':
      return false
    case 'pricing_quantity':
      return false
    case 'billed_cost':
      return false
    case 'usage_quantity':
      return false
    case 'billing_account_name':
      return false
    case 'billing_account_id':
      return false
    case 'billing_period_end':
      return false
    case 'billing_period_start':
      return false
    case 'charge_period_end':
      return false
    case 'charge_period_start':
      return false
    case 'consumed_quantity':
      return false
    case 'ec2.cpu_options_core_count':
      return false
    default:
      return true
  }
}
export function loadAutoCompleteIcon(type: string, compareType: string, tag: string) {
  // console.log(type)
  // console.log(compareType)
  // console.log(type === compareType)
  // console.log(tag)
  if (type === compareType) {
    if (isString(tag)) {
      return (
        <span>
          AC:
          <Tooltip title="Autocomplete available">
            <ButtonIcon>
              <FaRegSquareCheck size={20} color="green" />
            </ButtonIcon>
          </Tooltip>
        </span>
      )
    } return (
      <span>
        AC:
        <Tooltip title="Autocomplete unavailable">
          <ButtonIcon>
            <MdOutlineDisabledByDefault size={20} color="red" />
          </ButtonIcon>
        </Tooltip>
      </span>
    )
  } return <div />
}
export function reportLoadAutoCompleteIcon(
  type: string | undefined,
  tag: string,
  loaded: boolean,
  color: string,
) {
  if (isString(tag) && type === 'focus') {
    return (
      <SpinnerLoader isLoading={loaded} className="h-max w-fit" spinnerSize={15}>
        <span className={`flex flex-col text-xs h-full ${color}`}>
          AC:
          <Tooltip title="Autocomplete available">
            <ButtonIcon>
              <FaRegSquareCheck size={15} color="green" />
            </ButtonIcon>
          </Tooltip>
        </span>
      </SpinnerLoader>
    )
  } return (
    <span className="flex flex-col text-xs">
      AC:
      <Tooltip title="Autocomplete unavailable">
        <ButtonIcon>
          <MdOutlineDisabledByDefault size={15} color="red" />
        </ButtonIcon>
      </Tooltip>
    </span>
  )
}

export function getBgColor(tag?: string) {
  if (tag) {
    if (tag === 'focus') return 'bg-[#e1eedb]'
    return 'bg-[#def0f9]'
  } return 'bg-gray-300'
}
export function getBorderColor(tag?: string) {
  if (tag) {
    if (tag === 'focus') return 'border-[#5d6a57]'
    return 'border-sky-600'
  } return 'border-gray-500'
}
export function getTextColor(tag?: string) {
  if (tag) {
    if (tag === 'focus') return 'text-[#5d6a57]'
    return 'text-sky-600'
  } return 'text-gray-500'
}
export function getHoverBgColor(tag?: string) {
  if (tag) {
    if (tag === 'focus') return 'hover:bg-[#5d6a57]'
    return 'hover:bg-sky-600'
  } return 'hover:bg-gray-500'
}
export function getHoverTextColor(tag?: string) {
  if (tag) {
    if (tag === 'focus') return 'hover:text-[#e1eedb]'
    return 'hover:text-[#def0f9]'
  } return 'hover:text-gray-300'
}
