import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { toast, Bounce } from 'react-toastify'
import { LineChart } from '@mantine/charts'
import { graphQLAxios } from 'utils/axios'
import useAuthentication from 'hooks/useAuthentication'
import { getLastMonth } from 'features/projects/project-datepicker/helpFunction'
// import { CHART_COLORS } from 'constants/color.constants'
import { subAccountAvailabilityZoneCostTypeApiCall } from '../graphQlApiCalls'
import { ChartTooltip } from './ServiceCostsRegion'

const FinOpsColors = [
  '#668957', '#3E8E41', '#8B4513', '#FF6347', '#FF1493', '#32CD32', '#4682B4',
  '#FF8C00', '#FFD700', '#B0E57C', '#6A5ACD', '#C71585', '#D2691E', '#FF4500',
  '#F0E68C', '#DC143C'
]

type SubAccountAvailabilityZoneCostType = {
  availabilityZone: string
  billingPeriodStart: string
  provider: string
  region: string
  totalEffectiveCost: number
}

type chartDataType = {
  [key: string]: string | number
  date: string,
}
interface SubAccountAvailabilityZoneCostProps {
  id: string,
  height: number
}
type chartDataColors = { name: string, color: string }
export function SubAccountAvailabilityZoneCost({
  id,
  height
}: SubAccountAvailabilityZoneCostProps) {
  const timeWindow = getLastMonth()
  const [dataLoaded, setDataLoaded] = useState<boolean>(false)
  const [queryKey] = useState<string>(`initial-${id}`)
  const [data, setData] = useState<SubAccountAvailabilityZoneCostType[]>([])
  const [chartData, setChartData] = useState<chartDataType[]>([])
  const [chartDataColors, setChartDataColors] = useState<chartDataColors[]>([])

  const { currency } = useAuthentication()
  const [savedDelimiter, setSavedDelimiter] = useState<string>('en-US');
  const [savedGrouping, setSavedGrouping] = useState<string>('Yes');

  window.addEventListener('storage', () => {
    if (sessionStorage.getItem('delimiter') === ',') {
      setSavedDelimiter('de-DE')
    } else {
      setSavedDelimiter('en-US')
    }
    if (sessionStorage.getItem('grouping') !== null) setSavedGrouping(sessionStorage.getItem('grouping')!)
  })

  useEffect(() => {
    if (sessionStorage.getItem('delimiter') === ',') {
      setSavedDelimiter('de-DE')
    } else {
      setSavedDelimiter('en-US')
    }
    if (sessionStorage.getItem('grouping') !== null) setSavedGrouping(sessionStorage.getItem('grouping')!)
  }, [savedDelimiter, savedGrouping, sessionStorage.getItem('delimiter'), sessionStorage.getItem('grouping')])

  const { error } = useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      try {
        if (!dataLoaded) {
          const query =
            subAccountAvailabilityZoneCostTypeApiCall(timeWindow[0].toISOString().replace('T', ' ').replace('Z', ''), timeWindow[1].toISOString().replace('T', ' ').replace('Z', ''), 'N/A')
          await graphQLAxios
            .post('', { query })
            .then((res) => {
              setData(res.data.data.subAccountAvailabilityZoneCost)
              setDataLoaded(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                toast.error('No labels have been created.', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  transition: Bounce,
                })
              }
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })
  useEffect(() => {
    const newData: chartDataType[] = []
    const newDataColors: chartDataColors[] = []
    data.forEach((el) => {
      const idx = newData.findIndex((obj) => obj.date === el.billingPeriodStart)
      const idxColors = newDataColors.findIndex((obj) => obj.name === el.provider)
      if (idx === -1) {
        newData.push({
          date: el.billingPeriodStart,
          [el.provider]: el.totalEffectiveCost
        })
      } else {
        const newObj = newData[idx]
        newObj[el.provider] = el.totalEffectiveCost
        newData[idx] = newObj
      }
      if (idxColors === -1) {
        newDataColors.push({
          name: el.provider,
          color: FinOpsColors[newDataColors.length]
        })
      }
    })
    setChartData(newData.sort((a, b) => {
      return new Date(a.date as string).getDay() - new Date(b.date as string).getDay()
    }))
    setChartDataColors(newDataColors)
  }, [data])

  if (error) return <div>An error has occurred</div>
  return (
    <div className="h-full">
      <LineChart
        h={height}
        data={chartData}
        className="h-full"
        dataKey="date"
        series={chartDataColors}
        connectNulls
        valueFormatter={(value) => {
          return new Intl.NumberFormat(savedDelimiter, { useGrouping: savedGrouping === 'Yes' }).format(Number(value.toFixed(2)))
        }}
        tooltipProps={{
          // eslint-disable-next-line react/no-unstable-nested-components
          content: ({ label, payload }) => {
            return <ChartTooltip label={label} payload={payload} currency={currency} />
          }
        }}
        unit={currency.symbol}
      />
    </div>
  )
}
