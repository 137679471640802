import { Textarea } from '@mantine/core'
import { useState } from 'react'
import { WidgetData } from './widgetStatev2'

interface editCommentModalProps {
  isOpen: boolean,
  id: string,
  comment: string,
  setChangeCommentOf: (str: string) => void,
  widgetObject: WidgetData
}
export function EditCommentModal({
  isOpen,
  id,
  comment,
  setChangeCommentOf,
  widgetObject
}: editCommentModalProps) {
  const [commentWip, setCommentWip] = useState<string>(comment)
  if (!isOpen) return null
  return (
    <div key={`divWithCommentTextAreaFor${id}`} className="w-96 bg-white border border-gray-300 rounded-lg p-2">
      <Textarea
        key={`commentTextAreaFor${id}`}
        size="md"
        rows={15}
        resize="vertical"
        value={commentWip}
        onChange={(event) => {
          setCommentWip(event.target.value)
        }}
      />
      <button
        type="button"
        className="bg-white hover:bg-gray-600 text-black hover:text-white border-2 rounded-lg border-black w-fit p-1 m-1"
        onClick={() => {
          widgetObject.editComment(id, commentWip)
          setChangeCommentOf('')
          setCommentWip('')
        }}
      >
        Save comment
      </button>
      <button
        className="bg-[#BB0A1E] hover:bg-[#c83a4a] text-white border-2 rounded-lg border-[#BB0A1E] w-fit p-1 m-1"
        type="button"
        onClick={() => {
          setChangeCommentOf('')
          setCommentWip('')
        }}
      >
        Cancel
      </button>
    </div>

  )
}
