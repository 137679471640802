export const tourConfig = (callbackList: (() => void)[]) => {
  console.log(callbackList)
  return [
    {
      selector: '[data-tour="ResourceExplorerMenu"]',
      content: 'Welcome to the resource explorer page! Here you can look at your focus data, you can choose to load more data if you want.'
    },
    {
      selector: '[data-tour="GroupSwitch"]',
      content: 'Here you can choose if you want to see a table that is pregrouped by the developers, or a table that is fully ungrouped and fully configurable to your liking.',
    },
    {
      selector: '[data-tour="Table"]',
      content: 'Selecting a cell in the table will allow you to add it as a label rule.',
    },
    {
      selector: '[data-tour="LabelRuleCreation"]',
      content: 'Here you can see all the labels you have added, after you are done with this you can finish the creation by pressing "Open the label rule creation tool". There you can do everything you can do during the label rule creation process in the label rule page.',
    },
    {
      selector: '[data-tour="fullApp"]',
      content: 'Enjoy discovering your data!',
      action: () => {
        callbackList[0]()
      }
    },
  ]
}
