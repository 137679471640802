import {
  GraphCardGroup,
  GraphCardWrapper
} from 'components/graph-card'
import 'react-vis/dist/style.css'
import { TPlotSeries } from 'services/costhistory.interface'
import Spinner from 'components/spinner'
import {
  AreaChart,
  // AreaChart,
  // BarChart,
  AreaChartSeries,
  BarChart,
  BarChartSeries,
  LineChart,
  LineChartSeries,
  // LineChart
} from '@mantine/charts'
import { TCurrency } from 'services/cost.interface'
// import { makeTooltip } from 'components/LabeledDataRepresenation/helper'
import { useEffect, useState } from 'react'
import { ChartTooltip } from 'features/projects/project-finops-widget/useCases/ServiceCostsRegion'

interface BarLineChartProps {
  data: TPlotSeries[] | object[]
  dataSeries: AreaChartSeries[]
  isLoading?: boolean
  chartType?: 'line' | 'bar' | 'area',
  currency: TCurrency
  height?: number | undefined
  onClick?: (event: any) => void
}

function BarLineChart({
  data,
  dataSeries,
  chartType,
  isLoading,
  currency,
  height,
  onClick
}:
  BarLineChartProps) {
  const [theHeight, setTheHeight] = useState<number>(300)
  useEffect(() => {
    if (height) setTheHeight(height)
  }, [height])

  // const [savedDelimiter, setSavedDelimiter] = useState<string>('en-US');
  // const [savedGrouping, setSavedGrouping] = useState<string>('Yes');
  // useEffect(() => {
  //   if (sessionStorage.getItem('delimiter') === ',') setSavedDelimiter('de-DE')
  //   if (sessionStorage.getItem('grouping') === 'No') setSavedGrouping('No')
  // }, [savedDelimiter, sessionStorage.getItem('delimiter'), sessionStorage.getItem('grouping')])
  // useEffect(() => {
  //   console.log(dataSeries as BarChartSeries[])
  // })
  if (isLoading) {
    return (
      <GraphCardWrapper>
        <GraphCardGroup
          className="flex justify-center items-center h-full py-20"
          compact
          spacing={20}
        >
          <Spinner size={40} />
        </GraphCardGroup>
      </GraphCardWrapper>
    )
  }
  return (
    <div className={`h-[${theHeight}px] w-11/12`}>
      {chartType === 'bar' && (
        <BarChart
          h={theHeight}
          data={data}
          series={dataSeries as BarChartSeries[]}
          type="stacked"
          dataKey="date"
          barChartProps={{ onClick: (event) => { if (onClick) onClick(event) } }}
          tooltipProps={{
            // eslint-disable-next-line react/no-unstable-nested-components
            content: ({ label, payload }) => {
              return <ChartTooltip label={label} payload={payload} currency={currency} />
            }
          }}
        />
      )}
      {chartType === 'line' && (
        <LineChart
          h={theHeight}
          data={data}
          series={dataSeries as LineChartSeries[]}
          dataKey="date"
          lineChartProps={{ onClick: (event) => { if (onClick) onClick(event) } }}
          tooltipProps={{
            // eslint-disable-next-line react/no-unstable-nested-components
            content: ({ label, payload }) => {
              return <ChartTooltip label={label} payload={payload} currency={currency} />
            }
          }}
        />
      )}
      {chartType === 'area' && (
        <AreaChart
          h={theHeight}
          data={data}
          series={dataSeries as AreaChartSeries[]}
          dataKey="date"
          type="stacked"
          areaChartProps={{ onClick: (event) => { if (onClick) onClick(event) } }}
          tooltipProps={{
            // eslint-disable-next-line react/no-unstable-nested-components
            content: ({ label, payload }) => {
              return <ChartTooltip label={label} payload={payload} currency={currency} />
            }
          }}
        />
      )}
    </div>
  )
}

export default BarLineChart
