export const tourConfig = (id: string, callbackList: (() => void)[]) => {
  // console.log(callbackList)
  return [
    {
      selector: `[data-tour="ATableWidgetWithId${id}"]`,
      content: 'This is the aggregated cost by widget! The widget will fetch your resource data and displays it in a table, after this you can group/ungroup columns, filter, search and more.'
    },
    {
      selector: `[data-tour="ATableWidgetWithId${id}"]`,
      content: 'Selecting a row in this table will make this table fetch historical data for that selected resource over the timespan of a month.'
    },
    {
      selector: `[data-tour="theAccordionFor${id}"]`,
      content: 'The fetched data will then be shown in a bar chart up here.'
    },
    {
      selector: `[data-tour="theOptionsButtonFor${id}"]`,
      content: 'Clicking this button will show you the different options for the widget.',
      action: () => {
        callbackList[0]()
      }
    },
    {
      selector: `[data-tour="ATableWidgetWithId${id}"]`,
      content: 'Enjoy discovering your data',
      action: () => {
        callbackList[2]()
        callbackList[4]()
      }
    },
  ]
}
