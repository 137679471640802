import { useEffect, useState, useMemo } from 'react'
import {
  Input,
  // RegExInput,
  Modal,
  ModalBody,
  ModalHeader,
  SpinnerLoader,
  ModalFooter,
  RegExInput
} from 'components'
import { Chip, Popover } from '@mantine/core'
import { useQuery } from 'react-query'
import { axios } from 'utils'
import { v4 as uuid } from 'uuid'
import {
  TLabelRule,
} from 'services/label.rule.interface'
import { DndContext, DragEndEvent, DragMoveEvent } from '@dnd-kit/core'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { Bounce, toast } from 'react-toastify'
import { FaPlus, FaX } from 'react-icons/fa6'
import { FaCheck } from 'react-icons/fa'
import { re, reTest } from 'pages/advanced-reporting/mutation-new-report-modal'
import { createNewEffectWithID, createNewLabelRule, createNewRuleWithID, effectType, effectTypeWithId, labelRuleType, labelRuleTypeWithId, ruleType, ruleTypeWithId, } from '../queryHelperFunctions'
import ItemDataEditComponent from './ItemDataEditComponent'
import { Draggable } from './Draggable'
import { createFieldLabel, getBgColor, getHoverBgColor, getHoverTextColor, getTextColor } from '../helperFunctions'

// const reName = '^[a-z0-9][a-z0-9.-]*[a-z0-9]$'

// ***************************************
// Interface for MutationNewLabelRuleModal
// ***************************************
function mapChildrenWithId(children: effectType[]): effectTypeWithId[] {
  if (children) {
    return children.map((effect) => {
      return ({
        id: uuid(),
        key: effect.key,
        value: effect.value,
        children: mapChildrenWithId(effect.children)
      })
    })
  } return []
}

interface MutationNewLabelRuleModalProps {
  isOpen: boolean
  data?: TLabelRule
  onMutated?: () => void
  onClose: () => void
  initialLabelRule?: labelRuleType
  initialName?: string
  update?: boolean
}

// *************************
// MutationNewLabelRuleModal
// *************************
export function MutationNewLabelRulesModal({
  isOpen,
  data,
  onMutated,
  onClose,
  initialLabelRule,
  initialName,
  update
}: MutationNewLabelRuleModalProps) {
  // Context for creating label rules
  // const { createLabelRule, updateLabelRule } = useLabelRules()

  // the data used for the api calls
  type ec2ColType = {
    friendlyName: string;
    technicalName: string;
  }
  const tempEc2ColList: ec2ColType[] = [
    {
      friendlyName: 'Owner ID',
      technicalName: 'ec2.owner_id'
    },
    {
      friendlyName: 'Operation System',
      technicalName: 'ec2.platform_details'
    },
    {
      friendlyName: 'Public IP Address',
      technicalName: 'ec2.public_ip_address'
    },
    {
      friendlyName: 'CPU Options Core Count',
      technicalName: 'ec2.cpu_options_core_count'
    },
    {
      friendlyName: 'State',
      technicalName: 'ec2.state_name'
    }
  ]
  const tags = ['focus', 'ec2']
  const [
    labelList,
    setLabelList
  ] = useState<[]>([])
  const [labelRuleData, setLabelRuleData] = useState<labelRuleTypeWithId>(createNewLabelRule())

  const [
    fieldsValues,
    setFieldsValues
  ] = useState<[]>([])
  const [
    currentlyEditedLabel,
  ] = useState<string>()

  const [labelsListProcessed, setLabelsListProcessed] = useState<boolean>(false)
  const [queryKey, setQueryKey] = useState<string>(`query?${currentlyEditedLabel}`)
  const [labelRuleDataProcessed, setLabelRuleDataProcessed] = useState<boolean>(false)
  const [labelRuleDataUpdated, setLabelRuleDataUpdated] = useState<boolean>(true)
  const [fieldsProcessed, setFieldsProcessed] = useState<boolean>(false)
  const [overId, setOverId] = useState<string>('');
  const [moveTo, setMoveTo] = useState<{ num: number, to: number }>({ num: 0, to: 0 });

  // const [initialLoad, setInitialLoad] = useState<boolean>(true)

  // ********************************************************************************************
  // These are all the API calls that can happen, not all are called everytime.
  // The if statements will block off the calls for data that does not need to be updated anymore
  // ********************************************************************************************

  const { error } = useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      try {
        if (!labelsListProcessed) {
          await axios
            .get('/labels')
            .then((res) => {
              setLabelList(res.data)
              setLabelsListProcessed(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                toast.error('No labels have been created yet.', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  transition: Bounce,
                })
              }
            })
        }
        if (!labelRuleDataProcessed) {
          await axios
            .get('/label-rules')
            .then((res) => {
              const labelRule: labelRuleType = res.data.find((el: labelRuleType) => {
                return el.name === data?.name
              })
              if (labelRule) {
                const processedRules: ruleTypeWithId[] = labelRule.rules.map((rule) => {
                  console.log(rule.key)
                  const tag = tempEc2ColList.findIndex((el) => el.technicalName === rule.key) !== -1 ? 'ec2' : 'focus'
                  return ({
                    tag,
                    id: uuid(),
                    key: rule.key,
                    value: rule.value,
                    operand: rule.operand
                  })
                })
                const processedEffects: effectTypeWithId[] = labelRule.effects.map((effect) => {
                  const children = mapChildrenWithId(effect.children)
                  return ({
                    id: uuid(),
                    key: effect.key,
                    value: effect.value,
                    children
                  })
                })
                const labelRuleWitIds = {
                  id: uuid(),
                  name: labelRule.name,
                  description: labelRule.description,
                  enabled: labelRule.enabled,
                  rules: processedRules,
                  effects: processedEffects
                }
                setLabelRuleData(labelRuleWitIds)
              }
              setLabelRuleDataProcessed(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                toast.error('No label-rules have been created yet.', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  transition: Bounce,
                })
              }
            })
        }
        if (!labelRuleDataUpdated && !/A-Z/.test(labelRuleData.name)) {
          const processedRules: ruleType[] = labelRuleData.rules.map((rule) => {
            return ({
              key: rule.key,
              value: rule.value,
              operand: rule.operand
            })
          })
          const processedEffects: effectType[] = labelRuleData.effects.map((effect) => {
            const children = mapChildrenWithId(effect.children)
            return ({
              key: effect.key,
              value: effect.value,
              children
            })
          })
          const labelRule = {
            name: labelRuleData.name,
            description: labelRuleData.description,
            enabled: labelRuleData.enabled,
            rules: processedRules,
            effects: processedEffects
          }
          if (data) {
            await axios
              .put(`/label-rules/${data.name}`, labelRule)
              .then(() => {
                setLabelRuleDataUpdated(true)
                onClose()
                if (onMutated) onMutated()
              })
              .catch((error) => {
                if (error.response.data.data) {
                  toast.error('Updating a label-rule is not possible.', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                    transition: Bounce,
                  })
                }
              })
          } else if (update) {
            await axios
              .put(`/label-rules/${initialName}`, labelRule)
              .then(() => {
                setLabelRuleDataUpdated(true)
                onClose()
                if (onMutated) onMutated()
              })
              .catch((error) => {
                if (error.response.data.data) {
                  toast.error('Updating a label-rule is not possible.', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                    transition: Bounce,
                  })
                }
              })
          } else {
            await axios
              .post('/label-rules', labelRule)
              .then(() => {
                setLabelRuleDataUpdated(true)
                onClose()
                if (onMutated) onMutated()
              })
              .catch((error) => {
                if (error.response.data.data) {
                  toast.error('Creation of the label-rule was not possible.', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'colored',
                    transition: Bounce,
                  })
                }
              })
          }
        }

        if (!fieldsProcessed) {
          await axios
            .get('/fields')
            .then((res) => {
              setFieldsValues(res.data)
              setFieldsProcessed(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                toast.error('No resources are available.', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  transition: Bounce,
                })
              }
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })
  useEffect(() => {
    if (initialLabelRule) {
      labelRuleData.name = initialLabelRule.name
      labelRuleData.description = initialLabelRule.description
      labelRuleData.rules = initialLabelRule.rules.map((rule) => {
        const tag = tempEc2ColList.findIndex((el) => el.technicalName === rule.key) !== -1 ? 'ec2' : 'focus'
        const ruleWithId = {
          tag,
          id: uuid(),
          key: rule.key.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase(),
          value: rule.value,
          operand: rule.operand
        }
        return ruleWithId
      })
      labelRuleData.effects = mapChildrenWithId(initialLabelRule.effects)
    }
  }, [fieldsValues])
  // *****************************************
  // Convert the data to form data for editing
  // *****************************************

  function flattenChildren(effects: effectTypeWithId[]) {
    const res: effectTypeWithId[] = []
    effects.forEach((el) => {
      res.push(el)
      flattenChildren(el.children).forEach((child) => res.push(child))
    })
    return res
  }

  function dragMoveFunctionality({ delta }: DragMoveEvent) {
    if (moveTo.to === 0) {
      if (delta.x > 40) {
        setMoveTo({ num: 1, to: 1 })
      } else if (delta.x < 40) {
        setMoveTo({ num: 1, to: -1 })
      }
    } else if (delta.x * moveTo.to > 40 * moveTo.num) {
      setMoveTo({ num: moveTo.num + 1, to: moveTo.to })
    } else if (moveTo.num + -1 === 0) setMoveTo({ num: moveTo.num - 1, to: 0 })
    else setMoveTo({ num: moveTo.num + -1, to: moveTo.to })
  }
  function findChildsParent(id: string, list: effectTypeWithId[], parentId: string[]) {
    const index = list.findIndex((effect) => effect.id === id)
    if (index !== -1) {
      return parentId.concat(id)
    }
    let nextParent: string[] = []
    list.forEach((effect) => {
      if (effect.children.length !== 0) {
        const temp = findChildsParent(id, effect.children, parentId.concat(effect.id))
        if (temp.length > parentId.length) nextParent = temp
      }
    })
    return nextParent
  }
  function intoParent(
    num: number,
    effects: effectTypeWithId[],
    id: string,
    parentId: string
  ): string {
    if (effects.length === 0 || num === 0) return parentId
    const idx = effects.findIndex((el) => el.id === id)
    if (idx === 0) return parentId
    const len = idx > 0 ? idx - 1 : effects.length - 1
    const nextId = effects[len].id
    const nextEffects = effects[len].children
    return intoParent(num - 1, nextEffects, id, nextId)
  }

  function getParentEffects(effects: effectTypeWithId[], ids: string[]): effectTypeWithId[] {
    if (effects.length === 0 || ids.length === 0) return effects
    const idx = effects.findIndex((el) => el.id === ids[0])
    const nextEffects = effects[idx].children
    const nextIds = ids.slice(1)
    return getParentEffects(nextEffects, nextIds)
  }

  function dragOverFunctionality({ active, over }: DragEndEvent) {
    if (over && active) {
      setOverId(active.id.toString())
    }
  }

  function loopChecker(newParentId: string, effect: effectTypeWithId): boolean {
    let res = true
    if (newParentId === effect.id) return false
    effect.children.forEach((child) => {
      if (!loopChecker(newParentId, child)) res = false
    })
    return res
  }

  function removeChild(id: string, effects: effectTypeWithId[], theNewOverId: string): {
    item: effectTypeWithId, list: effectTypeWithId[], bool: boolean
  } {
    const index = effects.findIndex((effect) => effect.id === id)
    let res = createNewEffectWithID()
    if (index === -1) {
      let found = false
      effects = effects.map((effect) => {
        const { item, list, bool } = removeChild(id, effect.children, theNewOverId)
        effect.children = list
        if (bool) res = item
        if (bool) found = true
        return effect
      })
      return { item: res, list: effects, bool: found }
    }
    res = effects[index]
    if (loopChecker(theNewOverId, res)) effects = effects.filter((effect) => effect.id !== id)
    return { item: res, list: effects, bool: true }
  }

  function addChild(
    effects: effectTypeWithId[],
    effectToAdd: effectTypeWithId,
    parentId: string
  ): effectTypeWithId[] {
    const index = effects.findIndex((effect) => effect.id === parentId)
    if (index === -1) {
      effects = effects.map((effect) => {
        const list = addChild(effect.children, effectToAdd, parentId)
        effect.children = list
        return effect
      })
      return effects
    }
    effects[index].children = effects[index].children.concat(effectToAdd)
    return effects
  }

  function dragEndFunctionality({ active }: DragEndEvent) {
    let theNewOverId = overId
    if (moveTo.to > 0) {
      const listOfParents = findChildsParent(overId, labelRuleData.effects, ['Base'])
      const curParent = listOfParents[listOfParents.length - 2]
      const curNum = moveTo.num - (listOfParents.length - 2)
      const curEffects = getParentEffects(
        labelRuleData.effects,
        listOfParents.slice(1, listOfParents.length - 2)
      )
      theNewOverId = intoParent(curNum, curEffects, active.id.toString(), curParent)
    } else if (moveTo.to < 0) {
      const listOfParents = findChildsParent(active.id.toString(), labelRuleData.effects, ['Base'])
      if (moveTo.num < listOfParents.length - 1) theNewOverId = 'Base'
      else {
        let index = listOfParents.length - 1
        index -= moveTo.num
        const curParent = listOfParents[index]
        theNewOverId = curParent
      }
    }
    if (theNewOverId.length !== 0) {
      const { item, list } = removeChild(active.id.toString(), labelRuleData.effects, theNewOverId)
      if (loopChecker(theNewOverId, item)) {
        let addedList = []
        if (theNewOverId !== 'Base') {
          addedList = addChild(list, item, theNewOverId)
        } else {
          addedList = list.concat(item)
        }
        const { id, name, description, enabled, rules } = labelRuleData
        const newLabeRule = {
          id,
          name,
          description,
          enabled,
          rules,
          effects: addedList
        }
        setLabelRuleData(newLabeRule)
      }
    }
  }
  function editRule(ruleId: string, key: string, value: string, operand: string) {
    const ruleIndex = labelRuleData.rules.findIndex((el) => el.id === ruleId)
    const newRules = labelRuleData.rules
    if (ruleIndex !== -1) {
      const rule = newRules[ruleIndex]
      rule.key = key
      rule.value = value
      rule.operand = operand
      newRules[ruleIndex] = rule
    }
    const { id, name, description, enabled, effects } = labelRuleData
    const newLabelRules = {
      id,
      name,
      description,
      enabled,
      rules: newRules,
      effects
    }
    setLabelRuleData(newLabelRules)
  }

  function editEffectLoop(id: string, effects: effectTypeWithId[], key: string, value: string)
    : effectTypeWithId[] {
    const index = effects.findIndex((effect) => effect.id === id)
    if (index === -1) {
      effects = effects.map((effect) => {
        const list = editEffectLoop(id, effect.children, key, value)
        effect.children = list
        return effect
      })
      return effects
    }
    const effect = effects[index]
    effect.key = key
    effect.value = value
    effects[index] = effect
    return effects
  }
  function editEffect(effectId: string, key: string, value: string) {
    const newEffects = editEffectLoop(effectId, labelRuleData.effects, key, value)
    const { id, name, description, enabled, rules } = labelRuleData
    const newLabelRules = {
      id,
      name,
      description,
      enabled,
      rules,
      effects: newEffects
    }
    setLabelRuleData(newLabelRules)
  }

  function deleteRuleOrEffect(elId: string, type: string) {
    if (type === 'rules') {
      const newRules = labelRuleData.rules.filter((rule) => rule.id !== elId)
      const { id, name, description, enabled, effects } = labelRuleData
      const newLabelRules = {
        id,
        name,
        description,
        enabled,
        rules: newRules,
        effects
      }
      setLabelRuleData(newLabelRules)
    } else {
      const newEffects = removeChild(elId, labelRuleData.effects, '')
      const { id, name, description, enabled, rules } = labelRuleData
      const newLabelRules = {
        id,
        name,
        description,
        enabled,
        rules,
        effects: newEffects.list
      }
      setLabelRuleData(newLabelRules)
    }
  }

  const focusOptions = useMemo(() => {
    const result: { label: string, value: string }[] = []
    fieldsValues.forEach((el) => {
      result.push({ label: createFieldLabel(el), value: el })
    })
    return result
  }, [fieldsValues])

  function createHierarchicalComponents(effect: effectTypeWithId, depth: number) {
    if (effect) {
      return (
        <div key={effect.id}>
          <Draggable id={effect.id} key={effect.id} depth={depth}>
            <ItemDataEditComponent
              key={effect.id}
              type="effects"
              editItem={effect}
              onChange={(editItem) => {
                const item = editItem as effectTypeWithId
                editEffect(effect.id, item.key, item.value)
              }}
              onDelete={() => { deleteRuleOrEffect(effect.id, 'effects') }}
              labelList={labelList}
              options={focusOptions}
            // styleString1="py-0"
            // styleString2="flex bg-white"
            // styleString3=""
            />
          </Draggable>
          {effect.children.map((child) => {
            return createHierarchicalComponents(child, depth + 1)
          })}
        </div>
      )
    } return <div />
  }

  if (error) return <div>An error has occurred</div>
  return (
    <Modal isOpen={isOpen} onClose={() => onClose()} disableBackdropClick>
      <SpinnerLoader isLoading={!labelRuleDataUpdated}>
        <ModalHeader onClose={() => { onClose() }}>
          <div className="flex w-full justify-between">
            <div>
              {data || update ? 'Update' : 'Create'} Label Rule
            </div>
            <Chip
              data-tour="TheEnabledChip"
              color={labelRuleData.enabled ? '#668957' : 'gray'}
              icon={labelRuleData.enabled ? <FaCheck /> : <FaX />}
              checked
              onClick={() => {
                const newEnabled = !labelRuleData.enabled
                const { id, name, description, rules, effects } = labelRuleData
                const newLabeRule = {
                  id,
                  name,
                  description,
                  enabled: newEnabled,
                  rules,
                  effects
                }
                setLabelRuleData(newLabeRule)
              }}
            >
              Enabled
            </Chip>
          </div>
        </ModalHeader>
        <form onSubmit={() => {
          setLabelRuleDataUpdated(false)
          setQueryKey(uuid())
        }}
        >
          <ModalBody className="px-0 py-0 w-[80vw]">
            <div className="flex gap-6 px-5 pb-4 border-b">
              <RegExInput
                type="text"
                label="Name"
                name="name"
                value={labelRuleData.name}
                onChange={(event) => {
                  const newName = event.target.value
                  const { id, description, enabled, rules, effects } = labelRuleData
                  const newLabeRule = {
                    id,
                    name: newName,
                    description,
                    enabled,
                    rules,
                    effects
                  }
                  setLabelRuleData(newLabeRule)
                }}
                pattern={re}
                errorMessage="LabelRule name is invalid. It must consist of lower case alphanumeric characters, \'-\' or \'.\' and must start and and with and alphanumeric characters"
                required
              />
              <Input
                type="text"
                name="description"
                label="Description"
                value={labelRuleData.description}
                onChange={(event) => {
                  const newDescription = event.target.value
                  const { id, name, enabled, rules, effects } = labelRuleData
                  const newLabeRule = {
                    id,
                    name,
                    description: newDescription,
                    enabled,
                    rules,
                    effects
                  }
                  setLabelRuleData(newLabeRule)
                }}
              />
            </div>
            <div className="h-[calc(100vh-380px)] overflow-y-auto flex">
              <div className="flex flex-col p-2 w-1/2 h-full" data-tour="theLabelRuleHalf">
                <div className="relative flex flex-col border border-gray-500 rounded-lg h-full shadow-md">
                  <div className="border-b border-gray-500 bg-gray-300 rounded-t-lg p-2 w-full flex justify-between">
                    <span>Label Rules:</span>
                    <Popover width={200} position="bottom" withArrow shadow="md">
                      <Popover.Target>
                        <button
                          type="button"
                          className="cursor-pointer hover:bg-opacity-10 p-0.5 rounded-lg"
                        >
                          <FaPlus />
                        </button>
                      </Popover.Target>
                      <Popover.Dropdown>
                        {tags.map((tag) => (
                          <div className="p-1">
                            <button
                              type="button"
                              style={{ transition: 'background-color 0.1s ease-in' }}
                              className={
                                `cursor-pointer p-0.5 rounded-lg h-8 w-full ${getTextColor(tag)} ${getBgColor(tag)} ${getHoverTextColor(tag)} ${getHoverBgColor(tag)}`
                              }
                              onClick={() => {
                                const newRules =
                                  labelRuleData.rules.concat(createNewRuleWithID(tag))
                                const { id, name, description, enabled, effects } = labelRuleData
                                const newLabelRules = {
                                  id,
                                  name,
                                  description,
                                  enabled,
                                  rules: newRules,
                                  effects
                                }
                                setLabelRuleData(newLabelRules)
                              }}
                            >
                              {tag}
                            </button>
                          </div>
                        ))}
                      </Popover.Dropdown>
                    </Popover>
                  </div>
                  <div className="overflow-auto">
                    {labelRuleData.rules.map((rule) => (
                      <ItemDataEditComponent
                        useOption
                        key={rule.id}
                        editItem={rule}
                        type="rules"
                        tag={rule.tag}
                        onChange={(editItem) => {
                          const item = editItem as ruleTypeWithId
                          editRule(rule.id, item.key, item.value, item.operand)
                        }}
                        onDelete={() => { deleteRuleOrEffect(rule.id, 'rules') }}
                        labelList={labelList}
                        options={rule.tag === 'focus' ? focusOptions : tempEc2ColList.map((el) => {
                          return { label: el.friendlyName, value: el.technicalName }
                        })}
                      // styleString1="py-2"
                      // styleString2="flex bg-white border border-dark-grey rounded-md"
                      // styleString3="p-3"
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className="flex flex-col p-2 w-1/2 h-full" data-tour="theLabelEffectHalf">
                <div className="relative flex flex-col border border-gray-500 rounded-lg h-full shadow-md">
                  <div className="border-b border-gray-500 bg-gray-300 rounded-t-lg p-2 w-full flex justify-between">
                    <span>Label Effects:</span>
                    <button
                      type="button"
                      className="cursor-pointer hover:bg-opacity-10 p-0.5 rounded-lg"
                      onClick={() => {
                        const newEffects = labelRuleData.effects.concat(createNewEffectWithID())
                        const { id, name, description, enabled, rules } = labelRuleData
                        const newLabelRules = {
                          id,
                          name,
                          description,
                          enabled,
                          rules,
                          effects: newEffects
                        }
                        setLabelRuleData(newLabelRules)
                      }}
                    >
                      <FaPlus />
                    </button>
                  </div>
                  {/* Here the drag and drop context is created.
                REFERENCES: https://dndkit.com/
                It loops over the component list to display each element.
                It is important to add a unique key so that warnings are avoided. */}
                  <div className="p-1">
                    <DndContext
                      onDragOver={(event) => { dragOverFunctionality(event) }}
                      onDragMove={(event) => { dragMoveFunctionality(event) }}
                      onDragEnd={(event) => {
                        dragEndFunctionality(event)
                        setMoveTo({ num: 0, to: 0 })
                      }}
                    >
                      <SortableContext
                        items={flattenChildren(labelRuleData.effects)}
                        strategy={verticalListSortingStrategy}
                      >
                        {labelRuleData.effects.map((effect) => {
                          return createHierarchicalComponents(effect, 0)
                        })}
                      </SortableContext>
                    </DndContext>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="gap-4 w-full flex justify-end p-4">
            <button
              type="button"
              className="rounded-lg hover:bg-red-500 hover:bg-opacity-10 py-1 px-4 text-red-500"
              onClick={() => onClose()}
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={!reTest.test(labelRuleData.name)}
              className="bg-[#668957] hover:bg-[#93ac89] disabled:bg-gray-300 text-white rounded-md py-1 px-4"
            >
              {data || update ? 'Update' : 'Create'}
            </button>
          </ModalFooter>
        </form>
      </SpinnerLoader>
    </Modal>
  )
}

export default MutationNewLabelRulesModal
