import { useEffect, useState } from 'react'
import './index.scss'
import { useQuery } from 'react-query';
import { axios } from 'utils/axios';
import { Bounce, toast } from 'react-toastify';
import LogOutKeyCloakButton from 'components/key-cloak-logout-button'
import { Avatar, Button, Popover } from '@mantine/core';
import { v4 as uuid } from 'uuid'
import getUserLocale from 'get-user-locale';
import { ShowProfile } from './show-profile'
import { Settings } from './settings';
// import premiumLevel from '../../assets/badges/premiumLevel.svg'

export type profile = {
  username: string,
  firstname: string,
  lastname: string,
  company: string,
  email: string
}
export type forecasting = {
  tenant: string,
  provider: string,
  forecastDate: string,
  forecastMonth: number,
  forecastWeek: number[],
  forecastTax: number,
  warning: string,
  error: string
}

function TopBar() {
  const [isOpen, setOpen] = useState(false)
  const [showProfileBool, setShowProfileBool] = useState(false)
  const [settingsBool, setSettingsBool] = useState(false)
  const [profileData, setProfileData] = useState<profile>()
  const [dataProcessed, setDataProcessed] = useState<boolean>(false)
  const [settingsDataProcessed, setSettingsDataProcessed] = useState<boolean>(true)
  const [settingsDataCreation, setSettingsDataCreation] = useState<boolean>(true)
  const [settingsDataUpdate, setSettingsDataUpdate] = useState<boolean>(true)
  const [queryKey, setQueryKey] = useState<string>('initial')
  const [name, setName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [userLocale] = useState<string>(getUserLocale());
  const [dateFormat, setDateFormat] = useState<string>(userLocale === 'en-US' ? 'YYYY-MM-DD' : 'DD-MM-YYYY')
  const [delimiter, setDelimiter] = useState<string>(userLocale === 'en-US' ? '.' : ',')
  const [grouping, setGrouping] = useState<string>(userLocale === 'en-US' ? 'Yes' : 'No')
  const [budget, setBudget] = useState<number>(0)
  const [persona, setPersona] = useState<string>('')
  const [language, setLanguage] = useState<string>('English')
  const [profileIcon, setProfileIcon] = useState<string>('')
  const [saveClicked, setSaveClicked] = useState<boolean>(false)
  const [forecastingFetched, setForecastingFetched] = useState<boolean>(false)

  const toggleShowProfile = () => {
    setShowProfileBool(!showProfileBool)
  }

  const toggleSettings = () => {
    setSettingsBool(!settingsBool)
  }

  const { error } = useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      try {
        if (!dataProcessed) {
          await axios
            .get('auth/profile')
            .then((res) => {
              setProfileData(res.data)
              setSettingsDataProcessed(false)
              setQueryKey(uuid())
              setDataProcessed(true)
            })
            .catch((error) => {
              toast.error(`Error code ${error}`, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
                transition: Bounce,
              })
            })
        }
        if (!forecastingFetched) {
          await axios
            .get('forecast')
            .then((res) => {
              setForecastingFetched(true)
              sessionStorage.setItem('forecasting', JSON.stringify(res.data));
              window.dispatchEvent(new Event('forecasting'));
            })
        }
        if (!settingsDataProcessed) {
          await axios
            .get('/store/settings')
            .then((res) => {
              const jsn = JSON.parse(res.data.storeValue).settings
              setDateFormat(jsn.dateFormat)
              setDelimiter(jsn.delimiter)
              setGrouping(jsn.grouping)
              setLanguage(jsn.language)
              setBudget(jsn.budget)
              setPersona(jsn.persona)
              // sessionStorage.setItem('dateFormat', jsn.dateFormat);
              // sessionStorage.setItem('delimiter', jsn.delimiter);
              // sessionStorage.setItem('grouping', jsn.grouping);
              // sessionStorage.setItem('language', jsn.language);
              setProfileIcon(jsn.profileIcon)
              setSettingsDataProcessed(true)
            })
            .catch((error) => {
              setSettingsDataProcessed(true)
              setSettingsDataCreation(false)
              setQueryKey(uuid())
              toast.error(`Error code ${error}`, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
                transition: Bounce,
              })
            })
        }
        if (!settingsDataCreation) {
          setSettingsDataCreation(true)
          await axios
            .post('/store/settings', {
              settings: {
                dateFormat,
                delimiter,
                grouping,
                language,
                profileIcon,
                budget,
                persona
              }
            })
            .catch((error) => {
              toast.error(`Error code ${error}`, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
                transition: Bounce,
              })
            })
        }
        if (!settingsDataUpdate) {
          setSettingsDataUpdate(true)
          await axios
            .put('/store/settings', {
              settings: {
                dateFormat,
                delimiter,
                grouping,
                language,
                profileIcon,
                budget,
                persona
              }
            })
            .catch((error) => {
              toast.error(`Error code ${error}`, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
                transition: Bounce,
              })
            })
        }
      } catch (error) {
        toast.error(`Error code ${error}`, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          transition: Bounce,
        })
      }
    }
  })
  useEffect(() => {
    if (settingsDataProcessed) {
      sessionStorage.setItem('dateFormat', dateFormat);
      sessionStorage.setItem('delimiter', delimiter);
      sessionStorage.setItem('language', language);
    }
  }, [settingsDataProcessed])
  useEffect(() => {
    if (profileData !== undefined) {
      setName(profileData.firstname)
      setLastName(profileData.lastname)
    }
  }, [profileData])
  useEffect(() => {
    if (saveClicked) {
      setQueryKey(uuid())
      setSettingsDataUpdate(false)
      setSaveClicked(false)
    }
  }, [saveClicked])

  if (error) return <div>An error has occurred</div>
  return (
    <div className="flex phone:justify-end items-center w-full p-4 phone:py-4 phone:px-5 tablet:px-10 bg-top-bar-color">
      <div className="flex items-center justify-between phone:justify-center w-full phone:w-auto">
        {/* <Search /> */}
        <LogOutKeyCloakButton />
        <Popover opened={isOpen} onChange={setOpen} withArrow>
          <Popover.Target>
            <Button className="h-fit text-center bg-primary-color hover:bg-primary-color" onClick={() => { setOpen(!isOpen) }}>
              <div className="flex flex-col justify-center">
                <div className="flex justify-center">
                  <div className="flex relative">
                    {/* <div className="absolute"> */}
                    <Avatar src={profileIcon} size="md" color="white" className="bg-[#668957]" alt={`${name} ${lastName}`} />
                    {/* </div> */}
                    {/* <img src={premiumLevel} alt="anEntryLevelBadge"
                     className="object-cover min-w-[38px]  min-h-[38px]" /> */}
                  </div>
                </div>
                <span className="text-sm font-medium text-icon-color" style={{ textAlign: 'center' }}>
                  {`${name} ${lastName}`}
                </span>
              </div>
            </Button>
          </Popover.Target>
          <Popover.Dropdown>
            <div
              className="border-b border-gray-100 hover:bg-gray-50 p-2"
              onClick={() => {
                setOpen(false)
                toggleShowProfile()
              }}
            >
              Profile
            </div>
            <div
              className="py-2 px-2 hover:bg-gray-50"
              onClick={() => {
                setOpen(false)
                toggleSettings()
              }}
            >
              Settings
            </div>
          </Popover.Dropdown>
        </Popover>
        {showProfileBool && (
          <div>
            <ShowProfile
              showProfileBool
              data={profileData}
              profileIcon={profileIcon}
              onClose={toggleShowProfile}
            />
          </div>
        )}
        {settingsBool && (
          <div>
            <Settings
              settingsBool
              onClose={toggleSettings}
              initialDateFormat={dateFormat}
              setDatefomatGlobally={setDateFormat}
              initialDelimiter={delimiter}
              setDelimiterGlobally={setDelimiter}
              initialGrouping={grouping}
              setGroupingGlobally={setGrouping}
              initialLanguage={language}
              setLanguageGlobally={setLanguage}
              setSaveClicked={setSaveClicked}
              initialProfileImage={profileIcon}
              setProfileImageGlobally={setProfileIcon}
              initialBudget={budget}
              setBudgetGlobally={setBudget}
              initialPersona={persona}
              setPersonaGlobally={setPersona}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default TopBar
