import { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { axios } from 'utils/axios'
import { TFocusGroup } from 'services/costhistory.interface'
import { useQuery } from 'react-query'
import { FetchStatus } from 'components'
import useProject from 'hooks/useProject'
import { TCurrency } from 'services/cost.interface'
import { Progress } from '@mantine/core'
import { query } from '../project-widget/widgetHelpFunctions'

export interface summaryBarProps {
  id: string,
  title: string,
  key: string,
  timeWindow: string,
  currency: TCurrency,
  useProgressBar?: boolean,
}
export function PreviousMonthSpend({
  id,
  title,
  key,
  timeWindow,
  currency,
  useProgressBar
}: summaryBarProps) {
  const {
    fetchProjectsStatus,
    // projectList,
    // fetchProjects
  } = useProject()
  // const id = uuid()
  const [data, setData] = useState<TFocusGroup[]>([])
  const [dataProcessed, setDataProcessed] =
    useState<boolean>(false)
  const [query, setQuery] = useState<query>(
    {
      name: uuid(),
      description: '',
      options: {
        saveQuery: false,
        accumulate: true,
        aggregate: 'provider',
        step: '1m',
        window: timeWindow
      },
      filters: [
      ]
    }
  )
  const changeQueryId = () => {
    setQuery(
      {
        name: uuid(),
        description: '',
        options: {
          saveQuery: false,
          accumulate: true,
          aggregate: 'provider',
          step: '1m',
          window: timeWindow
        },
        filters: [
        ]
      }
    )
  }
  const { error } = useQuery({
    queryKey: [`query?${JSON.stringify(query)}`],
    queryFn: async () => {
      try {
        if (!dataProcessed) {
          await axios
            .post('/query', query)
            .then((res) => {
              setData(res.data.data)
              setDataProcessed(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                setData([])
                setDataProcessed(true)
              }
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })
  function getTotalCost() {
    const providerCosts = {
      totalCost: 0,
      AWS: 0,
      Azure: 0,
      GCP: 0
    }
    if (data.length !== 0) {
      data.forEach((el) => {
        const provider = Object.getOwnPropertyNames(el)[0]
        const cost = Number(el[provider].totalBilledCost)
        providerCosts.totalCost += Number(cost)
        if (provider === 'AWS') {
          providerCosts.AWS += cost
        }
        if (provider === 'Azure') {
          providerCosts.Azure += cost
        }
        if (provider === 'Google Cloud') {
          providerCosts.GCP += cost
        }
      })
    } return providerCosts
  }

  const [savedDelimiter, setSavedDelimiter] = useState<string>('en-US');
  const [savedGrouping, setSavedGrouping] = useState<string>('Yes');
  const [savedBudget, setSavedBudget] = useState<number>(0);

  const [providerCost, setProviderCost] = useState<{
    totalCost: number;
    AWS: number;
    Azure: number;
    GCP: number;
  }>({
    totalCost: 0,
    AWS: 0,
    Azure: 0,
    GCP: 0
  });

  useEffect(() => {
    console.log(data)
    setProviderCost(getTotalCost())
  }, [data])

  window.addEventListener('storage', () => {
    if (sessionStorage.getItem('delimiter') === ',') {
      setSavedDelimiter('de-DE')
    } else {
      setSavedDelimiter('en-US')
    }
    if (sessionStorage.getItem('grouping') !== null) setSavedGrouping(sessionStorage.getItem('grouping')!)
    if (sessionStorage.getItem('budget') !== null) setSavedBudget(Number(sessionStorage.getItem('budget')!))
  })
  useEffect(() => {
    if (sessionStorage.getItem('delimiter') === ',') {
      setSavedDelimiter('de-DE')
    } else {
      setSavedDelimiter('en-US')
    }
    if (sessionStorage.getItem('grouping') !== null) setSavedGrouping(sessionStorage.getItem('grouping')!)
    if (sessionStorage.getItem('budget') !== null) setSavedBudget(Number(sessionStorage.getItem('budget')!))
  }, [data])

  if (error) return <div>An error has occurred</div>
  return (
    <div id={id} key={key} className="p-2 h-full">
      <div key={key} className="h-full">
        <FetchStatus status={fetchProjectsStatus} retry={changeQueryId}>
          <div className="h-full">
            <div className="h-full">
              <div>
                <span className="text-gray-600 w-max font-semibold text-lg">
                  {currency.symbol}
                  {
                    new Intl.NumberFormat(savedDelimiter, {
                      useGrouping: savedGrouping === 'Yes'
                    }).format(providerCost.totalCost)
                  } {useProgressBar && (<span>out of {savedBudget}</span>)}
                </span>
              </div>
              <span className="text-gray-400 text-xs">{title}</span>
              <div>
                {[
                  {
                    provider: 'AWS',
                    data: providerCost.AWS
                  },
                  {
                    provider: 'Azure',
                    data: providerCost.Azure
                  },
                  {
                    provider: 'Google Cloud',
                    data: providerCost.GCP
                  }
                ].sort((a, b) => b.data - a.data).map((el) => {
                  return (
                    <div>
                      <span className="text-gray-600 w-max font-semibold text-md">
                        {currency.symbol}
                        {
                          new Intl.NumberFormat(savedDelimiter, {
                            useGrouping: savedGrouping === 'Yes'
                          }).format(el.data)
                        }
                      </span>
                      <span className="text-gray-400 text-xs px-1">{el.provider}</span>
                    </div>
                  )
                })}
              </div>
            </div>
            {useProgressBar && (
              <Progress.Root radius="xl" size="lg" bg={Math.round((getTotalCost().totalCost / savedBudget) * 100) > 100 ? '#b41220' : '#64d2ff'}>
                <Progress.Section value={Math.round((getTotalCost().totalCost / savedBudget) * 100) > 100 ? 100 - (Math.round((getTotalCost().totalCost / savedBudget) * 100) - 100) : Math.round((getTotalCost().totalCost / savedBudget) * 100)} color="#668957" />
              </Progress.Root>
            )}

          </div>
        </FetchStatus>
      </div>
    </div>
  )
}
