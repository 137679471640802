import { useEffect, useMemo, useState } from 'react'
import { BsTable } from 'react-icons/bs'
import { FaChartBar, FaQuestion } from 'react-icons/fa'
import { v4 as uuid } from 'uuid'
import { TCurrency } from 'services/cost.interface'
import { ButtonIcon, SpinnerLoader } from 'components'
// import { Bar, BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts'
import useProject from 'hooks/useProject'
import { useQuery } from 'react-query'
import { axios, graphQLAxios } from 'utils'
import { newTFocusGroup, TCostHistoryQueryResponse, TFocusGroup } from 'services/costhistory.interface'
import { TBarChartData } from 'services/chart.interface'
import { effectData } from 'pages/label-rules/mutation-new-label-rule-model/hierarchyHelperFunctions'

import { Accordion, Box, Button, MantineProvider, Tabs } from '@mantine/core'
import { Tooltip } from '@mui/material'
import { useResizeObserver } from '@mantine/hooks'
import { useTour } from '@reactour/tour'
import { fontAwesomeIcons } from 'pages/resource-explorer/icons'
import { MantineReactTable, MRT_Cell, MRT_ColumnDef, MRT_ExpandedState, MRT_GroupingState, MRT_TableInstance, useMantineReactTable } from 'mantine-react-table'
import { Bounce, toast } from 'react-toastify'
import { jsonData } from 'pages/advanced-reporting/ReportEditor'
import { graphqlCallIterator } from 'pages/resource-explorer/posts'
import { componentWithNumber } from './aggregationData'
import { query } from '../project-widget/widgetHelpFunctions'
import {
  createASelectionList,
  createResourceApiCall,
  CreateResourceChart,
  makeNewQueryRequest,
  noDupsLabelList,
  TagComponent
} from './helper'
import { tourConfig } from './tour'
import { dateFormatChanger } from '../project-labeled-data-widget/chartHelperFunctions'
import { WidgetData } from '../project-widget/widgetStatev2'
import { TutorialContainer } from '../project-tutorial'
import { getWidgetData, setWidgetData } from '../project-widget/widgetDataCaching'

const costProperties = [
  'billedCost',
  'effectiveCost',
  'listCost',
]

interface TableWidgetProps {
  id: string
  currency: TCurrency
  labelRuleDetailsList: effectData[]
  fields: string[]
  editCallBack: () => void
  closeCallBack: () => void
  deleteWidget: () => void
  widgetObject: WidgetData
  setErrorMessage: (msg: string) => void
  deleteErrorMessage: () => void
}

export function TableWidget({ id = '', currency, labelRuleDetailsList, fields, editCallBack, closeCallBack, deleteWidget, widgetObject, setErrorMessage, deleteErrorMessage }: TableWidgetProps) {
  const {
    fetchProjectsStatus,
    fetchProjects
  } = useProject()
  const [tutorialUpdater, setTutorialUpdater] = useState<boolean>(false)
  const { setIsOpen, setSteps, setCurrentStep } = useTour()
  const changeTutorialUpdater = () => {
    setTutorialUpdater(true)
  }
  // ***************************************************************************
  // Reset the options as these need to be updated regularly.
  // Add all the variables that are needed to update the widget regularly.
  // ***************************************************************************
  widgetObject.resetOptions(id)
  const [dataProcessed, setDataProcessed] =
    useState<boolean>(false)
  const [queryKey, setQueryKey] = useState<string>(`initial${id}`)
  const [resourceDetailsProcessed, setResourceDetailsProcessed] = useState<boolean>(true)
  const [, setResourcelayout] = useState<boolean>(false)
  const [expanded, setExpanded] = useState<MRT_ExpandedState>(widgetObject.getTableDataExpanded(id))
  const [tableOk, setTableOk] = useState<boolean>(false)
  const [resourceData, setResourseData] = useState<TFocusGroup[]>([])
  const [dataLoaded, setDataLoaded] = useState<boolean>(
    widgetObject.getTableDataAggregate(id).length === 0
  )
  const [resourceDataParsed, setResourceDataParsed] = useState<TBarChartData[]>([])
  const [editBool, setEditBool] = useState<boolean>(false)
  const [grouping, setGrouping] = useState<MRT_GroupingState>(
    widgetObject.getTableDataAggregate(id)
  );
  const [, setMonthlyCost] = useState<number>(0)
  const [labelRuleDetailsTagList, setLabelRuleDetailsTagList] =
    useState<TagComponent[]>([])

  const [data, setData] = useState<newTFocusGroup[]>(
    getWidgetData(`TableWidget${widgetObject.getTableDataAggregate(id)}`) as newTFocusGroup[]
  )
  const [sessionStorageTag, setSessionStorageTag] = useState<string>(
    `TableWidget${widgetObject.getTableDataAggregate(id)}`
  )
  const [tagSelector, setTagSelector] = useState<string>(widgetObject.getTableDataSelector(id))
  const changeEditBool = () => {
    setEditBool(true)
  }
  const [ref, rect] = useResizeObserver();

  const reloadFunctionality = () => {
    setDataProcessed(false)
    setQueryKey(uuid())
  }
  useEffect(() => {
    if (fetchProjectsStatus.isLoading) reloadFunctionality()
  }, [fetchProjectsStatus])

  const [filterValue, setFilterValue] = useState<string>('')

  const [savedDelimiter, setSavedDelimiter] = useState<string>('en-US');
  const [savedGrouping, setSavedGrouping] = useState<string>('Yes');

  window.addEventListener('storage', () => {
    if (sessionStorage.getItem('delimiter') === ',') {
      setSavedDelimiter('de-DE')
    } else {
      setSavedDelimiter('en-US')
    }
    if (sessionStorage.getItem('grouping') !== null) setSavedGrouping(sessionStorage.getItem('grouping')!)
  })
  useEffect(() => {
    if (sessionStorage.getItem('delimiter') === ',') {
      setSavedDelimiter('de-DE')
    } else {
      setSavedDelimiter('en-US')
    }
    if (sessionStorage.getItem('grouping') !== null) setSavedGrouping(sessionStorage.getItem('grouping')!)
  }, [savedDelimiter, savedGrouping, sessionStorage.getItem('delimiter'), sessionStorage.getItem('grouping')])

  // ***************************************************************************
  // Add the Edit widget to the options of this widget.
  // ***************************************************************************
  // widgetObject.editOptions(id, 'Edit widget', changeEditBool)

  const returnToMainView = () => {
    setEditBool(false)
  }

  widgetObject.editOptions(id, 'Help', () => {
    setCurrentStep(0)
    setIsOpen(true)
    if (setSteps) {
      setSteps(tourConfig(
        id,
        [
          editCallBack,
          changeEditBool,
          closeCallBack,
          returnToMainView,
          changeTutorialUpdater
        ]
      ))
    }
  })

  widgetObject.editOptions(id, 'Reload widget', () => {
    reloadFunctionality()
  })
  widgetObject.editOptions(id, 'Delete widget', () => {
    sessionStorage.removeItem(sessionStorageTag)
    deleteWidget()
  })
  const groceries = [
    {
      emoji: <FaChartBar color="#668957" />,
      value: 'Chart',
      description:
        CreateResourceChart(
          resourceDetailsProcessed,
          fetchProjectsStatus,
          fetchProjects,
          resourceDataParsed,
          currency
        ),
    }
  ];
  const items = groceries.map((item) => (
    <Accordion.Item key={item.value} value={item.value} data-tour={`theAccordionFor${id}`}>
      <Accordion.Control icon={item.emoji}>{item.value}</Accordion.Control>
      <Accordion.Panel>{item.description}</Accordion.Panel>
    </Accordion.Item>
  ));
  // ***************************************************************************
  // All that is needed to create a query and make Api calls is placed in this
  // section.
  // ***************************************************************************

  const [queryRequest, setQueryRequest] =
    useState<query | undefined>(makeNewQueryRequest(tagSelector, grouping))

  const { error } = useQuery({
    queryKey: [`query?${queryKey}`],
    queryFn: async () => {
      try {
        if (!(queryKey === `initial${id}` && data.length !== 0) && !dataProcessed) {
          await graphQLAxios
            .post('', {
              query: graphqlCallIterator(0)
            })
            .then((res) => {
              const theFetchedData = res.data.data
              const accessor = Object.getOwnPropertyNames(res.data.data)
              const mapped = theFetchedData[accessor[0]].map((el: jsonData) => {
                el.all = 'All resources'
                return el
              })
              setData(mapped)
              setWidgetData(sessionStorageTag, `TableWidget${grouping}`, mapped)
              setSessionStorageTag(`TableWidget${grouping}`)
              setDataProcessed(true)
              deleteErrorMessage()
            })
            .catch((error) => {
              if (error.response.data.data) {
                setErrorMessage('There is no data to be found for this configuration.')
                setData([])
                setDataProcessed(true)
              }
            })
        } else if ((queryKey === `initial${id}` && data.length !== 0)) {
          setDataProcessed(true)
        }
        if (!dataProcessed && grouping.length !== 0) {
          setDataProcessed(true)
        }
        if (!resourceDetailsProcessed) {
          await axios
            .post<TCostHistoryQueryResponse>('/query', queryRequest)
            .then((res) => {
              setResourceDetailsProcessed(true)
              if (res.data.data.length !== 0) {
                toast.success('Chart has been created.', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  transition: Bounce,
                })
                setResourseData(res.data.data)
              } else {
                toast.error('No historical data found.', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  transition: Bounce,
                })
              }
            })
            .catch(() => {
              setResourceDetailsProcessed(true)
              setResourseData([])
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })

  // ***************************************************************************
  // These are checks for when we are selecting tags, if you are a part of the
  // you have to be able to be deleted from it.
  // A tag who's not in the span and none of it's parents are the last of the span
  // (if there are parents)can not ever be selected until the aggregate is empty
  // again or a parent is added.
  // ***************************************************************************

  function isPartOfAggregate(aggregate: string[], el: string) {
    const temp = aggregate.findIndex((piece) => { return piece === el })
    return temp !== -1
  }
  function parentInAggrgate(el: string, aggregate: string[]) {
    if (aggregate.length === 0) return true
    const temp = labelRuleDetailsList.filter((Obj) => Obj.name === el)
    const aggregatePieces = aggregate
    const lastParent = labelRuleDetailsList.find((el) => {
      return el.name === aggregatePieces[aggregatePieces.length - 1]
    })
    let res = false
    temp.forEach((Obj) => {
      if (lastParent !== undefined) {
        if (Obj.parentId === lastParent?.id) res = true
      }
    })
    return res
  }
  // ***************************************************************************
  // This remakes the list of tags, so that all know if they can be added or not.
  // ***************************************************************************
  function remakeList(
    aggregate: string[],
    list: { tag: string, selected: boolean, parentConditionMet: boolean }[]
  ) {
    const temp = list.map((el) => {
      if (tagSelector === 'labels') {
        return {
          tag: el.tag,
          selected: isPartOfAggregate(aggregate, el.tag),
          parentConditionMet: parentInAggrgate(el.tag, aggregate)
        }
      }
      return {
        tag: el.tag,
        selected: isPartOfAggregate(aggregate, el.tag),
        parentConditionMet: true
      }
    })
    temp.sort((a, b) => a.tag.localeCompare(b.tag))
    setLabelRuleDetailsTagList(temp)
  }

  useEffect(() => {
    let temp = []
    if (tagSelector === 'fields') {
      temp = fields.map((el) => {
        return { tag: el, selected: false, parentConditionMet: true }
      })
    } else {
      temp = noDupsLabelList(labelRuleDetailsList).map((el) => {
        return { tag: el, selected: false, parentConditionMet: true }
      })
    }
    remakeList(grouping, temp)
  }, [labelRuleDetailsList, fields])

  // ***************************************************************************
  // If we get to the resources of the data, we can display the data in a chart.
  // ***************************************************************************
  function newRDetails(name: string, value: number): TBarChartData {
    return { name, value, color: '#8884d8' }
  }
  useEffect(() => {
    const tempData: TBarChartData[] = []
    if (resourceData.length !== 0) {
      resourceData.forEach((el) => {
        const obj = el[Object.getOwnPropertyNames(el)[0]]
        if (obj.resourceId !== undefined) {
          const date: Date = obj.timestamp
          const cost = Number(obj.totalBilledCost)
          const newDetails = newRDetails(dateFormatChanger(date.toString()), cost)
          tempData.push(newDetails)
        }
      })
      setResourceDataParsed(tempData)
      setDataLoaded(true)
    }
  }, [resourceData])

  useEffect(() => {
    if (resourceDataParsed.length !== 0 && !dataLoaded) {
      setDataLoaded(true)
    }
  }, [resourceDataParsed])

  function up(tag: string) {
    const index = grouping.findIndex((el) => {
      return el === tag
    })
    grouping.splice(index - 1, 0, grouping.splice(index, 1)[0])
    remakeList(grouping, labelRuleDetailsTagList)
  }

  function down(tag: string) {
    const index = grouping.findIndex((el) => {
      return el === tag
    })
    grouping.splice(index + 1, 0, grouping.splice(index, 1)[0])
    remakeList(grouping, labelRuleDetailsTagList)
  }

  // function createCost(
  //   cell: MRT_Cell<jsonData, unknown>
  // ) {
  //   return (
  //     <span>
  //       {Number(cell.getValue<number>()).toLocaleString?.('en-US', {
  //         style: 'currency',
  //         currency: 'USD'
  //       })}
  //     </span>
  //   )
  // }

  function toFlat(str: string) {
    const camelCase = str.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ')

    let flat = ''
    let initial = true

    camelCase.forEach((word) => {
      if (initial) {
        flat = flat.concat(word.charAt(0).toUpperCase()).concat(word.slice(1)).concat(' ')
        initial = false
      } else flat = flat.concat(word.charAt(0).toLowerCase()).concat(word.slice(1)).concat(' ')
    })
    return flat
  }
  function createMeanAggregated(
    cell: MRT_Cell<jsonData, unknown>,
    table: MRT_TableInstance<jsonData>
  ) {
    return (
      <>
        Total {' '}
        {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header.toLowerCase()}:{' '}
        <Box style={{ color: '#668957', fontWeight: 'bold' }}>
          {cell.getValue<number>()?.toLocaleString?.(savedDelimiter, {
            style: 'currency',
            currency: 'USD',
            useGrouping: savedGrouping === 'Yes'
          })}
        </Box>
      </>
    )
  }
  function makeCost(num: number | string) {
    return `${new Intl.NumberFormat(savedDelimiter, {
      useGrouping: savedGrouping === 'Yes'
    }).format(Number(Number(num).toFixed(2)))}`
  }
  const columnstest = useMemo<MRT_ColumnDef<jsonData>[]>(() => {
    if (data.length === 0) return []
    return costProperties.concat(Object.getOwnPropertyNames(data[0]).filter((el) => {
      return costProperties.findIndex((prop) => prop === el) === -1
    })).map((property) => {
      const test = costProperties.findIndex((el) => el === property)
      if (test !== -1) {
        return {
          header: toFlat(property),
          accessorKey: property,
          accessorFn: (row) => row[property],
          aggregationFn: 'sum',
          enableColumnFilterModes: false,
          AggregatedCell: ({ cell, table }) => createMeanAggregated(cell, table),
          Cell: ({ cell }) => `${currency.symbol} ${makeCost(cell.getValue<number | string>())}`,
        }
      }
      return {
        header: toFlat(property),
        accessorKey: property,
        accessorFn: (row) => row[property],
        filterVariant: 'autocomplete',
      }
    })
  }, [data, savedDelimiter, savedGrouping])

  useEffect(() => {
    if (columnstest.length > 0) setTableOk(true)
  }, [columnstest])

  useEffect(() => {
    widgetObject.setTableDataAfterNewCall(id, grouping, tagSelector)
  }, [grouping])
  //--------------------------------------------------------------------------
  // Here we configure the table with the columns and the data.
  // We give it some extra styling properties, and make sure we can group
  // resize and have multiple pages.
  // here we also load in some custom Icons from font awesome, these can be
  // found in icons.tsx, a file provided in a tutorial on the page for the
  // table library: https://v2.mantine-react-table.com/docs/examples/aggregation-and-grouping
  // important to note is that we used v2 in this project, it is simply better.
  //--------------------------------------------------------------------------

  useEffect(() => {
    widgetObject.setTableDataExpanded(id, expanded)
  }, [expanded])

  const tabletest = useMantineReactTable({
    columns: columnstest,
    data,
    enableColumnResizing: true,
    enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnOrdering: true,
    enableColumnVirtualization: true,
    enableFilterMatchHighlighting: true,
    enableFullScreenToggle: false,
    onGroupingChange: setGrouping,
    autoResetPageIndex: false,
    onExpandedChange: setExpanded,
    globalFilterFn: 'contains',
    defaultColumn: {
      size: 200,
    },
    state: {
      grouping: ['all'].concat(grouping.map((el) => {
        let res = ''
        const splitted = el.split(' ')
        splitted.forEach((part) => {
          if (res === '') {
            res = part.charAt(0).toLowerCase() + part.slice(1)
          } else {
            res = `${res}${part.charAt(0).toUpperCase() + part.slice(1)}`
          }
        })
        return res
      })),
      expanded
    },
    initialState: {
      density: 'xs',
      expanded: true,
    },
    icons: fontAwesomeIcons,
    mantineTableBodyRowProps: ({ row }) => ({
      className: row.getValue('provider') && row.getValue('resourceId') ? 'hover:cursor-pointer' : '',
      onClick: () => {
        // if (!(row.getValue('provider') && row.getValue('resourceId'))) console.log(row)
        if (row.getValue('provider') && row.getValue('resourceId')) {
          setQueryKey(uuid())
          setResourceDataParsed([])
          setResourceDetailsProcessed(false)
          setResourcelayout(true)
          createResourceApiCall(
            setQueryRequest,
            row.getValue('provider'),
            row.getValue('resourceId')
          )
        } else {
          setResourcelayout(false)
          setResourceDataParsed([])
        }
      },
      sx: { cursor: 'pointer' },
    }),
    mantineTableContainerProps: {
      style: {
        maxHeight: rect.height
      }
    },
    mantineTableProps: {
      style: {
        height: rect.height - 200,
        maxHeight: rect.height
      }
    },
    mantineToolbarAlertBannerBadgeProps: { color: '#668957', variant: 'outline' },
    mantinePaperProps: {
      shadow: 'none',
      style: {
        border: '0px solid white',
      },
    },
  })

  // ***************************************************************************
  // The render of the widget
  // ***************************************************************************
  if (error) return <div>An error has occurred</div>
  return (
    <div key={`ATableWidgetWithId${id}`} data-tour={`ATableWidgetWithId${id}`} ref={ref} className="h-full">

      <TutorialContainer
        tag="AggregationWidget"
        updater={tutorialUpdater}
        tourConfig={
          tourConfig(
            id,
            [
              editCallBack,
              changeEditBool,
              closeCallBack,
              returnToMainView,
              changeTutorialUpdater
            ]
          )
        }
      >
        <div className="block" style={{ height: `${rect.height.toFixed(1)}px` }}>
          <SpinnerLoader isLoading={!dataProcessed}>
            {editBool && (
              // ***************************************************************************
              // This is all the code for the edit part of the widget:
              // There is  a span with the tooltip explaining the widget
              // Then there are the 2 main components
              // One with the tabs that allow the user to select which tags are displayed,
              // in that same one there is also a search bar and the list of tags.
              // the other one shows all selected tags.
              // the last part are 2 buttons to save or cancel.
              // ***************************************************************************
              <div className="flex flex-col justify-items-stretch h-full overflow-auto">
                <div className="flex flex-row h-fit">
                  <span
                    key={`spanForTheEditingScreenForTableWidget${id}`}
                    className="text-gray-600 font-bold text-lg span w-full"
                  >
                    Select the tags you want to aggregate your data with.
                    <Tooltip
                      className="opacity-50"
                      title="Here you can select the tags that are used by the api to fetch your data for the table widget.
                          Choose the order of these tags to make sure the data is displayed in the order you want to view it in."
                    >
                      <ButtonIcon>
                        <FaQuestion color="#4b5563" size={15} />
                      </ButtonIcon>
                    </Tooltip>
                  </span>

                </div>
                <div className="flex h-full justify-center overflow-auto">
                  <div className="flex flex-col h-full w-full max-w-[800px]">
                    <div className="flex gap-1 w-full h-fit">
                      <div className="w-1/2 ">
                        <Tabs color="#787885" defaultValue="fields">
                          <Tabs.List>
                            <Tabs.Tab
                              className={`${tagSelector === 'fields' ? 'bg-[#787885] text-white border-t border-x border-t-[#787885] border-x-[#787885]' : ''}`}
                              value="fields"
                              onClick={() => {
                                const temp = fields.map((el) => {
                                  return { tag: el, selected: false, parentConditionMet: true }
                                })
                                setTagSelector('fields')
                                setFilterValue('')
                                setGrouping([])
                                remakeList([], temp)
                              }}
                            >
                              Fields
                            </Tabs.Tab>
                            <Tabs.Tab
                              value="labels"
                              className={`${tagSelector === 'labels' ? 'bg-[#787885] text-white border-t border-x border-t-[#787885] border-x-[#787885]' : ''}`}
                              onClick={() => {
                                const temp = noDupsLabelList(labelRuleDetailsList).map((el) => {
                                  return { tag: el, selected: false, parentConditionMet: true }
                                })
                                setTagSelector('labels')
                                setFilterValue('')
                                setGrouping([])
                                remakeList([], temp)
                              }}
                            >
                              Labels
                            </Tabs.Tab>
                          </Tabs.List>
                        </Tabs>
                      </div>
                      <div id={`placeholder${id}`} className="w-1/2" />
                    </div>

                    <div className="flex gap-1 h-max w-full overflow-hidden">
                      <div className="relative border border-[#787885] w-1/2 h-full rounded-b-lg">
                        <div className="sticky border-b border-[#787885] w-full">
                          <input
                            className="w-full h-12"
                            placeholder="Search a tag"
                            autoComplete="off"
                            onChange={(event) => {
                              setFilterValue(event.target.value)
                            }}
                          />
                        </div>
                        <div
                          className="h-full overflow-y-auto px-3 pb-12"
                        >
                          <div>
                            {createASelectionList(
                              labelRuleDetailsTagList,
                              tagSelector,
                              filterValue,
                              grouping,
                              setGrouping,
                              remakeList
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="border border-[#787885] w-1/2 h-full overflow-y-auto rounded-b-lg justify-start content-start">
                        {grouping.map((el, idx) => {
                          const maxNumber = grouping.length - 1
                          if (el === '') return <div />
                          return (
                            <div
                              key={`aggrgateStringPartFor${id}WithValue${el}`}
                              className="py-1 px-1 max-w-full"
                            >
                              {componentWithNumber(
                                el,
                                idx,
                                maxNumber,
                                up,
                                down,
                                () => {
                                  let newAggregate: string[] = []
                                  if (grouping[0] === el) {
                                    if (grouping.length === 1) newAggregate = []
                                    else newAggregate = grouping.slice(1)
                                  } else {
                                    const index =
                                      grouping.findIndex((piece) => { return el === piece })
                                    const temp1 = grouping.slice(0, index)
                                    const temp2 = grouping.slice(index + 1)
                                    newAggregate = temp1.concat(temp2)
                                  }
                                  setGrouping(newAggregate)
                                  remakeList(newAggregate, labelRuleDetailsTagList)
                                }
                              )}
                            </div>
                          )
                        })}
                      </div>

                    </div>
                  </div>
                </div>

                <div className="justify-self-center py-4 flex place-content-center">
                  <div className="px-3">
                    <Button
                      className="bg-white hover:bg-[#787885] text-[#787885] hover:text-white border-2 border-[#787885]"
                      onClick={() => {
                        // setPath(getTableDataPath(id))
                        setGrouping(widgetObject.getTableDataAggregate(id))
                        setEditBool(false)
                        setFilterValue('')
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className="px-3">
                    <Button
                      className="bg-[#668957] hover:bg-[#93ac89] border-2 border-[#668957]"
                      onClick={() => {
                        if (grouping.length !== 0) {
                          const newQuery = makeNewQueryRequest(tagSelector, grouping)
                          setQueryRequest(newQuery)
                          setEditBool(false)
                          setDataProcessed(false)
                          setQueryKey(uuid())
                          widgetObject.setTableDataAfterNewCall(id, grouping, tagSelector)
                          // widgetObject.setTableDataWhenWalkingThroughData(id, [])
                          setFilterValue('')
                          // setPath([])
                          setResourseData([])
                          setResourceDataParsed([])
                          setResourcelayout(false)
                        } else {
                          setEditBool(false)
                          setData([])
                          setMonthlyCost(0)
                          setResourcelayout(false)
                          widgetObject.setTableDataAfterNewCall(id, grouping, tagSelector)
                          // widgetObject.setTableDataWhenWalkingThroughData(id, [])
                          setFilterValue('')
                          // setPath([])
                          setResourseData([])
                          setResourceDataParsed([])
                          setResourcelayout(false)
                        }
                      }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </div>
            )}
            {!editBool && (
              // ***************************************************************************
              // This is all the code for the display of data
              // We have a path that can be clicked to go back
              // The table cards that show some more information about the table
              // The table itself follows
              // When we get to the last part of the data ie. a resource it will also show a chart
              // This is the last part of this side of the widget.
              // ***************************************************************************
              <div className="h-full overflow-x-auto">
                <BsTable size={20} />
                <Accordion>
                  {items}
                </Accordion>
                <div
                  className="flex items-center w-full text-wrap"
                >
                  {tableOk && (
                    <MantineProvider
                      theme={{
                        primaryColor: 'green',
                        primaryShade: 8,
                        colors: {
                          green: [
                            '#668957',
                            '#668957',
                            '#668957',
                            '#668957',
                            '#668957',
                            '#668957',
                            '#668957',
                            '#668957',
                            '#668957',
                            '#668957'
                          ],
                        },
                      }}
                    >
                      {/* <MantineReactTable table={table} />; */}
                      <SpinnerLoader isLoading={!tableOk}>
                        <MantineReactTable
                          table={tabletest}
                        />
                      </SpinnerLoader>
                    </MantineProvider>
                  )}
                </div>
              </div>
            )}
          </SpinnerLoader>
        </div>
      </TutorialContainer>
    </div>
  )
}
