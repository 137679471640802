import { returnElementByTag } from 'features/projects/project-summary-widgets/summaryWidgetHelperFunctions';
import useAuthentication from 'hooks/useAuthentication'

export function SummaryBar() {
  const { currency } = useAuthentication()
  const useProgressBar = false
  return (
    <div className="w-full flex justify-center">
      <div className="md:flex flex-row rounded-lg w-11/12 border-b border-[#5b7b4e]">
        <div className="md:flex md:w-1/3">
          <div className="w-full h-full">
            {useProgressBar && (
              <div>
                <div className="border border-gray-200 bg-white rounded-md p-2 m-1 shadow-md">
                  {returnElementByTag('ThisMonthSpend', 'ThisMonthSpend', currency)}
                </div>
                <div className="border border-gray-200 bg-white rounded-md p-2 m-1 shadow-md">
                  {returnElementByTag('PreviousMonthSpend', 'PreviousMonthSpend', currency)}
                </div>
              </div>
            )}
            {!useProgressBar && (
              <div className="md:flex h-4/6">
                <div className="border border-[#e6ffee] bg-[#e6ffee] rounded-md p-2 m-1 shadow-md md:w-1/2">
                  {returnElementByTag('ThisMonthSpend', 'ThisMonthSpend', currency)}
                </div>
                <div className="border border-[#e1f8ff] bg-[#e1f8ff] rounded-md p-2 m-1 shadow-md md:w-1/2">
                  {returnElementByTag('PreviousMonthSpend', 'PreviousMonthSpend', currency)}
                </div>
              </div>
            )}
            <div className="bg-[#fdfce4] border border-[#fdfce4] rounded-md p-2 m-1 shadow-md h-2/6">
              {returnElementByTag('PredictedMonthSpend', 'PredictedMonthSpend', currency)}
            </div>
          </div>
        </div>
        <div className="md:w-2/3 flex">
          <div className="w-full bg-white border border-gray-200 rounded-md m-1 shadow-md h-full">
            {returnElementByTag('TopServiceBySpend', 'TopServiceBySpend', currency)}
          </div>
          <div className="w-full border border-gray-200 bg-white rounded-md m-1 shadow-md h-full">
            {returnElementByTag('RegionPercentage', 'RegionPercentage', currency)}
          </div>
        </div>
      </div>
    </div>
  )
}
