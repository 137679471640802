export const tourConfig = (callbackList: (() => void)[]) => {
  return [
    {
      selector: '[data-tour="fullApp"]',
      content: 'Welcome! You have just taken the first step to understanding more about your data! This is the home page. Here you can access a variety of functionalities: the widgets, reports and labelrules',
      action: () => { callbackList[0]() }
    },
    {
      selector: '[data-tour="sidebar"]',
      content: 'This is the sidebar, using this you can change the page. This in turn changes what you can do with the page'
    },
    {
      selector: '[data-tour="topbar"]',
      content: 'The top bar will allow you to refresh the data, look at your profile and log out.'
    },
    {
      selector: '[data-tour="addWidgetButton"]',
      content: 'This button will allow you to choose a widget to add to the home screen. These will be remembered the next time you log in.'
    },
    {
      selector: '[data-tour="fullApp"]',
      content: 'There are many different widgets that you can add. Some are predefined, like the "Total spend by product" and "Spend for previous month and trends". Others are configurable, these are the widgets for the more technical users or when you understand your data enough. Some examples are "Aggregate cost by" and "group cost by" widgets.',
      action: () => { callbackList[1]() },
    },
    {
      selector: '[data-tour="showSummaryBarButton"]',
      content: 'This is the button to show the summary bar.'
    },
    {
      selector: '[data-tour="fullApp"]',
      content: 'Clicking on it will show you this banner. Here you can get a general overview of what your data means.',
      action: () => {
        callbackList[2]()
      }
    },
    {
      selector: '[data-tour="fullApp"]',
      content: 'Enjoy!',
      action: () => {
        callbackList[3]()
      }
    }
  ]
}
