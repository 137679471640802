import { useState } from 'react'
import { v4 as uuid } from 'uuid'
import { axios } from 'utils/axios'
import { TFocusGroup } from 'services/costhistory.interface'
import { useQuery } from 'react-query'
import { FetchStatus } from 'components'
import useProject from 'hooks/useProject'
import { Sparkline } from '@mantine/charts'
// import { FaChartLine } from 'react-icons/fa'
import { query } from '../project-widget/widgetHelpFunctions'

export interface TopServiceBySpendProps {
  id: string,
  key: string
}
export function TopServiceBySpend({ id, key }: TopServiceBySpendProps) {
  const {
    fetchProjectsStatus,
  } = useProject()
  const [awsData, setAwsData] = useState<TFocusGroup[]>([])
  const [azureData, setAzureData] = useState<TFocusGroup[]>([])
  const [gcpData, setGcpData] = useState<TFocusGroup[]>([])
  const [awsDataProcessed, setAwsDataProcessed] =
    useState<boolean>(false)
  const [azureDataProcessed, setAzureDataProcessed] =
    useState<boolean>(false)
  const [gcpDataProcessed, setGcpDataProcessed] =
    useState<boolean>(false)
  const [awsQuery,] = useState<query>(
    {
      name: uuid(),
      description: '',
      options: {
        saveQuery: false,
        accumulate: true,
        aggregate: 'serviceName',
        step: '1m',
        window: 'lastmonth'
      },
      filters: [{
        field: 'provider',
        value: 'AWS',
        operator: 'EQUALS'
      }
      ],
      limit: 10
    }
  )
  const [azureQuery,] = useState<query>(
    {
      name: uuid(),
      description: '',
      options: {
        saveQuery: false,
        accumulate: true,
        aggregate: 'serviceName',
        step: '1m',
        window: 'lastmonth'
      },
      filters: [{
        field: 'provider',
        value: 'Azure',
        operator: 'EQUALS'
      }
      ],
      limit: 10
    }
  )
  const [gcpQuery,] = useState<query>(
    {
      name: uuid(),
      description: '',
      options: {
        saveQuery: false,
        accumulate: true,
        aggregate: 'serviceName',
        step: '1m',
        window: 'lastmonth'
      },
      filters: [{
        field: 'provider',
        value: 'Google Cloud',
        operator: 'EQUALS'
      }
      ],
      limit: 10
    }
  )
  const changeQueryId = () => {
    console.log('placeholder')
  }
  const { error } = useQuery({
    queryKey: [`query?${JSON.stringify(awsQuery)}`],
    queryFn: async () => {
      try {
        if (!awsDataProcessed) {
          await axios
            .post('/query', awsQuery)
            .then((res) => {
              setAwsData(res.data.data)
              setAwsDataProcessed(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                setAwsData([])
                setAwsDataProcessed(true)
              }
            })
        }
        if (!azureDataProcessed) {
          await axios
            .post('/query', azureQuery)
            .then((res) => {
              setAzureData(res.data.data)
              setAzureDataProcessed(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                setAzureData([])
                setAzureDataProcessed(true)
              }
            })
        }
        if (!gcpDataProcessed) {
          await axios
            .post('/query', gcpQuery)
            .then((res) => {
              setGcpData(res.data.data)
              setGcpDataProcessed(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                setGcpData([])
                setGcpDataProcessed(true)
              }
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })

  function getTopServices() {
    const result = {
      awsResult: awsData.sort((a, b) => {
        const aName = Object.getOwnPropertyNames(a)[0]
        const bName = Object.getOwnPropertyNames(b)[0]
        return Number(b[bName].totalBilledCost) - Number(a[aName].totalBilledCost)
      }).slice(0, 3).map((el) => {
        const name = Object.getOwnPropertyNames(el)[0]
        return el[name].serviceName
      }),
      azureResult: azureData.sort((a, b) => {
        const aName = Object.getOwnPropertyNames(a)[0]
        const bName = Object.getOwnPropertyNames(b)[0]
        return Number(b[bName].totalBilledCost) - Number(a[aName].totalBilledCost)
      }).slice(0, 3).map((el) => {
        const name = Object.getOwnPropertyNames(el)[0]
        return el[name].serviceName
      }),
      gcpResult: gcpData.sort((a, b) => {
        const aName = Object.getOwnPropertyNames(a)[0]
        const bName = Object.getOwnPropertyNames(b)[0]
        return Number(b[bName].totalBilledCost) - Number(a[aName].totalBilledCost)
      }).slice(0, 3).map((el) => {
        const name = Object.getOwnPropertyNames(el)[0]
        return el[name].serviceName
      }),
    }
    return result
  }

  if (error) return <div>An error has occurred</div>
  return (
    <div id={id} key={key} className="w-full h-full">
      <div key={key} className="h-full">
        <FetchStatus status={fetchProjectsStatus} retry={changeQueryId}>
          <div className="h-full">
            <div className="">
              <div className="p-2">
                <span className="text-gray-600 text-xs">Top <span className="font-bold">AWS</span> services by spend</span>
                <span className="font-semibold text-gray-800 text-sm flex">
                  {
                    getTopServices().awsResult.map((el, idx) => {
                      return <div className="px-2"><span>#{idx + 1} {el}{idx !== 2 ? ',' : ''}</span></div>
                    })
                  }
                </span>
              </div>
              <div className="p-2">
                <span className="text-gray-600 text-xs">Top <span className="font-bold">Azure</span> services by spend</span>
                <span className="font-semibold text-gray-800 text-sm flex">
                  {
                    getTopServices().azureResult.map((el, idx) => {
                      return <div className="px-2"><span>#{idx + 1} {el}{idx !== 2 ? ',' : ''}</span></div>
                    })
                  }
                </span>
              </div>
              <div className="p-2">
                <span className="text-gray-600 text-xs">Top <span className="font-bold">Google Cloud</span> services by spend</span>
                <span className="font-semibold text-gray-800 text-sm flex">
                  {
                    getTopServices().gcpResult.map((el, idx) => {
                      return <div className="px-2"><span>#{idx + 1} {el}{idx !== 2 ? ',' : ''} </span></div>
                    })
                  }
                </span>
              </div>
            </div>
            {/* <div className="flex justify-end h-[120px]"> */}
            {/* <FaChartLine color="#668957" size={40} /> */}
            <Sparkline
              w="100%"
              h={100}
              data={[10, 60, 20, 60, 80, 30, 100]}
              curveType="linear"
              color="#668957"
              fillOpacity={0.8}
              strokeWidth={1}
            />
            {/* </div> */}
          </div>
        </FetchStatus>
      </div>
    </div>
  )
}
