import { Modal, ModalBody, ModalHeader } from 'components/modal';
import { Avatar } from '@mantine/core';
import { profile } from '..';

interface showProfileProps {
  showProfileBool: boolean
  data: profile | undefined
  onClose: () => void
  profileIcon: string
}

export function ShowProfile({
  showProfileBool,
  data,
  onClose,
  profileIcon
}: showProfileProps) {
  return (
    <Modal isOpen={showProfileBool} onClose={onClose} disableBackdropClick>
      <ModalHeader onClose={onClose} className="border-b border-gray-700">
        <Avatar src={profileIcon} size={80} color="white" className="bg-[#668957]" />
        {`${data?.firstname} ${data?.lastname}`}
      </ModalHeader>
      <ModalBody className="px-5 py-1 w-[50vw] mt-3 mb-3">
        <div className="border border-gray-700 rounded-lg">
          <div className="bg-gray-200 border-b border-gray-700 rounded-t-lg p-1">
            <span>User information:</span>
          </div>
          <div className="w-full flex">
            <div className="w-1/4 bg-gray-100 border-b border-gray-700 p-1">Company</div>
            <div className="w-3/4 p-1 border-b  border-l border-gray-700">{data?.company}</div>
          </div>
          <div className="w-full flex">
            <div className="w-1/4 bg-gray-100 p-1 rounded-b-lg">E-mail</div>
            <div className="w-3/4 border-l border-gray-700 p-1">{data?.email}</div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}
