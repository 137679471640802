import { getLastMonth } from 'features/projects/project-datepicker/helpFunction'
import useAuthentication from 'hooks/useAuthentication'
import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { toast, Bounce } from 'react-toastify'
import { graphQLAxios } from 'utils'
import { BarChart, getFilteredChartTooltipPayload, PieChart } from '@mantine/charts'
import { Chip, Paper, Text } from '@mantine/core'
import { useResizeObserver } from '@mantine/hooks'
import { IoMdArrowRoundBack } from 'react-icons/io';
import { TPieChartData } from 'services/chart.interface'
import { getSmallestVal, parseData, parsePieData, RenderLegend } from 'features/projects/project-labeled-data-widget/chartHelperFunctions'
import { jsonData } from 'pages/resource-explorer/constants'
import { TCurrency } from 'services/cost.interface'
// import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts'
import { GET_CHART_COLORS } from 'constants/color.constants'
import { FaCheck, FaX } from 'react-icons/fa6'
import { projectAndTheirApps } from 'features/projects/project-widget/widgetHelpFunctions'
import { ServiceCostsRegionApiCall } from '../graphQlApiCalls'
import { drawLineIf, drawNumberIf } from '..'
import { getProviderColor } from './AnomalousDailySpendingRSN'
// import { drawNumberIf, drawLineIf } from '..'

interface newChartTooltipProps {
  label: string,
  payload?: jsonData[],
  currency: TCurrency
}
export function ChartTooltip({ label, payload, currency }: newChartTooltipProps) {
  if (!payload) return null;
  const otherElement = getFilteredChartTooltipPayload(payload).find((el) => el.name === 'Other')
  return (
    <Paper px="md" py="sm" withBorder shadow="md" radius="md">
      <Text fw={500} mb={5}>
        {label}
      </Text>
      {getFilteredChartTooltipPayload(payload).filter((el) => el.name !== 'Other').sort((a, b) => b.value - a.value)
        .map((item: any) => {
          let name = ''
          if (item.name === '') {
            name = 'Taxes'
          } else {
            name = item.name
            if (name.length > 20) {
              name = `${name.slice(0, 20)}...`
            }
          }
          return (
            <div
              className="flex p-1 w-fit h-fit min-h-2 z-10"
              style={{ color: `${item.color}` }}
            >
              <div className="mr-2" style={{ border: `2px solid ${item.color}`, borderRadius: '1rem' }} />
              <Text key={item.name} c="dark" fz="sm">
                <span className="font-semibold text-gray-600 text-sm">{currency.symbol}{item.value.toFixed(2)}</span> <span className="text-gray-400 text-xs w-24 text-wrap">{name}</span>
              </Text>
            </div>
          )
        })}
      {otherElement && (
        <div
          className="flex p-1 w-fit h-fit min-h-2 z-10"
          style={{ color: `${otherElement.color}` }}
        >
          <div className="mr-2" style={{ border: `2px solid ${otherElement.color}`, borderRadius: '1rem' }} />
          <Text key={otherElement.name} c="dark" fz="sm">
            <span className="font-semibold text-gray-600 text-sm">{currency.symbol}{otherElement.value.toFixed(2)}</span> <span className="text-gray-400 text-xs w-24 text-wrap">{otherElement.name !== '' ? otherElement.name : 'Taxes'}</span>
          </Text>
        </div>
      )}
    </Paper>
  );
}

type chartDataColors = { name: string, color: string }

type serviceCostsRegionType = {
  chargePeriodStart: string
  provider: string
  region: string
  serviceName: string
  totalEffectiveCost: number
}
interface ServiceCostsRegionProp {
  id: string,
  height: number,
}
export function ServiceCostsRegion({ id, height }: ServiceCostsRegionProp) {
  const { currency } = useAuthentication()
  const timeWindow = getLastMonth()
  const [dataLoaded, setDataLoaded] = useState<boolean>(false)
  const [queryKey] = useState<string>(`initial-${id}`)
  const [data, setData] = useState<serviceCostsRegionType[]>([])
  const [clickedRegion, setClickedRegion] = useState<string>('')
  const [clickedTotalCost, setClickedTotalCost] = useState<number>(0)
  const [pieChartData, setPieChartData] = useState<TPieChartData[]>([])
  const [lineChartData, setLineChartData] = useState<projectAndTheirApps[]>([])
  const [linechartDataColors, setLineChartDataColors] = useState<chartDataColors[]>([])
  const [totalCost, setTotalCost] = useState<number>(0)

  const [savedDelimiter, setSavedDelimiter] = useState<string>('en-US');
  const [savedGrouping, setSavedGrouping] = useState<string>('Yes');

  window.addEventListener('storage', () => {
    console.log(totalCost)
    if (sessionStorage.getItem('delimiter') === ',') {
      setSavedDelimiter('de-DE')
    } else {
      setSavedDelimiter('en-US')
    }
    if (sessionStorage.getItem('grouping') !== null) setSavedGrouping(sessionStorage.getItem('grouping')!)
  })

  useEffect(() => {
    if (sessionStorage.getItem('delimiter') === ',') {
      setSavedDelimiter('de-DE')
    } else {
      setSavedDelimiter('en-US')
    }
    if (sessionStorage.getItem('grouping') !== null) setSavedGrouping(sessionStorage.getItem('grouping')!)
  }, [savedDelimiter, savedGrouping, sessionStorage.getItem('delimiter'), sessionStorage.getItem('grouping')])

  const { error } = useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      try {
        if (!dataLoaded) {
          const query =
            ServiceCostsRegionApiCall(timeWindow[0].toISOString().replace('T', ' ').replace('Z', ''), timeWindow[1].toISOString().replace('T', ' ').replace('Z', ''))
          await graphQLAxios
            .post('', { query })
            .then((res) => {
              setData(res.data.data.serviceCostsRegion)
              setDataLoaded(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                toast.error('No labels have been created.', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  transition: Bounce,
                })
              }
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })

  const [providers, setProviders] = useState<string[]>([])
  const [providersChecked, setProvidersChecked] = useState<string[]>([])

  useEffect(() => {
    const newProviders: string[] = []
    data.forEach((el) => {
      const fIndex = newProviders.findIndex((provider) => provider === el.provider)
      if (fIndex === -1) newProviders.push(el.provider)
    })
    setProviders(newProviders)
    setProvidersChecked(newProviders)
  }, [data])

  useEffect(() => {
    // console.log(currency)
    // console.log(data)
    if (clickedRegion === '') {
      const newChartData: TPieChartData[] = []
      let newTotalCost = 0
      let colorCtr = 0
      data.forEach((el) => {
        const theRegion = el.region === '' ? 'Taxes (no region and once a month)' : el.region
        const fIndex = newChartData.findIndex((chartEl) => chartEl.name === theRegion)
        let include = true
        include = providersChecked.findIndex((prov) => prov === el.provider) !== -1
        if (fIndex === -1 && include) {
          newTotalCost += el.totalEffectiveCost
          newChartData.push({
            name: theRegion,
            value: Number(el.totalEffectiveCost.toFixed(2)),
            color: GET_CHART_COLORS(colorCtr)
          })
          colorCtr++
        } else if (include) {
          newChartData[fIndex].value += Number(el.totalEffectiveCost.toFixed(2))
        }
      })
      setTotalCost(newTotalCost)
      setPieChartData(newChartData)
    } else {
      const theRegion = clickedRegion === 'Taxes (no region and once a month)' ? '' : clickedRegion
      const newData: projectAndTheirApps[] = []
      data.filter((el) => {
        let include = true
        include = providersChecked.findIndex((prov) => prov === el.provider) !== -1
        return el.region === theRegion && include
      }).forEach((el) => {
        const day = new Date(el.chargePeriodStart).getDate()
        const idx = newData.findIndex((obj) => obj.label === day.toString())
        // const idxColors = newDataColors.findIndex((obj) => obj.name === el.serviceName)
        if (idx === -1) {
          newData.push({
            label: day.toString(),
            [el.serviceName]: el.totalEffectiveCost
          })
        } else {
          const newObj = newData[idx]
          if (newObj[el.serviceName]) {
            newObj[el.serviceName] = Number(newObj[el.serviceName]) + el.totalEffectiveCost
          } else {
            newObj[el.serviceName] = el.totalEffectiveCost
          }
          newData[idx] = newObj
        }
        // if (idxColors === -1) {
        //   newDataColors.push({
        //     name: el.serviceName,
        //     color: GET_CHART_COLORS(newDataColors.length)
        //   })
        // }
      })
      // console.log(newData.sort((a, b) => a.day.localeCompare(b.day)))
      const parsedData = parseData(newData.sort((a, b) => Number(a.label) - Number(b.label)))
      setLineChartData(parsedData)
      const newDataColors: chartDataColors[] = []
      setLineChartData(parsedData)
      parsedData.forEach((el) => {
        const properties = Object.getOwnPropertyNames(el).filter((el) => el !== 'label')
        properties.forEach((property) => {
          const findIndex = newDataColors.findIndex((el) => el.name === property)
          if (findIndex === -1) {
            newDataColors.push({
              name: property,
              color: GET_CHART_COLORS(newDataColors.length)
            })
          }
        })
      })
      setLineChartDataColors(newDataColors)
    }
  }, [providersChecked, clickedRegion])

  const [ref, rect] = useResizeObserver();

  if (error) return <div>An error has occurred</div>
  return (
    <div className="h-full">
      <div className="flex p-1 pb-2">
        <span className="text-gray-500 pr-2 text-sm p-1">Included providers: </span>
        {providers.map((el) => {
          const found = providersChecked.findIndex((provider) => el === provider)
          return (
            <Chip
              className="p-1"
              color={found !== -1 ? getProviderColor(el) : '#868e96'}
              icon={found !== -1 ? <FaCheck /> : <FaX />}
              checked
              onClick={() => {
                if (found !== -1) {
                  const newProvidersChecked =
                    providersChecked.filter((provider) => provider !== el)
                  setProvidersChecked(newProvidersChecked)
                } else {
                  const newProvidersChecked = providersChecked.concat(el)
                  setProvidersChecked(newProvidersChecked)
                }
              }}
            >
              {el}
            </Chip>
          )
        })}
      </div>
      {clickedRegion === '' && (
        <div className="flex h-5/6 w-full">
          <RenderLegend
            data={parsePieData(pieChartData)}
            parentKey="fiojeffhuiiosehfghuiosgfhifhueio"
            currency={currency}
            delimiter={savedDelimiter}
            grouping={savedGrouping}
          />
          <div className="h-5/6 w-5/6 ml-[130px]" ref={ref}>
            <PieChart
              size={getSmallestVal(rect)}
              withLabelsLine
              labelsPosition="outside"
              labelsType="value"
              withLabels
              opacity={60}
              strokeWidth={1}
              data={parsePieData(pieChartData)}
              pieProps={{
                label: (val) => drawNumberIf(
                  val,
                  totalCost,
                  currency,
                  savedDelimiter,
                  savedGrouping
                ),
                labelLine: (val) => drawLineIf(val, totalCost),
                onClick: (event) => {
                  setClickedRegion(event.name)
                  // console.log(event)
                  setClickedTotalCost(Number(event.value.toFixed(2)))
                },
                fillOpacity: '80%',
                outerRadius: '60%'
              }}
            />
          </div>
        </div>
      )}

      {clickedRegion !== '' && (
        <div className="w-full">
          <div className="flex">
            <Chip
              checked
              color="#668957"
              className="p-2"
              onClick={() => {
                setClickedRegion('')
                setClickedTotalCost(0)
              }}
              icon={<IoMdArrowRoundBack />}
            >
              Go back
            </Chip>

            <span className="p-3 text-sm text-gray-500 font-semibold px-0 text-wrap">Summary: {clickedRegion}; {currency.symbol}{new Intl.NumberFormat(savedDelimiter, { useGrouping: savedGrouping === 'Yes' }).format(clickedTotalCost)}</span>
          </div>
          <BarChart
            h={height}
            data={lineChartData}
            className="h-full"
            dataKey="label"
            valueFormatter={(value) => {
              return new Intl.NumberFormat(savedDelimiter, { useGrouping: savedGrouping === 'Yes' }).format(Number(value.toFixed(2)))
            }}
            tooltipProps={{
              // eslint-disable-next-line react/no-unstable-nested-components
              content: ({ label, payload }) => {
                return <ChartTooltip label={label} payload={payload} currency={currency} />
              }
            }}
            unit={currency.symbol}
            series={linechartDataColors}
            type="stacked"
          />
        </div>
      )}
    </div>
  )
}
