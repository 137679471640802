export const tourConfig = (callbackList: (() => void)[]) => {
  return [
    {
      selector: '[data-tour="LabelRuleSelectMenu"]',
      content: 'Welcome to the label rule creator page! Here you can create the labels you want to use in the widgets and other parts of the application.'
    },
    {
      selector: '[data-tour="fullApp"]',
      content: 'If you click "create new label rule" you will be brought to this view. The same will be shown when you want to update a label rule, but some fields will then already be configured.',
      action: () => {
        callbackList[0]()
      }
    },
    {
      selector: '[data-tour="TheEnabledChip"]',
      content: 'This chip will show if the label rule is enabled or not. You can click this to enable or disable it.',
    },
    {
      selector: '[data-tour="theLabelRuleHalf"]',
      content: 'Here you can add new label rules by clicking on the +. You can choose a focus group, operator and value for this label rule.',
    },
    {
      selector: '[data-tour="theLabelEffectHalf"]',
      content: 'Here you can add new label effects by clicking on the +. You can choose the name and value of this label effects.',
    },
    {
      selector: '[data-tour="fullApp"]',
      content: 'Enjoy creating label rules that you can use throughout the application. Check out the resource explorer for another way of adding label rules.',
      action: () => {
        callbackList[1]()
        callbackList[2]()
      }
    },
  ]
}
