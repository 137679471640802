import { useEffect, useState } from 'react'
import { TPieChartData } from 'services/chart.interface'
import { axios } from 'utils'
import { v4 as uuid } from 'uuid'
import { useQuery } from 'react-query'
import { newTFocusGroup, TCostHistoryQueryResponse, TFocusGroup } from 'services/costhistory.interface'
import useAuthentication from 'hooks/useAuthentication'
// import LabeledDataComponent from 'components/LabeledDataRepresenation'
import { LineChart, PieChart } from '@mantine/charts'
import { useResizeObserver } from '@mantine/hooks'
import { SpinnerLoader } from 'components'
import { forecasting } from 'components/top-bar'
import { dateFormatChanger, getSmallestVal, parseAccumulatedTrueData, parsePieData, RenderLegend } from './chartHelperFunctions'
import { createWidgetAggregatedAPIFieldCall, createWidgetAPIFieldCall, dataNameAndColor, projectAndTheirApps, query } from '../project-widget/widgetHelpFunctions'
import { WidgetData } from '../project-widget/widgetStatev2'
import { getWidgetData, setWidgetData } from '../project-widget/widgetDataCaching'
import { drawLineIf, drawNumberIf } from '../project-finops-widget'
import { ChartTooltip } from '../project-finops-widget/useCases/ServiceCostsRegion'

export const hardCodedPredictionData = {
  aws: {
    forecastWeek: [40, 30, 50, 20, 60, 40, 50],
    forecastMonth: 1285,
    forecastTax: 275.76,
    error: 'bijvoorbeeld: training mislukt',
    warning: 'bijvoorbeeld: voorspelling gelukt, maar het was op basis van heel weinig data'
  },
  azure: {
    forecastWeek: [30, 40, 50, 30, 50, 30, 60],
    forecastMonth: 987,
    forecastTax: 160,
    error: 'bijvoorbeeld: training mislukt',
    warning: 'bijvoorbeeld: voorspelling gelukt, maar het was op basis van heel weinig data'
  },
  gcp: {
    forecastWeek: [42.34, 18.90, 37.62, 26.45, 15.78, 31.21, 33.56],
    forecastMonth: 1422,
    forecastTax: 357,
    error: 'bijvoorbeeld: training mislukt',
    warning: 'bijvoorbeeld: voorspelling gelukt, maar het was op basis van heel weinig data'
  },
}

interface PredefinedGroupByProps {
  id: string,
  tag: string,
  budgetting?: boolean,
  widgetObject: WidgetData,
  deleteWidget: () => void,
}
export function PredefinedGroupBy({
  tag,
  id,
  widgetObject,
  deleteWidget,
  budgetting
}: PredefinedGroupByProps) {
  widgetObject.resetOptions(id)
  const { currency } = useAuthentication()
  const [dataProcessed, setDataProcessed] = useState<boolean>(getWidgetData(`PredefinedWidget${id}${tag}`).length !== 0)
  const [focusData, setFocusData] = useState<TFocusGroup[] | newTFocusGroup[]>(
    getWidgetData(`PredefinedWidget${id}${tag}`)
  )
  const daysToSubstract = budgetting ? (new Date().getDate()) - 1 : 31
  const monthWindow = `${new Date(new Date().getTime() - daysToSubstract * 24 * 60 * 60 * 1000).toISOString()},${new Date().toISOString()}`
  const [chartDataValues, setChartDataValues] = useState<projectAndTheirApps[]>([])
  const [chartDataNames, setChartDataNames] = useState<dataNameAndColor[]>([])
  const [accumulatedChartData, setAccumulatedChartData] = useState<TPieChartData[]>([])
  const [queryKey, setQueryKey] = useState<string>(uuid())
  const [lastTimeStamp, setLastTimeStamp] = useState<Date>(new Date())
  const [query] = useState<query>(
    tag === 'accumulateTrue' ?
      createWidgetAggregatedAPIFieldCall('serviceName', '', 'PredefinedWidgetAccumulateTrue', monthWindow)
      : createWidgetAPIFieldCall('provider', monthWindow, '1d', '', 'PredefinedWidgetAccumulateFalse')
  )
  const [totalCost, setTotalCost] = useState<number>(0)

  widgetObject.editOptions(id, 'Delete widget', () => {
    sessionStorage.removeItem(`PredefinedWidget${id}${tag}`)
    deleteWidget()
  })
  // const [ref2, rect2] = useResizeObserver();
  const [ref, rect] = useResizeObserver();

  const [savedDelimiter, setSavedDelimiter] = useState<string>('en-US');
  const [savedGrouping, setSavedGrouping] = useState<string>('Yes');
  const [savedBudget, setSavedBudget] = useState<number>(0);
  const [savedForecasting, setSavedForecasting] = useState<forecasting[]>();

  window.addEventListener('storage', () => {
    if (sessionStorage.getItem('delimiter') === ',') {
      setSavedDelimiter('de-DE')
    } else {
      setSavedDelimiter('en-US')
    }
    if (sessionStorage.getItem('grouping') !== null) setSavedGrouping(sessionStorage.getItem('grouping')!)
    if (sessionStorage.getItem('budget') !== null) setSavedBudget(Number(sessionStorage.getItem('budget')!))
  })

  useEffect(() => {
    if (sessionStorage.getItem('delimiter') === ',') {
      setSavedDelimiter('de-DE')
    } else {
      setSavedDelimiter('en-US')
    }
    if (sessionStorage.getItem('grouping') !== null) setSavedGrouping(sessionStorage.getItem('grouping')!)
    if (sessionStorage.getItem('budget') !== null) setSavedBudget(Number(sessionStorage.getItem('budget')!))
  }, [savedDelimiter, savedGrouping])

  window.addEventListener('forecasting', () => {
    if (!savedForecasting) {
      if (sessionStorage.getItem('forecasting') !== null && sessionStorage.getItem('forecasting')) {
        const result: forecasting[] = JSON.parse(sessionStorage.getItem('forecasting')!)
        setSavedForecasting(result)
      }
    }
  })

  useEffect(() => {
    if (!savedForecasting) {
      if (sessionStorage.getItem('forecasting') !== null && sessionStorage.getItem('forecasting')) {
        const result: forecasting[] = JSON.parse(sessionStorage.getItem('forecasting')!)
        setSavedForecasting(result)
      }
    }
  }, [savedForecasting])

  const { error } = useQuery({
    queryKey: [`query?${queryKey}`],
    queryFn: async () => {
      try {
        if (!dataProcessed) {
          setDataProcessed(true)
          await axios
            .post<TCostHistoryQueryResponse>('/query', query)
            .then((res) => {
              setFocusData(res.data.data)
              setWidgetData(`PredefinedWidget${id}${tag}`, `PredefinedWidget${id}${tag}`, res.data.data)
            }).catch((error) => {
              if (error.response.data.data) {
                setDataProcessed(true)
              }
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })
  useEffect(() => {
    setQueryKey(uuid())
  }, [query])

  // useEffect(() => {
  //   console.log(lastTimeStamp.toISOString)
  // }, [lastTimeStamp])

  useEffect(() => {
    if (tag === 'accumulateFalse') {
      let accumulatedCost = 0
      const dataToWorkWith = focusData as TFocusGroup[]
      let newData: projectAndTheirApps[] = []
      dataToWorkWith.forEach((el) => {
        const label = Object.getOwnPropertyNames(el)
        const { timestamp, totalBilledCost } = el[label[0]]
        setLastTimeStamp(timestamp)
        const newTimestamp = dateFormatChanger(timestamp.toString())
        const index = newData.findIndex((el) => el.label === newTimestamp)
        if (index !== -1) {
          newData[index].Spend += Number(totalBilledCost)
        } else {
          newData.push({
            label: newTimestamp,
            Spend: (Number(totalBilledCost) + accumulatedCost)
          })
          if (budgetting) accumulatedCost += Number(totalBilledCost)
        }
      })
      if (newData.length > 2) newData = newData.slice(0, newData.length - 1)
      // setLastTimeStamp(newData[newData.length - 1].timestamp)
      for (let i = 0; i < 7; i++) {
        const thisDate = new Date(
          new Date(lastTimeStamp).getTime() + i * 24 * 60 * 60 * 1000
        ).toISOString()
        if (savedForecasting) {
          console.log(savedForecasting)
          let total = 0
          savedForecasting.forEach((cloud) => {
            total += cloud.forecastWeek[i]
          })
          total += accumulatedCost
          if (budgetting) accumulatedCost = total
          const newEl = {
            label: dateFormatChanger(thisDate),
            Spend: total
          }
          newData.push(newEl)
        }
      }
      setChartDataValues(newData)
      setChartDataNames([{ name: 'Spend', color: 'indigo.6' }, { name: 'Predicted', color: 'indigo.6' }])
      // const res = parseAccumulatedFalseData(focusData as TFocusGroup[])
      // setChartDataValues(res.values)
      // setChartDataNames(res.names)
    } else {
      const res = parseAccumulatedTrueData(focusData as newTFocusGroup[])
      let newTotalCost = 0
      res.forEach((el) => {
        newTotalCost += el.value
      })
      setAccumulatedChartData(res.sort((a, b) => b.value - a.value))
      setTotalCost(newTotalCost)
    }
  }, [focusData, savedForecasting])

  if (error) return <div>An error has occurred</div>
  return (
    <div className="h-full w-full" ref={ref}>
      <SpinnerLoader isLoading={!dataProcessed}>
        {tag === 'accumulateTrue' && accumulatedChartData.length !== 0 && (
          <div className="h-full w-full flex">
            <div className="w-1/3">
              {savedDelimiter && savedGrouping && (
                <RenderLegend
                  data={parsePieData(accumulatedChartData)}
                  parentKey={id}
                  currency={currency}
                  delimiter={savedDelimiter}
                  grouping={savedGrouping}
                />
              )}
            </div>
            <div className="h-5/6 w-5/6 ml-[122px]" ref={ref}>
              <PieChart
                size={getSmallestVal(rect)}
                withLabelsLine
                labelsPosition="outside"
                labelsType="value"
                withLabels
                opacity={60}
                strokeWidth={1}
                data={parsePieData(accumulatedChartData)}
                pieProps={{
                  label: (val) => drawNumberIf(
                    val,
                    totalCost,
                    currency,
                    savedDelimiter,
                    savedGrouping
                  ),
                  labelLine: (val) => drawLineIf(val, totalCost),
                  fillOpacity: '80%',
                  outerRadius: '60%'
                }}
              />
            </div>
          </div>
        )}
        {tag === 'accumulateFalse' && chartDataValues.length !== 0 && lastTimeStamp && (
          <div className="h-full" style={{ display: 'block' }}>
            <div className="h-full">
              <LineChart
                className="h-full"
                // h={300}
                data={chartDataValues}
                dataKey="label"
                series={chartDataNames}
                curveType="natural"
                valueFormatter={(value) => {
                  const val =
                    new Intl.NumberFormat(savedDelimiter, { useGrouping: savedGrouping === 'Yes' }).format(Number(value.toFixed(2)))
                  return val
                }}
                unit={currency.symbol}
                connectNulls
                referenceLines={budgetting ? [
                  { x: dateFormatChanger(lastTimeStamp.toString()), label: 'Forecast', color: 'yellow.6' },
                  { y: savedBudget, label: 'Budget', color: 'red.6' },
                ] : [
                  { x: dateFormatChanger(lastTimeStamp.toString()), label: 'Forecast', color: 'yellow.6' },
                ]}
                tooltipProps={{
                  // eslint-disable-next-line react/no-unstable-nested-components
                  content: ({ label, payload }) => {
                    return <ChartTooltip label={label} payload={payload} currency={currency} />
                  }
                }}
              />
            </div>
          </div>

        )}
      </SpinnerLoader>
    </div>
  )
}
