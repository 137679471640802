import { TCurrency } from 'services/cost.interface'
import { CiCirclePlus } from 'react-icons/ci';
import { PreviousMonthSpend } from './PreviousMonthSpend'
import { TopServiceBySpend } from './TopServiceBySpend'
import { RegionPercentage } from './RegionPercentage'
import { PredictionSpend } from './PredictionSpend';

export function returnElementByTag(
  tag: string,
  key: string,
  currency: TCurrency,
) {
  if (tag === 'PreviousMonthSpend') {
    return (
      <PreviousMonthSpend
        id={key}
        title="Previous month spendings"
        key={key}
        timeWindow="lastmonth"
        currency={currency}
      />
    )
  }
  if (tag === 'ThisMonthSpend') {
    // const month = new Date().getMonth() + 1
    // const year = new Date().getFullYear()
    // let window = ''
    // let paddingNow = ''
    // let paddingNext = ''
    // const amountOfDaysInCur = new Date(year, month, 0).getDate()
    // const amountOfDaysInNext = new Date(year, month - 1, 0).getDate()
    // if (month < 10 && month !== 0) paddingNow = '0'
    // if (month + 1 < 10 && month + 1 !== 0) paddingNext = '0'
    // if (month === 12) window = `${year}-${month}-${amountOfDaysInCur
    // }T22:00:00Z,${year + 1}-01-${amountOfDaysInNext}T21:59:59.999Z`
    // else window = `${year}-${paddingNext}${month}-${amountOfDaysInCur}T22:00:00Z,${year
    // }-${paddingNow}${month + 1}-${amountOfDaysInNext}T21:59:59.999Z`
    return (
      <PreviousMonthSpend
        id={key}
        title="Current month spendings"
        key={key}
        timeWindow="thismonth"
        currency={currency}
      />
    )
  }
  if (tag === 'PredictedMonthSpend') {
    return (
      <PredictionSpend
        id={key}
        key={key}
        currency={currency}
      />
    )
  }
  if (tag === 'TopServiceBySpend') {
    return (
      <TopServiceBySpend id={key} key={key} />
    )
  }
  if (tag === 'RegionPercentage') {
    return (
      <RegionPercentage id={key} key={key} />
    )
  }
  return <div />
}
export function returnPlusButton(
  view: boolean,
  viewChanger: () => void,
  plusCallback: (label: string) => void
) {
  return (
    <div className="w-7/8 h-full p-2 border-2 border-[#668957] rounded-lg bg-white shadow-2xl m-2" onClick={viewChanger}>
      {view && (
        <CiCirclePlus size={60} style={{ color: '#668957' }} />
      )}
      {!view && (
        <div className="flex flex-col">
          <button
            type="button"
            onClick={() => {
              viewChanger()
              plusCallback('PreviousMonthSpend')
            }}
          >
            Add previous month spend
          </button>
          <button
            type="button"
            onClick={() => {
              viewChanger()
              plusCallback('TopServiceBySpend')
            }}
          >
            Add top service by spend
          </button>
          <button
            type="button"
            onClick={() => {
              viewChanger()
              plusCallback('RegionPercentage')
            }}
          >
            Add region percentage
          </button>
          <button
            type="button"
            onClick={() => {
              viewChanger()
            }}
          >
            cancel
          </button>
        </div>
      )}
    </div>
  )
}
