export const blueGray = [
  '#94a8d0',
  '#5f7cb7',
  '#44639f',
  '#2c4b80'
]
export const brown = [
  '#bdaaa4',
  '#a17f74',
  '#896459',
  '#6d4b40'
]
export const tomato = [
  '#f69e6e',
  '#f06e27',
  '#d6530c',
  '#a73c00'
]
export const deepOrange = [
  '#fdb766',
  '#fc931d',
  '#e17800',
  '#af5a00'
]
export const brightOrange = [
  '#ffca64',
  '#ffb01b',
  '#e39500',
  '#b07100'
]
export const yellow = [
  '#e7e17c',
  '#dbd33e',
  '#c0b820',
  '#928d03'
]
export const brightGreen = [
  '#a2e67e',
  '#75d93f',
  '#59be23',
  '#3d920d'
]
export const green = [
  '#7aea9f',
  '#3bdf70',
  '#1bc455',
  '#00973c'
]
export const lightBlue = [
  '#64d2ff',
  '#23bcfe',
  '#00a1e4',
  '#007cb6'
]
// ================
export const skyBlue = [
  '#64c1ff',
  '#20a2fe',
  '#0088e4',
  '#0068b6'
]

export const paleBlue = [
  '#94a8d0',
  '#5f7cb7',
  '#44639f',
  '#2c4b80'
]
export const brightBlue = [
  '#64a0ff',
  '#1d72fe',
  '#0058e4',
  '#0043b5'
]
export const deepBlue = [
  '#7a87e9',
  '#3a4bdd',
  '#1f32c4',
  '#0a259c'
]
export const paleIndigo = [
  '#99a0ca',
  '#6672af',
  '#4c5897',
  '#36437a'
]
export const purple = [
  '#9a7de6',
  '#693cd9',
  '#4f23c0',
  '#3b1898'
]
export const palePurple = [
  '#9b98ca',
  '#6a66af',
  '#504c97',
  '#3b3979'
]
export const violet = [
  '#ac7ce8',
  '#833bdb',
  '#6921c2',
  '#501599'
]
export const paleViolet = [
  '#ad86dd',
  '#854bcb',
  '#6b31b2',
  // '#52238d'
]
export const darkPink = [
  '#c186dd',
  '#a34bcb',
  '#8931b2',
  '#6b218d'
]
export const brightPink = [
  '#d779eb',
  '#c337e0',
  '#a91cc6',
  '#84099c'
]
export const pink = [
  '#f66ef1',
  '#f028e9',
  '#d609ce',
  '#a700a1'
]
export const megenta = [
  '#f66eab',
  '#f02981',
  '#d60867',
  '#a9004f'
]
export const brightRed = [
  '#f66e99',
  '#f02866',
  '#d6084c',
  '#a90039'
]
export const PaleRed = [
  '#ec779c',
  '#e3366c',
  '#c91a52',
  '#9f003e'
]
export const deepRed = [
  '#ec777e',
  '#e3353f',
  '#c91a25',
  '#9e0419'
]
export const red = [
  '#f76d6d',
  '#f22625',
  '#d8070b',
  '#a90003'
]
// eslint-disable-next-line max-len
export const colors = [
  // Other colors
  blueGray,
  green,
  skyBlue,
  violet,
  purple,
  darkPink,
  pink,
  megenta,
  red,

  // Bright colors
  brightOrange,
  brightGreen,
  brightBlue,
  brightPink,
  brightRed,

  // Deep colors
  deepOrange,
  deepBlue,
  deepRed,

  // Light colors
  lightBlue,
]
