/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { getLastMonth } from 'features/projects/project-datepicker/helpFunction'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { toast, Bounce } from 'react-toastify'
import { graphQLAxios } from 'utils'
import { useResizeObserver } from '@mantine/hooks'
import { PieChart } from '@mantine/charts'
import { getSmallestVal, parsePieData, RenderLegend } from 'features/projects/project-labeled-data-widget/chartHelperFunctions'
// import { ResponsiveContainer, PieChart, Pie, Cell } from 'recharts'
import { TPieChartData } from 'services/chart.interface'
import useAuthentication from 'hooks/useAuthentication'
import { CostPerComputeServiceForASubaccountApiCall } from '../graphQlApiCalls'
import { drawLineIf, drawNumberIf } from '..'

type CostPerComputeServiceForASubaccountType = {
  chargePeriodEnd: string
  chargePeriodStart: string
  monthlyEffectiveCost: number
  pricingQuantity: number
  resourceId: string
  resourceName: string
  serviceName: string
}

interface CostPerComputeServiceForASubaccountprops {
  id: string,
}
export function CostPerComputeServiceForASubaccount({
  id,
}: CostPerComputeServiceForASubaccountprops) {
  const { currency } = useAuthentication()
  const timeWindow = getLastMonth()
  const [dataLoaded, setDataLoaded] = useState<boolean>(false)
  const [queryKey] = useState<string>(`initial-${id}`)
  const [data, setData] = useState<CostPerComputeServiceForASubaccountType[]>([])
  const [pieChartData, setPieChartData] = useState<TPieChartData[]>([])
  const [totalCost, setTotalCost] = useState<number>(0)

  const [savedDelimiter, setSavedDelimiter] = useState<string>('en-US');
  const [savedGrouping, setSavedGrouping] = useState<string>('Yes');

  window.addEventListener('storage', () => {
    if (sessionStorage.getItem('delimiter') === ',') {
      setSavedDelimiter('de-DE')
    } else {
      setSavedDelimiter('en-US')
    }
    if (sessionStorage.getItem('grouping') !== null) setSavedGrouping(sessionStorage.getItem('grouping')!)
  })

  useEffect(() => {
    if (sessionStorage.getItem('delimiter') === ',') {
      setSavedDelimiter('de-DE')
    } else {
      setSavedDelimiter('en-US')
    }
    if (sessionStorage.getItem('grouping') !== null) setSavedGrouping(sessionStorage.getItem('grouping')!)
  }, [savedDelimiter, savedGrouping, sessionStorage.getItem('delimiter'), sessionStorage.getItem('grouping')])

  const { error } = useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      try {
        if (!dataLoaded) {
          const query =
            CostPerComputeServiceForASubaccountApiCall(timeWindow[0].toISOString().replace('T', ' ').replace('Z', ''), timeWindow[1].toISOString().replace('T', ' ').replace('Z', ''))
          await graphQLAxios
            .post('', { query })
            .then((res) => {
              setData(res.data.data.costPerComputeServiceForASubaccount)
              setDataLoaded(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                toast.error('No labels have been created.', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  transition: Bounce,
                })
              }
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })

  const [ref, rect] = useResizeObserver();

  useEffect(() => {
    const newChartData: TPieChartData[] = []
    let newTotalCost = 0
    data.sort((a, b) => {
      return b.monthlyEffectiveCost - a.monthlyEffectiveCost
    }).forEach((el) => {
      newTotalCost += el.monthlyEffectiveCost
      newChartData.push({
        name: el.resourceId,
        value: Number(el.monthlyEffectiveCost.toFixed(2)),
        color: ''
      })
    })
    setPieChartData(newChartData)
    setTotalCost(newTotalCost)
  }, [data])

  if (error) return <div>An error has occurred</div>
  return (
    <div className="h-full w-full">
      <div className="flex h-5/6 w-full">
        <RenderLegend
          data={parsePieData(pieChartData)}
          parentKey="fiojeffhuiiosehfghuiosgfhifhueio"
          currency={currency}
          delimiter={savedDelimiter}
          grouping={savedGrouping}
        />
        <div className="w-2/3">
          <div className="h-5/6 w-5/6 ml-[130px]" ref={ref}>
            <PieChart
              size={getSmallestVal(rect)}
              withLabelsLine
              labelsPosition="outside"
              labelsType="value"
              withLabels
              opacity={60}
              strokeWidth={1}
              data={parsePieData(pieChartData)}
              pieProps={{
                label: (val) => drawNumberIf(
                  val,
                  totalCost,
                  currency,
                  savedDelimiter,
                  savedGrouping
                ),
                labelLine: (val) => drawLineIf(val, totalCost),
                fillOpacity: '80%',
                outerRadius: '60%'
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
