import { jsonData } from './constants'
import { toQueryString } from './initialStates'

export const graphqlCall = `
  query getIndexedFocusData{
  getIndexedFocusData(from:300, to:400) {
    availabilityZone
    billingAccountId
    billingAccountName
    billingCurrency
    billingPeriodEnd
    billingPeriodStart
    chargeCategory
    chargeDescription
    chargeFrequency
    chargePeriodEnd
    chargePeriodStart
    chargeSubcategory
    commitmentDiscountCategory
    commitmentDiscountId
    commitmentDiscountName
    commitmentDiscountType
    invoiceIssuer
    pricingCategory
    pricingUnit
    provider
    publisher
    region
    resourceId
    resourceName
    resourceType
    serviceCategory
    serviceName
    skuId
    skuPriceId
    subAccountId
    subAccountName
    usageUnit
  }
}`
export const graphqlCallIterator = (int: number) => `
  query getIndexedFocusData{
  getIndexedFocusData(from:${int * 200}, to:${(int + 1) * 200}) {
  effectiveCost
  listCost
  billedCost
  availabilityZone
  billingAccountId
  billingAccountName
  billingCurrency
  billingPeriodEnd
  billingPeriodStart
  chargeCategory
  chargeDescription
  chargeFrequency
  chargePeriodEnd
  chargePeriodStart
  chargeSubcategory
  commitmentDiscountCategory
  commitmentDiscountId
  commitmentDiscountName
  commitmentDiscountType
  invoiceIssuer
  pricingCategory
  pricingUnit
  provider
  publisher
  region
  resourceId
  resourceName
  resourceType
  serviceCategory
  serviceName
  skuId
  skuPriceId
  subAccountId
  subAccountName
  usageUnit
  pricingQuantity
  usageQuantity
  listUnitPrice
  }
}`

export const graphqlCallInterval = (filters: string[], globalFilter: string[], from: number, to: number, columns:jsonData) => `
  query getFilteredIndexedFocusData{
  getFilteredIndexedFocusData(filters: [${filters}], globalFilter:[${globalFilter}], from:${from}, to:${to}) {
    ${toQueryString(columns)}
  }
}`

export const graphqlInitialCall = `
  query allFocusGroupings{
  allFocusGroupings {
    provider
    serviceCategory
    serviceName
  }
}`

// availabilityZone
// billingAccountId
// billingAccountName
// billingCurrency
// billingPeriodEnd
// billingPeriodStart
// chargeCategory
// chargeDescription
// chargeFrequency
// chargePeriodEnd
// chargePeriodStart
// chargeSubcategory
// commitmentDiscountCategory
// commitmentDiscountId
// commitmentDiscountName
// commitmentDiscountType
// invoiceIssuer
// pricingCategory
// pricingUnit
// provider
// publisher
// region
// resourceId
// resourceName
// resourceType
// serviceCategory
// serviceName
// skuId
// skuPriceId
// subAccountId
// subAccountName
// usageUnit
export const focusDataCountCall =
  `query getFocusDataCount {
  getFocusDataCount
}`
