import { Modal as CmpModal, ModalBody, ModalHeader } from 'components/modal';
import { useState } from 'react';
import useAuthentication from 'hooks/useAuthentication';
import { IoSettingsSharp } from 'react-icons/io5';
import { Avatar, Button, Modal, NumberInput } from '@mantine/core';
import { FotoEditor } from './FotoEditor';

interface settingsProps {
  settingsBool: boolean
  onClose: () => void
  initialDateFormat: string
  setDatefomatGlobally: (str: string) => void
  initialDelimiter: string
  setDelimiterGlobally: (str: string) => void
  initialGrouping: string
  setGroupingGlobally: (str: string) => void
  initialLanguage: string
  setLanguageGlobally: (str: string) => void
  setSaveClicked: (bool: boolean) => void
  initialProfileImage: string
  setProfileImageGlobally: (img: string) => void
  initialBudget: number
  setBudgetGlobally: (budget: number) => void
  initialPersona: string
  setPersonaGlobally: (persona: string) => void
}

export function Settings({
  settingsBool,
  onClose,
  initialDateFormat,
  setDatefomatGlobally,
  initialDelimiter,
  setDelimiterGlobally,
  initialGrouping,
  setGroupingGlobally,
  initialLanguage,
  setLanguageGlobally,
  setSaveClicked,
  initialProfileImage,
  setProfileImageGlobally,
  initialBudget,
  setBudgetGlobally,
  initialPersona,
  setPersonaGlobally
}: settingsProps) {
  const { currency } = useAuthentication()
  const [dateFormat, setDateFromat] = useState<string>(initialDateFormat)
  const [delimiter, setDelimiter] = useState<string>(initialDelimiter)
  const [grouping, setGrouping] = useState<string>(initialGrouping)
  const [language, setLanguage] = useState<string>(initialLanguage)
  const [budget, setBudget] = useState<string | number>(initialBudget)
  const [persona, setPersona] = useState<string>(initialPersona)
  const dateFromatOptions = ['DD-MM-YYYY', 'YYYY-MM-DD']
  const delimiterOptions = [',', '.']
  const groupingOptions = ['Yes', 'No']
  const LanguageOptions = ['English']
  const PersonaOptions = ['', 'FinOps Practitioner', 'Product', 'Engineering', 'Finance', 'Procurement']
  const [profileImage, setProfileImage] = useState<string | undefined>(initialProfileImage)
  const [cropping, setCropping] = useState<boolean>(false)

  const [opened, setOpened] = useState(false);

  return (
    <CmpModal isOpen={settingsBool} onClose={onClose} disableBackdropClick>
      <ModalHeader onClose={onClose} className="border-b border-gray-700">
        <IoSettingsSharp size={50} className="mt-1 ml-1 text-sb-button-color" />
        Settings
      </ModalHeader>
      <ModalBody className="px-5 py-1 w-[50vw] mt-3">
        <div className="border border-gray-700 rounded-lg mt-2 mb-3">
          <Modal opened={opened} onClose={() => { setOpened(false) }}>
            <span>
              You changed your persona, this will load in the widget preset for this new persona.
              You will lose the configuration you have made, are you certain?
            </span>
            <Button
              onClick={() => {
                setOpened(false)
                setSaveClicked(true)
                setDatefomatGlobally(dateFormat)
                setDelimiterGlobally(delimiter)
                setGroupingGlobally(grouping)
                setLanguageGlobally(language)
                setBudgetGlobally(Number(budget))
                setPersonaGlobally(persona)
                sessionStorage.setItem('dateFormat', dateFormat);
                sessionStorage.setItem('delimiter', delimiter);
                sessionStorage.setItem('grouping', grouping);
                sessionStorage.setItem('language', language);
                sessionStorage.setItem('budget', budget.toString());
                sessionStorage.setItem('persona', persona);
                sessionStorage.setItem('personaChanged', 'true');
                window.dispatchEvent(new Event('storage'));
                if (profileImage) {
                  setProfileImageGlobally(profileImage)
                }
                onClose()
              }}
            >
              I agree.
            </Button>
          </Modal>
          <div className="bg-gray-200 border-b border-gray-700 rounded-t-lg p-1">
            <span>Editable information:</span>
          </div>
          <div className="w-full flex">
            <div className="w-1/4 bg-gray-100 border-b border-gray-700 p-1">Date format</div>
            <div className="w-3/4 p-1 border-b  border-l border-gray-700">
              <select
                value={dateFormat}
                onChange={(event) => {
                  setDateFromat(event.target.value)
                }}
                className="bg-white w-full h-full"
              >
                {dateFromatOptions.map((el) => {
                  return <option value={el}>{el}</option>
                })}
              </select>
            </div>
          </div>
          <div className="w-full flex">
            <div className="w-1/4 bg-gray-100 border-b border-gray-700 p-1">Delimiter</div>
            <div className="w-3/4 p-1 border-b  border-l border-gray-700">
              <select
                value={delimiter}
                onChange={(event) => {
                  setDelimiter(event.target.value)
                }}
                className="bg-white w-full h-full"
              >
                {delimiterOptions.map((el) => {
                  return <option value={el}>{`Using "${el}" as a delimiter`}</option>
                })}
              </select>
            </div>
          </div>
          <div className="w-full flex">
            <div className="w-1/4 bg-gray-100 border-b border-gray-700 p-1">Thousand separator</div>
            <div className="w-3/4 p-1 border-b  border-l border-gray-700">
              <select
                value={grouping}
                onChange={(event) => {
                  setGrouping(event.target.value)
                }}
                className="bg-white w-full h-full"
              >
                {groupingOptions.map((el) => {
                  return <option value={el}>{el}</option>
                })}
              </select>
            </div>
          </div>
          <div className="w-full flex">
            <div className="w-1/4 bg-gray-100 border-b border-gray-700 p-1">Language</div>
            <div className="w-3/4 border-b border-l border-gray-700 p-1">
              <select
                value={language}
                onChange={(event) => {
                  setLanguage(event.target.value)
                }}
                className="bg-white w-full h-full"
              >
                {LanguageOptions.map((el) => {
                  return <option value={el}>{el}</option>
                })}
              </select>
            </div>
          </div>

          <div className="w-full flex">
            <div className="w-1/4 bg-gray-100 border-b border-gray-700 p-1">Persona</div>
            <div className="w-3/4 border-b border-l border-gray-700 p-1">
              <select
                value={persona}
                onChange={(event) => {
                  setPersona(event.target.value)
                }}
                className="bg-white w-full h-full"
              >
                {PersonaOptions.map((el) => {
                  return <option value={el}>{el}</option>
                })}
              </select>
            </div>
          </div>
          <div className="w-full flex">
            <div className="w-1/4 bg-gray-100 border-b border-gray-700 p-1">Profilepic</div>
            <div className="w-3/4 border-l border-b border-gray-700 p-1">
              {profileImage && cropping && (
                <FotoEditor
                  foto={profileImage}
                  fotoBackup={initialProfileImage}
                  setProfileImage={setProfileImage}
                  setCropping={setCropping}
                />
              )}
              <Avatar src={profileImage} alt="it's me" />
              <input
                type="file"
                placeholder="Input placeholder"
                onChange={(event) => {
                  if (event.target.files !== null) {
                    setCropping(true)
                    setProfileImage(URL.createObjectURL(event.target.files[0]))
                  }
                }}
              />
            </div>
          </div>
          <div className="w-full flex">
            <div className="w-1/4 bg-gray-100 p-1 rounded-b-lg">Budget</div>
            <div className="w-3/4 border-l border-gray-700 p-1">
              <NumberInput
                label="With prefix"
                placeholder={currency.value}
                prefix={currency.symbol}
                onChange={setBudget}
                value={budget}
                mb="md"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <button
            type="button"
            className="bg-[#BB0A1E] hover:bg-[#c83a4a] text-white border-2 rounded-lg border-[#BB0A1E] w-16 p-1 m-1"
            onClick={() => {
              setDateFromat(initialDateFormat)
              setDelimiter(initialDelimiter)
              setLanguage(initialLanguage)
              setProfileImage(initialProfileImage)
              onClose()
            }}
          >
            Cancel
          </button>
          <button
            className="bg-[#668957] border-[#668957] hover:bg-[#93ac89] hover:border-[#93ac89] text-white border-2 rounded-lg w-16 p-1 m-1"
            type="button"
            onClick={() => {
              if (persona === initialPersona) {
                setSaveClicked(true)
                setDatefomatGlobally(dateFormat)
                setDelimiterGlobally(delimiter)
                setGroupingGlobally(grouping)
                setLanguageGlobally(language)
                setBudgetGlobally(Number(budget))
                sessionStorage.setItem('dateFormat', dateFormat);
                sessionStorage.setItem('delimiter', delimiter);
                sessionStorage.setItem('grouping', grouping);
                sessionStorage.setItem('language', language);
                sessionStorage.setItem('budget', budget.toString());
                sessionStorage.setItem('persona', persona);
                window.dispatchEvent(new Event('storage'));
                if (profileImage) {
                  setProfileImageGlobally(profileImage)
                }
                onClose()
              } else {
                setOpened(true)
              }
            }}
          >
            Save
          </button>
        </div>
      </ModalBody>
    </CmpModal>
  )
}
