export const tourConfig = (callbackList: (() => void)[]) => {
  return [
    {
      selector: '[data-tour="ReportSelectMenu"]',
      content: 'Welcome to the report page! Here you can create and edit reports. This in order to give you the power to understand specific areas of your data that much better.'
    },
    {
      selector: '[data-tour="fullApp"]',
      content: 'This is the view you would get if you would click on "create report". You will see the same view if you click to edit a report, this however then with the repoert configured.',
      action: () => {
        callbackList[0]()
      }
    },
    {
      selector: '[data-tour="ResourceFilters"]',
      content: 'Here you can add a filter, choose the category of the filter, choose the operator and value that you want that filter to have.',
    },
    {
      selector: '[data-tour="LabelRules"]',
      content: 'Similar to the highlighted area before, you can add filters here, now these would add a restriction on the label rule you would allow in your report.',
    },
    {
      selector: '[data-tour="Options"]',
      content: 'Here you can configure the time window in between the report has to take place, you can also choose the amount of steps you want the report to take on at a time, and then also if this has to be hourly daily or monthly steps. Lastlye you have to choose the cost you want to see and how to distinguish the different results.',
    },
    {
      selector: '[data-tour="ReportChart"]',
      content: 'After clicking on filter you will see a chart pop up here, if not then you will have to reconfigure the settings, it might have been too restrictive. You can switch between three types of charts. Bar-, line- and Area-chart.',
    },
    {
      selector: '[data-tour="fullApp"]',
      content: 'Enjoy experimenting with different configurations in order to view your data the way you want to!',
      action: () => {
        callbackList[1]()
        callbackList[2]()
      }
    },
  ]
}
