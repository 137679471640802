import { BarChart } from '@mantine/charts';
// import { dataNameAndColor } from 'features/projects/project-labeled-data-card';
import { dataNameAndColor, projectAndTheirApps } from 'features/projects/project-widget/widgetHelpFunctions';
import { TCurrency } from 'services/cost.interface';
import { useEffect, useState } from 'react';
import { ChartTooltip } from 'features/projects/project-finops-widget/useCases/ServiceCostsRegion';
import { parseData } from 'features/projects/project-labeled-data-widget/chartHelperFunctions';
// import { makeTooltip } from './helper';

interface LabeledDataProps {
  data: projectAndTheirApps[],
  series: dataNameAndColor[],
  height: number,
  width: number,
  currency: TCurrency

}

function LabeledDataComponent({
  data,
  series,
  height,
  width,
  currency
}: LabeledDataProps) {
  const [savedDelimiter, setSavedDelimiter] = useState<string>('en-US');
  const [savedGrouping, setSavedGrouping] = useState<string>('Yes');
  const [chartData, setChartData] = useState<projectAndTheirApps[]>([]);

  window.addEventListener('storage', () => {
    if (sessionStorage.getItem('delimiter') === ',') {
      setSavedDelimiter('de-DE')
    } else {
      setSavedDelimiter('en-US')
    }
    if (sessionStorage.getItem('grouping') !== null) setSavedGrouping(sessionStorage.getItem('grouping')!)
  })
  useEffect(() => {
    if (sessionStorage.getItem('delimiter') === ',') {
      setSavedDelimiter('de-DE')
    } else {
      setSavedDelimiter('en-US')
    }
    if (sessionStorage.getItem('grouping') !== null) setSavedGrouping(sessionStorage.getItem('grouping')!)
  }, [savedDelimiter, savedGrouping, sessionStorage.getItem('delimiter'), sessionStorage.getItem('grouping')])
  useEffect(() => {
    setChartData(parseData(data))
  }, [data])

  function getSeries() {
    if (chartData.find((el) => el.label === 'Other')) {
      return series.concat({
        name: 'Other',
        color: '#52238d'
      })
    } return series
  }
  return (
    <div className="w-full h-full">
      <BarChart
        valueFormatter={(value) => {
          return new Intl.NumberFormat(savedDelimiter, { useGrouping: savedGrouping === 'Yes' }).format(value)
        }}
        h={height}
        w={width}
        data={chartData}
        fillOpacity={0.85}
        dataKey="label"
        type="stacked"
        unit={currency.symbol}
        series={getSeries()}
        withLegend
        legendProps={{ verticalAlign: 'bottom', height: 50 }}
        style={{ transitionDuration: '0ms' }}
        barProps={{ fillOpacity: '80%' }}
        // tooltipProps={{
        //   content: ({ label, payload }) => makeTooltip(label, payload, currency),
        // }}
        // withLegend
        tooltipProps={{
          // eslint-disable-next-line react/no-unstable-nested-components
          content: ({ label, payload }) => {
            return <ChartTooltip label={label} payload={payload} currency={currency} />
          }
        }}
      />
    </div>
  )
}

export default LabeledDataComponent
