export const anomalousDailySpendingTypeApiCall = (startDate: string, endDate: string) => `
  query anomalousDailySpending{
  anomalousDailySpending(startDate: "${startDate}", endDate: "${endDate}" ) {
    dailyEffectiveCost
    day
    provider
    subAccountId
  }
}`
export const anomalousDailySpendingRTypeApiCall = (startDate: string, endDate: string) => `
  query anomalousDailySpendingR{
  anomalousDailySpendingR(startDate: "${startDate}", endDate: "${endDate}" ) {
    dailyEffectiveCost
    day
    provider
    region
    subAccountId
  }
}`
export const anomalousDailySpendingRSNTypeApiCall = (startDate: string, endDate: string) => `
  query anomalousDailySpendingRSN{
  anomalousDailySpendingRSN(startDate: "${startDate}", endDate: "${endDate}" ) {
    dailyEffectiveCost
    day
    provider
    region
    serviceName
    subAccountId
  }
}`
export const subAccountAvailabilityZoneCostTypeApiCall = (startDate: string, endDate: string, subAccId:string) => `
query subAccountAvailabilityZoneCost {
  subAccountAvailabilityZoneCost(startDate: "${startDate}", endDate:"${endDate}", subAccId: "${subAccId}") {
    availabilityZone
    billingPeriodStart
    provider
    region
    totalEffectiveCost
  }
}`

export const serviceCategoryCostsApiCall = (startDate: string, endDate: string) => `
query serviceCategoryCosts {
  serviceCategoryCosts(
    startDate: "${startDate}"
    endDate: "${endDate}"
  ) {
    billingCurrency
    month
    provider
    serviceCategory
    totalBilledCost
  }
}`
export const BudgetsWithBilledCostsApiCall = (startDate: string, endDate: string) => `
query budgetsWithBilledCosts {
  budgetsWithBilledCosts(
    startDate: "${startDate}"
    endDate: "${endDate}"
  ) {
    billingPeriodEnd
    billingPeriodStart
    provider
    totalBilledCost
  }
}`
export const CostPerComputeServiceForASubaccountApiCall = (startDate: string, endDate: string) => `
query costPerComputeServiceForASubaccount {
  costPerComputeServiceForASubaccount(
    startDate: "${startDate}"
    endDate: "${endDate}"
    subAccId:"N/A"
  ) {
    chargePeriodEnd
    chargePeriodStart
    monthlyEffectiveCost
    pricingQuantity
    resourceId
    resourceName
    serviceName
  }
}`
export const ServiceCostsRegionApiCall = (startDate: string, endDate: string) => `
query serviceCostsRegion {
  serviceCostsRegion(
    startDate: "${startDate}"
    endDate: "${endDate}"
  ) {
    chargePeriodStart
    provider
    region
    serviceName
    totalEffectiveCost
  }
}`
export const graphqlInitialCall = `
  query allFocusGroupings{
  allFocusGroupings {
    provider
    serviceCategory
    serviceName
  }
}`
