import { GraphCardContent, Table } from 'components'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { v4 as uuid } from 'uuid'
import { TCurrency } from 'services/cost.interface'
import { ResponsiveContainer, PieChart, Pie, Cell, Legend } from 'recharts'
import { GET_CHART_COLORS } from 'constants/color.constants'
import { BsTable } from 'react-icons/bs'
import { FaChartPie } from 'react-icons/fa'
import { useQuery } from 'react-query'
import { axios } from 'utils'
import { useResizeObserver } from '@mantine/hooks'
import { OptimizationTableColumns } from './constants'
import { optimizedDataCheckmarkType, renderLabel } from './helper'
import { WidgetData } from '../project-widget/widgetStatev2'
// import { renderLegend } from '../project-labeled-data-widget/chartHelperFunctions'

type optimizationData = {
  [index: string]: any
  done: optimizedDataCheckmarkType,
  id: string,
  name: string,
  action: string,
  monthlyCost: number,
  monthlySaving: number,
  savingPercentage: number,
  restart: string,
  rollback: string
  timestamp: string
}

interface OptiminzationWidgetProps {
  id: string
  currency: TCurrency
  editCallBack: () => void
  closeCallBack: () => void
  deleteWidget: () => void
  widgetObject: WidgetData
  setErrorMessage: (msg:string) => void
  deleteErrorMessage: () => void
}

export function OptiminzationWidget({ id,
  currency,
  editCallBack,
  closeCallBack,
  deleteWidget,
  widgetObject,
  setErrorMessage,
  deleteErrorMessage
}: OptiminzationWidgetProps) {
  widgetObject.resetOptions(id)
  const [queryKey] = useState<string>('')
  const [data, setData] = useState<optimizationData[]>([])
  const [reload, setReload] = useState<number>(1)

  const formatOptions = useMemo(() => ({ currency }), [currency])
  const [editBool, setEditBool] = useState<boolean>(widgetObject.getOptimizationWidgetView(id))
  const [dataProcessed, setDataProcessed] = useState<boolean>(false)
  const [idOfClickedItem, setIdOfClickedItem] = useState<string>('')

  const [ref, rect] = useResizeObserver();
  const [pieCostData, setPieCostData] = useState<{
    name: string,
    value: number,
    color: string
  }[]>([])
  const [pieSavingsData, setPieSavingsData] = useState<{
    name: string,
    value: number,
    color: string
  }[]>([])
  const [chosenSymbol] = useState<string>(currency.symbol)
  const changeEditBool = () => {
    widgetObject.changeOptimizationWidgetView(id)
    setEditBool(!editBool)
  }
  const changeReload = useCallback(() => {
    setReload((p) => p + 1);
  }, []);
  // ***************************************************************************
  // Add the Edit widget to the options of this widget.
  // ***************************************************************************
  widgetObject.editOptions(id, 'Change view', changeEditBool)
  widgetObject.editOptions(id, 'Delete widget', deleteWidget)

  const { error } = useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      try {
        if (!dataProcessed) {
          await axios
            .get('/optimization-data')
            .then((res) => {
              // data(res.data)
              setData(res.data.map((dataPiece: {
                name: string;
                action: string;
                monthlyCost: number;
                monthlySavings: number;
                savingPercentage: number;
                restart: string;
                rollback: string;
                timestamp: string;
              }) => {
                const theId = uuid()
                const theIdx = widgetObject.getOptimizationWidgetChecked(id).findIndex(
                  (el) => el.resourceName === dataPiece.name
                )
                let checked = theIdx !== -1
                if (checked) {
                  const theView = widgetObject.getOptimizationWidgetChecked(id)[theIdx]
                  const timestampOfThePast = new Date(theView.timestamp)
                  const timestamOfTheNow = new Date(dataPiece.timestamp)
                  if (timestamOfTheNow > timestampOfThePast) {
                    checked = false
                    widgetObject.deleteOptimizationWidgetChecked(id, dataPiece.name)
                  }
                }
                return {
                  done: {
                    value: checked,
                    callback: () => {
                      setIdOfClickedItem(theId)
                    }
                  },
                  id: theId,
                  name: dataPiece.name,
                  action: dataPiece.action,
                  monthlyCost: dataPiece.monthlyCost,
                  monthlySaving: dataPiece.monthlySavings,
                  savingPercentage: dataPiece.savingPercentage,
                  restart: dataPiece.restart === 't' ? 'yes' : 'no',
                  rollback: dataPiece.rollback === 't' ? 'yes' : 'no',
                  timestamp: dataPiece.timestamp
                }
              }).sort((a: optimizationData, b: optimizationData) => {
                return b.MonthlySaving - a.MonthlySaving
              }))
              deleteErrorMessage()
              setDataProcessed(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                setErrorMessage('There is no optimization data to be found.')
                setDataProcessed(true)
              }
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })

  useEffect(() => {
    console.log(editCallBack) // for calling edit in tutorial
    console.log(closeCallBack) // for closing the tutorial
    // console.log(setChosenValue)
    let ctr = -1
    setPieCostData(data.map((el) => {
      ctr++
      const value: number = el.monthlyCost
      return {
        name: el.name,
        value,
        color: GET_CHART_COLORS(ctr)
      }
    }))
    ctr = -1
    setPieSavingsData(data.map((el) => {
      ctr++
      const value: number = el.monthlySaving
      return {
        name: el.name,
        value,
        color: GET_CHART_COLORS(ctr)
      }
    }))
  }, [
    data,
  ])
  useEffect(() => {
    if (idOfClickedItem !== '') {
      const newData = data
      const element = newData.find((el: { id: string }) => el.id === idOfClickedItem)
      if (element) {
        element.done.value = !element.done.value
        if (widgetObject.getOptimizationWidgetChecked(id).findIndex(
          (check) => check.resourceName === element.name
        ) === -1) {
          widgetObject.addOptimizationWidgetChecked(id, element.name, element.timestamp)
        } else {
          widgetObject.deleteOptimizationWidgetChecked(id, element.name)
        }
        setData(newData)
        changeReload()
        setIdOfClickedItem('')
      }
    }
  }, [idOfClickedItem])

  if (error) return <div>An error has occurred</div>
  return (
    <div ref={ref}>
      {!editBool && Boolean(reload) && (
        <div className="h-full">
          <div className="flex flex-row">
            <BsTable size={20} />
            <span className="px-2 w-1/2">Possible optimizations</span>
          </div>
          <div>
            <GraphCardContent minHeight={250} className=" py-2 h-3/4 w-7/8">
              <Table
                columns={OptimizationTableColumns}
                data={data}
                formatOptions={formatOptions}
              />
            </GraphCardContent>
          </div>
        </div>
      )}
      {editBool && (
        <div>
          <div className="flex flex-row">
            <FaChartPie size={20} />
            <span className="px-2 w-1/2">Chart of the optimization data</span>
          </div>
        </div>
      )}
      {editBool && (
        <div className={`h-1/4 ${rect.width > 650 ? 'flex flex-row' : 'block'}`}>
          <GraphCardContent className="h-1/4">
            <span>The estimated monthly cost:</span>
            <ResponsiveContainer>
              <PieChart>
                <Pie
                  data={pieCostData}
                  dataKey="value"
                  nameKey="name"
                  labelLine={false}
                  cx="50%"
                  cy="50%"
                  fill="#82ca9d"
                  label={(value) => {
                    return renderLabel(value, chosenSymbol)
                  }}
                >
                  {pieCostData.map((entry) => (
                    <Cell key={entry.color} fill={entry.color} />
                  ))}
                </Pie>
                <Legend
                  payload={pieCostData.map((el) => { return { value: el.name, color: el.color } })}
                  height={36}
                  layout="horizontal"
                  verticalAlign="top"
                />
              </PieChart>
            </ResponsiveContainer>
          </GraphCardContent>
          <GraphCardContent className="h-1/4">
            <span>The estimated monthly savings:</span>
            <ResponsiveContainer>
              <PieChart>

                <Pie
                  data={pieSavingsData}
                  dataKey="value"
                  nameKey="name"
                  labelLine={false}
                  cx="50%"
                  cy="50%"
                  fill="#82ca9d"
                  label={(value) => {
                    return renderLabel(value, chosenSymbol)
                  }}
                >
                  {pieSavingsData.map((entry) => (
                    <Cell key={entry.color} fill={entry.color} />
                  ))}
                </Pie>
                <Legend
                  payload={pieCostData.map((el) => { return { value: el.name, color: el.color } })}
                  //  content={renderLegend(pieData, id, currency)}
                  height={36}
                  layout="horizontal"
                  verticalAlign="top"
                />
              </PieChart>
            </ResponsiveContainer>
          </GraphCardContent>
        </div>
      )}
    </div>
  )
}
