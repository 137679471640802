import myImageTable from '../../assets/icons/websiteIcons/TableHomePage.png';
import myImagePie from '../../assets/icons/websiteIcons/PieChartHomePage.png';
import myImageBar from '../../assets/icons/websiteIcons/BarChartHomePage.png';
import myImageLine from '../../assets/icons/websiteIcons/optimisation.png';
import myImageOptimization from '../../assets/icons/websiteIcons/visibility.png';

export type widgetAddType = {
  widgetName: string,
  addType: string,
  widgetDescription: string,
  imageString: string,
  link?: string,
  widgetTags: string[]
}

export const listOfWidgets: { label: string, lst: widgetAddType[] }[] = [
  {
    label: 'Predefined',
    lst: [
      {
        widgetName: 'Total spend by product last month',
        addType: 'predefineWidgetAccTrue',
        widgetDescription: 'Compare all of the products that you are using across different providers. This widget displays the cost for each of these services in a pie chart so you can easily see which service is the most expensive. The cost displayed is the FinOps total effective cost.',
        imageString: myImagePie, // 'src\assets\icons\websiteIcons\TableHomePage.png',
        widgetTags: ['', 'Service names', 'Effective cost', 'FinOps Practitioner', 'Product', 'Engineering', 'Finance', 'Procurement']
      }, {
        widgetName: 'Spend for previous month and trends',
        addType: 'predefineWidgetAccFalse',
        widgetDescription: 'This widget will gather the cost of all your used services over the last 31 days, it will then display the daily sum in a line chart. This means that you can look at your cloud related expenses for over the last 31 days. The cost displayed is the FinOps total effective cost.',
        imageString: myImageLine,
        widgetTags: ['', 'Effective cost', 'FinOps Practitioner', 'Product', 'Engineering', 'Finance', 'Procurement']
      }, {
        widgetName: 'Compare accumulated cost with budget this month',
        addType: 'predefineWidgetAccFalseWithBudgeting',
        widgetDescription: 'Similar to the "Spend for previous month and trends" you will see a trend that represents your spendings, the difference here lies in the fact that this widget accumulates this cost. This way you can see how your spendings gradually reach your set budget.',
        imageString: myImageLine,
        widgetTags: ['', 'Effective cost', 'FinOps Practitioner', 'Product', 'Engineering', 'Finance', 'Procurement']
      }, {
        widgetName: 'Anomalous daily spending last month',
        addType: 'FinOpsUseCaseWidget-Anomalous daily spending',
        widgetDescription: 'It is important for FinOps Practitioners to track daily spend from various angles to ensure any anomalous cost trends are easily detected. Purchases can often cause false positives, for this reason, we want to monitor cost trends with up-front payments amortized. Graphing the data by day in a multiline chart can often help find the outliers.',
        imageString: myImageLine,
        link: 'https://www.finops.org/assets/use-cases/daily-anomaly-subaccount/',
        widgetTags: ['', 'FinOps', 'FinOps Practitioner']
      }, {
        widgetName: 'Anomalous daily spending by region last month',
        addType: 'FinOpsUseCaseWidget-Anomalous daily spending R',
        widgetDescription: 'It is important for FinOps Practitioners to track daily spend from various angles to ensure any anomalous cost trends are easily detected. Tracking by region enables further granularity in understanding the source of anomalous spend. Purchases can often cause false positives, for this reason, we want to monitor cost trends with up-front payments amortized. Graphing the data by day in a multiline chart can often help find the outliers.',
        imageString: myImageLine,
        link: 'https://www.finops.org/assets/use-cases/daily-anomaly-region/',
        widgetTags: ['', 'FinOps', 'Developer recommendations', 'FinOps Practitioner']
      }, {
        widgetName: 'Anomalous daily spending by service name last month',
        addType: 'FinOpsUseCaseWidget-Anomalous daily spending RSN',
        widgetDescription: 'It is important for FinOps Practitioners to track daily spend from various angles to ensure any anomalous cost trends are easily detected. Tracking by region and service enables further granularity in understanding the source of anomalous spend. Purchases can often cause false positives, for this reason, we want to monitor cost trends with up-front payments amortized. Graphing the data by day in a multiline chart can often help find the outliers.',
        imageString: myImageBar,
        link: 'https://www.finops.org/assets/use-cases/daily-anomaly-services/',
        widgetTags: ['', 'FinOps', 'FinOps Practitioner']
      }, {
        widgetName: 'Sub account availability zone cost over the last 6 months',
        addType: 'FinOpsUseCaseWidget-Sub account availability zone cost',
        widgetDescription: 'It is good practice for Engineering to monitor cost and usage of services by availability zone for all regions to ensure that there isn’t any cost/usage occurring outside of the expected locations and that your costs are spread according to an expected ratio.',
        imageString: myImageLine,
        link: 'https://www.finops.org/assets/use-cases/costs-by-availability-zone/',
        widgetTags: ['', 'FinOps', 'Engineering']
      }, {
        widgetName: 'Service category costs last month',
        addType: 'FinOpsUseCaseWidget-Service category costs last month',
        widgetDescription: 'Understanding costs across providers, billing periods, and service categories (Analytics, Compute, Database, Storage, etc.) provides valuable insight into total costs. This widget will give you this data accumulated for one month and displays it in a multiline chart.',
        imageString: myImagePie,
        link: 'https://www.finops.org/assets/use-cases/costs-service-category/',
        widgetTags: ['', 'FinOps', 'Service names', 'Developer recommendations', 'FinOps Practitioner']
      }, {
        widgetName: 'Service category costs last 6 months',
        addType: 'FinOpsUseCaseWidget-Service category costs last 6 months',
        widgetDescription: 'Understanding costs across providers, billing periods, and service categories (Analytics, Compute, Database, Storage, etc.) provides valuable insight into total costs. This widget will give you this data accumulated for six months and displays it in a multiline chart.',
        imageString: myImageBar,
        link: 'https://www.finops.org/assets/use-cases/costs-service-category/',
        widgetTags: ['', 'FinOps', 'Developer recommendations', 'FinOps Practitioner']
      }, {
        widgetName: 'Budgets with billed costs for the last 6 months',
        addType: 'FinOpsUseCaseWidget-Budgets with billed costs',
        widgetDescription: 'Finance manages tracking of budgets and must update long-term budgets with the actual costs billed each month so the budget is not exceeded.',
        imageString: myImageBar,
        link: 'https://www.finops.org/assets/use-cases/update-budgets/',
        widgetTags: ['', 'FinOps', 'Finance', 'FinOps Practitioner']
      }, {
        widgetName: 'Cost per compute service last month',
        addType: 'FinOpsUseCaseWidget-Cost per compute service',
        widgetDescription: 'Compute spend tends to be the largest component of spend in most cloud bills. Drilling into the resource-level view of cost for a given day can provide a detailed view of the heaviest spending compute resources, including a view of which time periods the consumption was at its highest level.',
        imageString: myImagePie,
        link: 'https://www.finops.org/assets/use-cases/cost-compute-subaccount/',
        widgetTags: ['', 'FinOps', 'Engineering', 'FinOps Practitioner']
      }, {
        widgetName: 'Service costs per region last month',
        addType: 'FinOpsUseCaseWidget-Service costs per region',
        widgetDescription: 'Analyzing Service level spending trends over a time period can expose expected and unexpected growth in the consumption of various services, by region, across all providers and is a good way for the FinOps team to prioritize usage and rate optimization conversations with product, engineering and finance leaders within the organization.',
        imageString: myImagePie,
        link: 'https://www.finops.org/assets/use-cases/analyze-service-costs-by-region/',
        widgetTags: ['', 'FinOps', 'FinOps Practitioner', 'Finance']
      }
    ]
  },
  {
    label: 'Configurable',
    lst: [
      {
        widgetName: 'Aggregate cost by widget',
        addType: 'projectTable',
        widgetDescription: 'This widget fetches your data, processed by the system to be more informative. This data will then be represented inside of a table, which you can order/hide/filter the columns of. You can also group based on a column. when clicking a row it will fetch the historical data of that resource data and display that in a bar chart.',
        imageString: myImageTable,
        widgetTags: ['', 'Effective cost', 'Table view', 'Developer recommendations', 'FinOps Practitioner', 'Product', 'Engineering', 'Finance', 'Procurement']
      }, {
        widgetName: 'Group cost by widget',
        addType: 'cumulativeCost',
        widgetDescription: 'By using this widget you will gain the ability to look at your data by grouping them on a specifc focus column. There are two sides to this widget, one that will show the grouped data for one month and displays it in a piechart. Another will show this data in a stacked barchart over a selected period of time.',
        imageString: myImageBar,
        widgetTags: ['', 'Service names', 'Effective cost', 'Developer recommendations', 'FinOps Practitioner', 'Product', 'Engineering', 'Finance', 'Procurement']
      }, {
        widgetName: 'Optiminzation widget',
        addType: 'optimizationWidget',
        widgetDescription: 'The Optimization widget allows you to see recommendations across your cloud providers. It provides data that shows how much money you could save if you did the recommended actions. You can check off what you have already accomplished. There is a second view that visualises the potential savings.',
        imageString: myImageOptimization,
        widgetTags: ['', 'Effective cost', 'Optimizations', 'FinOps Practitioner', 'Product', 'Engineering', 'Finance', 'Procurement']
      }
    ]
  }
]
export type addWidgetFilterType = {
  tag: string,
  checked: boolean
}
export const addWidgetFilterStrings: string[] = [
  'FinOps', 'Service names', 'Effective cost', 'Table view', 'Developer recommendations', 'Optimizations'
]
export function getDescription(tag: string) {
  let listOfWidgetsFlattened: widgetAddType[] = []
  listOfWidgets.forEach((el) => { listOfWidgetsFlattened = listOfWidgetsFlattened.concat(el.lst) })
  const res = listOfWidgetsFlattened.find((el) => el.addType === tag)?.widgetDescription
  if (res) return res
  return ''
}
export function getWidgetName(tag: string) {
  let listOfWidgetsFlattened: widgetAddType[] = []
  listOfWidgets.forEach((el) => { listOfWidgetsFlattened = listOfWidgetsFlattened.concat(el.lst) })
  const res = listOfWidgetsFlattened.find((el) => el.addType === tag)?.widgetName
  if (res) return res
  return ''
}
