import { GET_CHART_COLORS } from 'constants/color.constants'
import { useState } from 'react'
import { TPieChartData } from 'services/chart.interface'
import { newTFocusGroup, TFocusGroup } from 'services/costhistory.interface'
import { TCurrency } from 'services/cost.interface'
import { Paper } from '@mantine/core'
import env from 'env'
import { projectAndTheirApps, dataNameAndColor } from '../project-widget/widgetHelpFunctions'
// import { chartJsDataSets } from '.'

export function dateFormatChanger(date: string) {
  let savedDateFormat = sessionStorage.getItem('dateFormat');
  if (savedDateFormat === null) savedDateFormat = 'YYYY-MM-DD'
  const splitted = date.split('T')[0].split('-')
  const days = splitted[2]
  const month = splitted[1]
  const year = splitted[0]
  if (savedDateFormat === 'YYYY-MM-DD') return date.split('T')[0]
  return `${days}-${month}-${year}`
}
export function dateFormatChangerWithHour(date: string) {
  let savedDateFormat = sessionStorage.getItem('dateFormat');
  if (savedDateFormat === null) savedDateFormat = 'YYYY-MM-DD'
  const splitted = date.split('T')[0].split('-')
  const days = splitted[2]
  const month = splitted[1]
  const year = splitted[0]
  if (savedDateFormat === 'YYYY-MM-DD') return `${date.split('T')[0]} at ${date.split('T')[1].split(':')[0]}h`
  return `${days}-${month}-${year} at ${date.split('T')[1].split(':')[0]}h`
}

export function parseAccumulatedFalseData(
  receivedData: TFocusGroup[],
  stepUnit: string
): { values: projectAndTheirApps[], names: dataNameAndColor[] } {
  if (receivedData.length !== 0) {
    let ctr = 0
    const newData: projectAndTheirApps[] = []
    const dataNames: dataNameAndColor[] = []
    receivedData.forEach((dataMoment) => {
      const labels = Object.getOwnPropertyNames(dataMoment)
      if (labels.length !== 0) {
        const { timestamp } = dataMoment[labels[0]]
        const index = newData.findIndex((el) => {
          if (stepUnit === 'h') return el.label === dateFormatChangerWithHour(timestamp.toString())
          return el.label === dateFormatChanger(timestamp.toString())
        });
        const temp = index !== -1 ? newData[index] : {
          label: stepUnit === 'h' ? dateFormatChangerWithHour(timestamp.toString()) : dateFormatChanger(timestamp.toString())
        };
        let item: projectAndTheirApps = temp
        labels.forEach((label) => {
          const dataElement = dataMoment[label]
          item = { ...item, [label]: Number(Number(dataElement.totalBilledCost).toFixed(2)) }
          // when the data matches
          const possibleNameIndex = dataNames.findIndex((pair) => pair.name === label)
          if (possibleNameIndex === -1) {
            dataNames.push({ name: label, color: GET_CHART_COLORS(ctr) })
            ctr++
          }
        })
        if (index !== -1) {
          newData[newData.findIndex((el) => {
            if (stepUnit === 'h') return el.label === dateFormatChangerWithHour(timestamp.toString())
            return el.label === dateFormatChanger(timestamp.toString())
          })] = item
        } else {
          newData.push(item)
        }
      }
    })
    newData.sort((a, b) => {
      if (new Date(a.label) < new Date(b.labe)) {
        return -1;
      }
      if (new Date(a.label) > new Date(b.label)) {
        return 1;
      }
      return 0;
    });
    return { values: newData, names: dataNames }
  }
  return { values: [], names: [] }
}

export function parseAccumulatedTrueData(
  receivedData: newTFocusGroup[],
  // path: string[]
): TPieChartData[] {
  if (receivedData.length !== 0) {
    const newData: TPieChartData[] = []
    receivedData.sort((a, b) => {
      const name1 = Object.getOwnPropertyNames(a)[0]
      const name2 = Object.getOwnPropertyNames(b)[0]
      return b[name2].totalBilledCost - a[name1].totalBilledCost
    }).slice(0, 10).forEach((el) => {
      const name = Object.getOwnPropertyNames(el)[0]
      const newEl = {
        name,
        value: Number(el[name].totalBilledCost),
        color: ''
      }
      newData.push(newEl)
    })
    return newData
  } return []
}

export function getProviderNames(receivedData: newTFocusGroup[]) {
  const res: string[] = ['']
  if (receivedData.length !== 0) {
    const labels = Object.getOwnPropertyNames(receivedData[0])
    labels.forEach((label) => {
      const { resources } = receivedData[0][label]
      resources.forEach((resource) => {
        const { provider } = resource
        const index = res.findIndex((el) => el === provider)
        if (index === -1) res.push(provider)
      })
    })
  }
  return res
}

// function legendOver(name: string, chartValue: string, parentKey: string, currency: TCurrency) {
//   const id = `${parentKey}${name}`
//   const element = document.getElementById(id)!;
//   const newstr = name.concat(' : ').concat(`${currency.symbol} `).concat(chartValue)
//   element.innerHTML = newstr
// }
// function legendOut(
//   name: string,
//   str: string,
//   chartValue: string,
//   parentKey: string,
//   currency: TCurrency
// ) {
//   const id = `${parentKey}${name}`
//   const element = document.getElementById(id)!;
//   const newstr = str.concat(' : ').concat(`${currency.symbol} `).concat(chartValue)
//   element.innerHTML = newstr
// }

const splitter = (value: string, bool: boolean) => {
  let temp = value
  if (value.length > 10 && bool) {
    temp = value.substr(0, 10).concat('...')
  } else {
    temp = value
  }
  return temp
};
interface RenderLegendProps {
  data: TPieChartData[],
  parentKey: string,
  currency: TCurrency,
  delimiter: string,
  grouping: string
}

export function RenderLegend({
  data, parentKey, currency, delimiter, grouping
}: RenderLegendProps) {
  const [bool, setBool] = useState<boolean>(true)
  const otherElement = data.find((el) => el.name === 'Other')
  return (
    <Paper
      className="absolute w-fit h-max bg-white opacity-100 z-10"
      onMouseEnter={() => { setBool(false) }}
      onBlur={() => { setBool(true) }}
      onMouseLeave={() => { setBool(true) }}
      withBorder
      radius="md"
      shadow="md"
    >
      {
        data.filter((el) => el.name !== 'Other').sort((a, b) => b.value - a.value).map((
          entry: {
            value: any;
            color: any;
            name: any
          },
          index: any
        ) => {
          const val =
            new Intl.NumberFormat(delimiter, { useGrouping: grouping === 'Yes' }).format(entry.value)
          return (
            <div
              className="flex p-1 h-fit"
              style={{ color: `${entry.color}` }}
              key={`item-${index}`}
              id={`${parentKey}${entry.name}`}
            >
              <div style={{ border: `2px solid ${entry.color}`, borderRadius: '0.5rem' }} />
              <div className="flex pl-1">
                <span className="text-pretty">
                  <span className="text-gray-600 w-max font-semibold text-sm">
                    {currency.symbol} {val}{' '}
                  </span>
                  <span className="text-gray-400 text-xs">
                    {entry.name !== '' ? splitter(entry.name, bool) : 'Taxes'}
                  </span>
                </span>
              </div>
            </div>
          )
        })
      }
      {otherElement && otherElement.value !== 0 && (
        <div
          className="flex p-1 h-fit"
          style={{ color: `${otherElement.color}` }}
          id={`${parentKey}${otherElement.name}`}
        >
          <div style={{ border: `2px solid ${otherElement.color}`, borderRadius: '0.5rem' }} />
          <div className="flex pl-1">
            <span className="text-pretty">
              <span className="text-gray-600 w-max font-semibold text-sm">
                {currency.symbol} {new Intl.NumberFormat(delimiter, { useGrouping: grouping === 'Yes' }).format(otherElement.value)}{' '}
              </span>
              <span className="text-gray-400 text-xs">
                {otherElement.name !== '' ? splitter(otherElement.name, bool) : 'Taxes'}
              </span>
            </span>
          </div>
        </div>
      )}
    </Paper>
  );
}
export function getSmallestVal(rect: { width: number, height: number }) {
  const multiplier = 7
  const divider = 8
  if ((rect.height * multiplier) / divider >= (rect.width * multiplier) / divider) {
    return (rect.width * multiplier) / divider
  }
  return (rect.height * multiplier) / divider
}

export function renderLabel(
  val: any,
  currency: TCurrency,
  maxVal: number,
  delimiter: string,
  grouping: string
) {
  const amount = new Intl.NumberFormat(delimiter, { useGrouping: grouping === 'Yes' }).format(val.value)
  const RADIAN = Math.PI / 180;
  const radius = val.innerRadius + (val.outerRadius - val.innerRadius) * 0.5;
  const x = val.cx + radius * Math.cos(-val.midAngle * RADIAN);
  const y = val.cy + radius * Math.sin(-val.midAngle * RADIAN);
  if ((val.value / maxVal) * 100 > 15) {
    return <text x={x} y={y} fill="white" textAnchor={x > val.cx ? 'start' : 'end'} dominantBaseline="central">{currency.symbol}{amount}</text>
  } return <text />
}

export const renderCustomizedLabel = (
  val: any,
  currency: TCurrency,
  delimiter: string,
  grouping: string
) => {
  const amount = new Intl.NumberFormat(delimiter, { useGrouping: grouping === 'Yes' }).format(val.value)
  return (
    <text className="z-50 text-xs" x={val.x} y={val.y} fill={val.color} textAnchor={val.x > val.cx ? 'start' : 'end'}>
      {currency.symbol} {amount}
    </text>
  );
};

export function parseData(data: projectAndTheirApps[]) {
  return data.map((el) => {
    const newEl: projectAndTheirApps = {
      label: ''
    }
    const properties = Object.getOwnPropertyNames(el).filter((property) => property !== 'label')
    newEl.label = el.label
    const sorted = properties.map((property) => {
      return {
        property,
        value: el[property]
      }
    }).sort((a, b) => b.value - a.value)
    sorted.slice(0, Number(env.REACT_APP_MAX_CHART_ITEMS)).forEach((propValPair) => {
      newEl[propValPair.property] = propValPair.value
    })
    let rest = 0
    sorted.slice(Number(env.REACT_APP_MAX_CHART_ITEMS)).forEach((propValPair) => {
      rest += propValPair.value
    })
    if (rest !== 0) newEl.Other = rest
    return newEl
  })
}
export function parsePieData(data: TPieChartData[]) {
  const sorted = data.sort((a, b) => b.value - a.value)
  const sliced = sorted.slice(0, Number(env.REACT_APP_MAX_CHART_ITEMS))
  let rest = 0
  sorted.slice(Number(env.REACT_APP_MAX_CHART_ITEMS)).forEach((propValPair) => {
    rest += propValPair.value
  })
  const resList = sliced.concat({ name: 'Other', value: rest, color: '' })
  let ctr = 0
  return resList.map((el) => {
    el.color = GET_CHART_COLORS(ctr)
    ctr++
    return el
  })
}
