import { useState } from 'react';
import { useResizeObserver } from '@mantine/hooks';
import { Polygon } from 'recharts';
import { TCurrency } from 'services/cost.interface';
import { AnomalousDailySpendingRSN } from './useCases/AnomalousDailySpendingRSN';
import { SubAccountAvailabilityZoneCost } from './useCases/SubAccountAvailabilityZoneCost';
import { ServiceCategoryCosts } from './useCases/ServiceCategoryCosts';
import { WidgetData } from '../project-widget/widgetStatev2';
import { BudgetsWithBilledCosts } from './useCases/BudgetsWithBilledCosts';
import { CostPerComputeServiceForASubaccount } from './useCases/CostPerComputeServiceForASubaccount';
import { ServiceCostsRegion } from './useCases/ServiceCostsRegion';
import { renderCustomizedLabel } from '../project-labeled-data-widget/chartHelperFunctions';

export function drawNumberIf(
  val: any,
  totalCost: number,
  currency: TCurrency,
  savedDelimiter: string,
  savedGrouping: string
) {
  if (val.value <= (totalCost / 100)) return <div />
  return (renderCustomizedLabel(
    val,
    currency,
    savedDelimiter,
    savedGrouping
  ))
}

export function drawLineIf(
  val: any,
  totalCost: number,
) {
  if (val.value <= (totalCost / 100)) return <div />
  return <Polygon stroke={val.color} fill={val.color} points={val.points} />
}

interface FinopsWidgetProps {
  id: string,
  tag: string,
  widgetObject: WidgetData,
  deleteWidget: () => void,
}
export function FinopsWidget({ id, tag, widgetObject, deleteWidget }: FinopsWidgetProps) {
  const [finopsSelectedUseCase] = useState<string>(tag)
  const [ref, rect] = useResizeObserver();

  // useEffect(() => {
  //   console.log('has changed')
  //   console.log(finopsSelectedUseCase)
  // }, [finopsSelectedUseCase])

  widgetObject.resetOptions(id)
  // widgetObject.editOptions(id, 'Reload widget', () => {
  //   reloadFunctionality()
  // })
  widgetObject.editOptions(id, 'Delete widget', () => {
    // sessionStorage.removeItem(sessionStorageTag)
    deleteWidget()
  })

  return (
    <div className="h-full" ref={ref}>
      {/* <select
        value={finopsSelectedUseCase}
        name="FinopsUseCases"
        id="FinopsUseCases"
        onChange={(event) => {
          // console.log(event.target.value)
          setFinopsSelectedUseCase(event.target.value)
        }}
      >
        {['Anomalous daily spending', 'Anomalous daily spe
        nding R', 'Anomalous daily spending RSN', 'Su
        b account availability zone cost', 'Service cate
        gory costs last month', 'Service category costs last 6 months'].map((el) => {
          return (
            <option value={el}>{el}</option>
          )
        })}
      </select> */}
      <div className="h-full">
        {finopsSelectedUseCase === 'Anomalous daily spending' && (
          <div className="h-full">
            <AnomalousDailySpendingRSN id={id} tag="" height={(rect.height * 7) / 8} />
          </div>
        )}
        {finopsSelectedUseCase === 'Anomalous daily spending R' && (
          <div className="h-full">
            <AnomalousDailySpendingRSN id={id} tag="R" height={(rect.height * 7) / 8} />
          </div>
        )}
        {finopsSelectedUseCase === 'Anomalous daily spending RSN' && (
          <div className="h-full">
            <AnomalousDailySpendingRSN id={id} tag="RSN" height={(rect.height * 7) / 8} />
          </div>
        )}
        {finopsSelectedUseCase === 'Sub account availability zone cost' && (
          <div className="h-full">
            <SubAccountAvailabilityZoneCost id={id} height={(rect.height * 7) / 8} />
          </div>
        )}
        {finopsSelectedUseCase === 'Service category costs last month' && (
          <div className="h-full">
            <ServiceCategoryCosts
              id={id}
              tag="lastmonth"
              height={(rect.height * 7) / 8}
            />
          </div>
        )}
        {finopsSelectedUseCase === 'Service category costs last 6 months' && (
          <div className="h-full">
            <ServiceCategoryCosts
              id={id}
              tag="last6months"
              height={(rect.height * 7) / 8}
            />
          </div>
        )}
        {finopsSelectedUseCase === 'Budgets with billed costs' && (
          <div className="h-full">
            <BudgetsWithBilledCosts id={id} height={(rect.height * 7) / 8} />
          </div>
        )}
        {finopsSelectedUseCase === 'Cost per compute service' && (
          <div className="h-full">
            <CostPerComputeServiceForASubaccount
              id={id}
            />
          </div>
        )}
        {finopsSelectedUseCase === 'Service costs per region' && (
          <div className="h-full">
            <ServiceCostsRegion
              id={id}
              height={(rect.height * 6) / 8}
            />
          </div>
        )}
      </div>
    </div>
  )
}
