import { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { HiOutlineTrash } from 'react-icons/hi'
import { axios } from 'utils'
import { v4 as uuid } from 'uuid'
import {
  TLabelRuleOperator
} from 'services/label.rule.interface'
import { Bounce, toast } from 'react-toastify'
import { titleCase } from 'pages/advanced-reporting/ResourceFilterElement'
import { getBgColor, getBorderColor, getTextColor, isString } from '../helperFunctions';
import {
  LABEL_RULE_ELEMENT_TYPE,
  LABEL_RULES_OPERATOR_STRING_OPTIONS,
  LABEL_RULES_OPERATOR_NUMERIC_OPTIONS
} from '../label.rule.constants'
import { effectTypeWithId, ruleTypeWithId } from '../queryHelperFunctions'

interface ItemDataEditComponentProps {
  editItem: ruleTypeWithId | effectTypeWithId
  type: string
  useOption?: boolean
  tag?: string
  labelList: string[]
  options: { label: string, value: string }[]
  onChange?: (rule: ruleTypeWithId | effectTypeWithId) => void
  onDelete?: (rule: ruleTypeWithId | effectTypeWithId) => void
}

function ItemDataEditComponent({
  editItem,
  type,
  useOption,
  tag,
  labelList,
  options,
  onChange,
  onDelete
}: ItemDataEditComponentProps) {
  const operatorStringOptions = useMemo(() => {
    return [...LABEL_RULES_OPERATOR_STRING_OPTIONS]
  }, [])

  const operatorNumericOptions = useMemo(() => {
    return [...LABEL_RULES_OPERATOR_NUMERIC_OPTIONS]
  }, [])

  const [values, setValues] = useState<string[]>([])
  const [key, setKey] = useState<string>(editItem.key)
  useEffect(() => {
    if (editItem.key === '' && type === LABEL_RULE_ELEMENT_TYPE.RULES) {
      if (onChange && options.length !== 0) onChange({ ...editItem, key: options[0].value })
    } else {
      setKey(editItem.key)
    }
  }, [editItem.key, options])

  const [processed, setProcessed] = useState<boolean>(false)
  const [queryKey, setQueryKey] = useState<string>('')

  const { error } = useQuery({
    queryKey: [`query?${queryKey}`],
    queryFn: async () => {
      try {
        if (!processed && key !== '' && tag !== 'ec2') {
          const query = type === LABEL_RULE_ELEMENT_TYPE.EFFECTS ? `/labels/${key}` : `/fields/${key}`
          await axios
            .get(query)
            .then((res) => {
              setValues(res.data)
              setProcessed(true)
            })
            .catch((error) => {
              if (error.response.data) {
                toast.error(`The type "${type}" does not have any values`, {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  transition: Bounce,
                })
                setProcessed(true)
              }
            })
        }
      } catch (error) {
        setProcessed(true)
        throw new Error(`Error code ${error}`)
      }
    }
  })
  function loadNames() {
    return (
      <datalist id={`names${editItem.id}`}>
        {labelList.map((el, k) => { return <option key={`names-${k}`} value={el} /> })}
      </datalist>
    )
  }

  function loadValues() {
    return (
      <datalist id={`values${editItem.id}`}>
        {values.map((el, k) => { return <option key={`names-${k}`} value={el} /> })}
      </datalist>
    )
  }

  useEffect(() => {
    const isALabel = labelList.findIndex((el) => el === key) !== -1
      || options.findIndex((el) => el.value === key) !== -1
    if (isALabel) {
      if (type === 'rules') {
        if (isString(key)) {
          setProcessed(false)
          setValues([])
          setQueryKey(uuid())
        } else setValues([])
      } else {
        setProcessed(false)
        setValues([])
        setQueryKey(uuid())
      }
    }
  }, [key, editItem.value])

  if (error) return <div>An error has occurred</div>
  return (
    <div className={`h-fit w-full ${type === LABEL_RULE_ELEMENT_TYPE.RULES ? 'p-1' : ''}`}>
      <div className={`rounded-lg ${getBgColor(tag)} flex ${type === LABEL_RULE_ELEMENT_TYPE.RULES ? `border ${getBorderColor(tag)}` : ''}`}>
        <div className={`w-full ${tag ? 'p-1' : ''}`}>
          {tag && (
            <div className={`${getTextColor(tag)} font-semibold pl-5`}>
              <span>{tag}</span>
            </div>
          )}
          <div className={`flex ${getBgColor(tag)} rounded-lg w-full`}>
            <div className="flex flex-col sm:flex-row w-full">
              {useOption && (
                <div className="flex w-full ">
                  {/* {reportLoadAutoCompleteIcon(tag, editItem.key, false, getTextColor(tag))} */}
                  <select
                    className={`h-full w-full bg-white text-center bottom-0 border-l border-t border-b rounded-l-lg ${getBorderColor(tag)}`}
                    value={key}
                    onChange={(event) => {
                      setProcessed(false)
                      // setQueryKey(uuid())
                      if (onChange) onChange({ ...editItem, key: event.target.value })
                    }}
                  >
                    {options.sort((a, b) => {
                      return a.label.localeCompare(b.label)
                    }).map((option) => (
                      <option key={option.label} value={option.value}>
                        {titleCase(option.label)}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              {!useOption && (
                <div className="w-full">
                  <input
                    type="search"
                    list={`names${editItem.id}`}
                    className={`h-full text-gray-500 w-full p-1 rounded-l-lg text-center bottom-0 border-r border-l ${getBorderColor(tag)}`}
                    value={key}
                    onChange={(event) => {
                      if (onChange) onChange({ ...editItem, key: event.target.value })
                    }}
                  />
                  {loadNames()}
                </div>
              )}
              {type === LABEL_RULE_ELEMENT_TYPE.RULES && (
                <div className="w-full border-gray-500">
                  <select
                    className={`h-full w-full bg-white text-center bottom-0 border-t border-b border-r border-l ${getBorderColor(tag)}`}
                    value={(editItem as ruleTypeWithId).operand}
                    onChange={(event) => {
                      const { value } = event.target as HTMLSelectElement
                      if (onChange) {
                        onChange({
                          ...editItem,
                          operand: value as TLabelRuleOperator
                        })
                      }
                    }}
                  >
                    {isString(key) && operatorStringOptions.map((option) => (
                      <option key={option.label} value={option.value}>
                        {titleCase(option.label)}
                      </option>
                    ))}

                    {!isString(key) && operatorNumericOptions.map((option) => (
                      <option key={option.label} value={option.value}>
                        {titleCase(option.label)}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <input
                type="search"
                list={`values${editItem.id}`}
                className={`h-full text-gray-500 w-full p-1 rounded-r-lg ${tag ? 'border-t border-b' : ''} text-center bottom-0 border-r  ${getBorderColor(tag)}`}
                value={editItem.value}
                onChange={(event) => {
                  if (onChange) onChange({ ...editItem, value: event.target.value })
                }}
              />
              {loadValues()}
            </div>
            <div
              className={`flex items-center p-2 cursor-pointer hover:bg-red-500 hover:bg-opacity-10 ${getTextColor(tag)} hover:text-red-500 rounded-r-lg`}
              onClick={() => {
                if (onDelete) onDelete(editItem)
              }}
            >
              <HiOutlineTrash size={18} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ItemDataEditComponent
