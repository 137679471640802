import { v4 as uuid } from 'uuid'

function standardPreset() {
  let idArray = ['', '', '', '', '', '', '', '']
  idArray = idArray.map(() => uuid())
  return ([
    {
      tag: 'FinOpsUseCaseWidget-Anomalous daily spending R',
      state: {
        id: idArray[0],
        key: idArray[0],
        x: 0,
        y: 12,
        w: 6,
        h: 12,
        options: [
          {
            label: 'Delete widget'
          }
        ]
      }
    },
    {
      tag: 'FinOpsUseCaseWidget-Service category costs last month',
      state: {
        id: idArray[1],
        key: idArray[1],
        x: 8,
        y: 0,
        w: 4,
        h: 12,
        options: [
          {
            label: 'Delete widget'
          }
        ]
      }
    },
    {
      tag: 'FinOpsUseCaseWidget-Service category costs last 6 months',
      state: {
        id: idArray[2],
        key: idArray[2],
        x: 0,
        y: 0,
        w: 8,
        h: 12,
        options: [
          {
            label: 'Delete widget'
          }
        ]
      }
    },
    {
      tag: 'FinOpsUseCaseWidget-Budgets with billed costs',
      state: {
        id: idArray[3],
        key: idArray[3],
        x: 6,
        y: 36,
        w: 6,
        h: 12,
        options: [
          {
            label: 'Delete widget'
          }
        ]
      }
    },
    {
      tag: 'FinOpsUseCaseWidget-Cost per compute service',
      state: {
        id: idArray[4],
        key: idArray[4],
        x: 0,
        y: 24,
        w: 4,
        h: 12,
        options: [
          {
            label: 'Delete widget'
          }
        ]
      }
    },
    {
      tag: 'FinOpsUseCaseWidget-Service costs per region',
      state: {
        id: idArray[5],
        key: idArray[5],
        x: 6,
        y: 12,
        w: 6,
        h: 12,
        options: [
          {
            label: 'Delete widget'
          }
        ]
      }
    },
    {
      tag: 'predefineWidgetAccFalseWithBudgeting',
      state: {
        id: idArray[6],
        key: idArray[6],
        x: 0,
        y: 36,
        w: 6,
        h: 12,
        options: [
          {
            label: 'Delete widget'
          }
        ]
      }
    },
    {
      tag: 'predefineWidgetAccFalse',
      state: {
        id: idArray[7],
        key: idArray[7],
        x: 4,
        y: 24,
        w: 8,
        h: 12,
        options: [
          {
            label: 'Delete widget'
          }
        ]
      }
    }
  ]
  )
}

function finOpsPractitionerPreset() {
  let idArray = ['', '', '', '', '', '']
  idArray = idArray.map(() => uuid())
  return ([
    {
      tag: 'FinOpsUseCaseWidget-Service category costs last month',
      state: {
        id: idArray[0],
        key: idArray[0],
        x: 8,
        y: 0,
        w: 4,
        h: 12,
        options: [
          {
            label: 'Delete widget'
          }
        ]
      }
    },
    {
      tag: 'FinOpsUseCaseWidget-Service category costs last 6 months',
      state: {
        id: idArray[1],
        key: idArray[1],
        x: 0,
        y: 0,
        w: 8,
        h: 12,
        options: [
          {
            label: 'Delete widget'
          }
        ]
      }
    },
    {
      tag: 'predefineWidgetAccFalse',
      state: {
        id: idArray[2],
        key: idArray[2],
        x: 0,
        y: 25,
        w: 6,
        h: 12,
        options: [
          {
            label: 'Delete widget'
          }
        ]
      }
    },
    {
      tag: 'predefineWidgetAccFalseWithBudgeting',
      state: {
        id: idArray[3],
        key: idArray[3],
        x: 6,
        y: 25,
        w: 6,
        h: 12,
        options: [
          {
            label: 'Delete widget'
          }
        ]
      }
    },
    {
      tag: 'FinOpsUseCaseWidget-Budgets with billed costs',
      state: {
        id: idArray[4],
        key: idArray[4],
        x: 4,
        y: 17,
        w: 8,
        h: 12,
        options: [
          {
            label: 'Delete widget'
          }
        ]
      }
    },
    {
      tag: 'FinOpsUseCaseWidget-Service costs per region',
      state: {
        id: idArray[5],
        key: idArray[5],
        x: 0,
        y: 12,
        w: 4,
        h: 12,
        options: [
          {
            label: 'Delete widget'
          }
        ]
      }
    }
  ]
  )
}
function productPreset() {
  return standardPreset()
}
function engineeringPreset() {
  let idArray = ['', '', '', '', '']
  idArray = idArray.map(() => uuid())
  return ([
    {
      tag: 'predefineWidgetAccFalse',
      state: {
        id: idArray[0],
        key: idArray[0],
        x: 6,
        y: 29,
        w: 6,
        h: 15,
        options: [
          {
            label: 'Delete widget'
          }
        ]
      }
    },
    {
      tag: 'predefineWidgetAccFalseWithBudgeting',
      state: {
        id: idArray[1],
        key: idArray[1],
        x: 0,
        y: 29,
        w: 6,
        h: 15,
        options: [
          {
            label: 'Delete widget'
          }
        ]
      }
    },
    {
      tag: 'FinOpsUseCaseWidget-Sub account availability zone cost',
      state: {
        id: idArray[2],
        key: idArray[2],
        x: 0,
        y: 17,
        w: 8,
        h: 12,
        options: [
          {
            label: 'Delete widget'
          }
        ]
      }
    },
    {
      tag: 'FinOpsUseCaseWidget-Cost per compute service',
      state: {
        id: idArray[3],
        key: idArray[3],
        x: 8,
        y: 17,
        w: 4,
        h: 12,
        options: [
          {
            label: 'Delete widget'
          }
        ]
      }
    },
    {
      tag: 'projectTable',
      state: {
        id: idArray[4],
        key: idArray[4],
        x: 0,
        y: 0,
        w: 12,
        h: 17,
        options: [
          {
            label: 'Reload widget'
          },
          {
            label: 'Delete widget'
          }
        ],
        widgetTypeData: {
          aggregate: [],
          expanded: {},
          selector: 'fields'
        }
      }
    }
  ]
  )
}
function financePreset() {
  let idArray = ['', '', '', '', '']
  idArray = idArray.map(() => uuid())
  return ([
    {
      tag: 'FinOpsUseCaseWidget-Budgets with billed costs',
      state: {
        id: idArray[0],
        key: idArray[0],
        x: 0,
        y: 0,
        w: 8,
        h: 12,
        options: [
          {
            label: 'Delete widget'
          }
        ]
      }
    },
    {
      tag: 'predefineWidgetAccTrue',
      state: {
        id: idArray[1],
        key: idArray[1],
        x: 0,
        y: 12,
        w: 4,
        h: 12,
        options: [
          {
            label: 'Delete widget'
          }
        ]
      }
    },
    {
      tag: 'predefineWidgetAccFalse',
      state: {
        id: idArray[2],
        key: idArray[2],
        x: 0,
        y: 24,
        w: 12,
        h: 12,
        options: [
          {
            label: 'Delete widget'
          }
        ]
      }
    },
    {
      tag: 'predefineWidgetAccFalseWithBudgeting',
      state: {
        id: idArray[3],
        key: idArray[3],
        x: 4,
        y: 12,
        w: 8,
        h: 12,
        options: [
          {
            label: 'Delete widget'
          }
        ]
      }
    },
    {
      tag: 'FinOpsUseCaseWidget-Service costs per region',
      state: {
        id: idArray[4],
        key: idArray[4],
        x: 8,
        y: 0,
        w: 4,
        h: 12,
        options: [
          {
            label: 'Delete widget'
          }
        ]
      }
    }
  ]
  )
}
function procurementPreset() {
  return standardPreset()
}
export function getPreset(str: string) {
  if (str === '') {
    return standardPreset()
  } if (str === 'FinOps Practitioner') {
    return finOpsPractitionerPreset()
  } if (str === 'Product') {
    return productPreset()
  } if (str === 'Engineering') {
    return engineeringPreset()
  } if (str === 'Finance') {
    return financePreset()
  } if (str === 'Procurement') {
    return procurementPreset()
  }
  return standardPreset()
}
