import { Outlet, Route, Routes } from 'react-router-dom'
import { QueryClientProvider, QueryClient } from 'react-query';
import { CatalogContextProvider } from 'context/CatalogContext';
import { ProjectContextProvider } from 'context/ProjectContext';
import { ApplicationContextProvider } from 'context/ApplicationContext';
import { ExternalCostContextProvider } from 'context/ExternalCostContext';
import { ReportContextProvider } from 'context/ReportContext';
import { LabelRuleContextProvider } from 'context/LabelRuleContext';
// import { ResourceExplorer } from 'pages/resource-explorer';
import { ResourceExplorer } from 'pages/resource-explorer';
import { ConfigurationContextProvider } from '../context/ConfigurationContext';

import AuthFormLayout from '../layouts/AuthFormLayout';
import MainLayout from '../layouts/MainLayout';

import {
  Signin,
  Signup,
  ApplicationMutation,
  Catalog,
  CatalogApplication,
  Configuration,
  Home,
  // Reports,
  LabelRules,
  AdvancedReporting,
  // SingleProject,
  ExternalCosts,
} from '../pages'

import PrivateRoute from './privateRoute'

function AppRouter() {
  const queryClient = new QueryClient();
  return (
    <Routes>
      <Route
        element={(
          <AuthFormLayout>
            <Outlet />
          </AuthFormLayout>
        )}
      >
        <Route path="/login" element={<Signin />} />
        <Route path="/register" element={<Signup />} />
      </Route>
      <Route
        element={(
          <PrivateRoute>
            <ProjectContextProvider>
              <ReportContextProvider>
                <QueryClientProvider client={queryClient}>
                  <MainLayout>
                    <Outlet />
                  </MainLayout>
                </QueryClientProvider>
              </ReportContextProvider>
            </ProjectContextProvider>
          </PrivateRoute>
        )}
      >
        <Route
          caseSensitive
          path="/"
          element={(
            <ReportContextProvider>
              <QueryClientProvider client={queryClient}>
                <Home />
              </QueryClientProvider>
            </ReportContextProvider>
          )}
        />
        {/* <Route
          caseSensitive
          path="/reports"
          element={(
            <ReportContextProvider>
              <Reports />
            </ReportContextProvider>
          )}
        /> */}
        {/* <Route
          caseSensitive
          path="/projects/:projectId/savings"
          element={<SingleProject />}
        />
        <Route
          caseSensitive
          path="/singleproject"
          element={<SingleProject />}
        /> */}
        <Route
          caseSensitive
          path="/label-rules"
          element={(
            <LabelRuleContextProvider>
              <LabelRules />
            </LabelRuleContextProvider>
          )}
        />
        <Route
          caseSensitive
          path="/reports"
          element={(
            <ReportContextProvider>
              <QueryClientProvider client={queryClient}>
                <AdvancedReporting />
              </QueryClientProvider>
            </ReportContextProvider>
          )}
        />
        <Route
          caseSensitive
          path="/projects/:projectId/application-add"
          element={(
            <PrivateRoute>
              <ApplicationContextProvider>
                <ApplicationMutation />
              </ApplicationContextProvider>
            </PrivateRoute>
          )}
        />
        <Route
          caseSensitive
          path="/projects/:projectId/application-edit/:applicationId"
          element={(
            <PrivateRoute>
              <ApplicationContextProvider>
                <ApplicationMutation />
              </ApplicationContextProvider>
            </PrivateRoute>
          )}
        />
        <Route
          caseSensitive
          path="/catalog/:catalogId"
          element={(
            <CatalogContextProvider>
              <Catalog />
            </CatalogContextProvider>
          )}
        />
        <Route
          caseSensitive
          path="/catalog/:catalogId/packages/:packageName/:version"
          element={(
            <CatalogContextProvider>
              <CatalogApplication />
            </CatalogContextProvider>
          )}
        />
        <Route
          caseSensitive
          path="/external-costs"
          element={(
            <ExternalCostContextProvider>
              <ExternalCosts />
            </ExternalCostContextProvider>
          )}
        />
        <Route
          caseSensitive
          path="/Resource-explorer"
          element={(
            <ApplicationContextProvider>
              <ResourceExplorer />
            </ApplicationContextProvider>
          )}
        />
        <Route
          caseSensitive
          path="/configuration"
          element={(
            <ConfigurationContextProvider>
              <Configuration />
            </ConfigurationContextProvider>
          )}
        />
      </Route>
    </Routes>
  )
}

export default AppRouter
