import { MRT_ExpandedState } from 'mantine-react-table'
import { v4 as uuid } from 'uuid'

export type labeledDataWidget = {
  label: string,
  type: string,
  provider: string,
  optionsOpen: boolean,
  stepDuration: string,
  window: string
}
export type tableWidget = {
  aggregate: string[],
  expanded: MRT_ExpandedState,
  selector: string
}
export type optimizationWidget = {
  view: boolean,
  checked: { resourceName: string, timestamp: string }[]
}
export type widgetState = {
  id: string,
  key: string,
  comment: string,
  x: number,
  y: number,
  w: number,
  h: number,
  options: { label: string, func: () => void }[]
  widgetTypeData: labeledDataWidget | tableWidget | optimizationWidget | undefined
}
export type widgetdata = { tag: string, state: widgetState }

export class WidgetData {
  widgets: widgetdata[] = []

  widgetChanged = false

  eMail = ''

  initiallyLoaded = false

  // eslint-disable-next-line class-methods-use-this
  updateCallback = () => { console.log('not received yet') }

  setWidgets(newList: widgetdata[]) {
    this.widgets = newList
  }

  setWidgetChanged(bool: boolean) {
    this.widgetChanged = bool
  }

  setEMail(newstr: string) {
    this.eMail = newstr
  }

  setCallback(newCallBack: () => void) {
    this.updateCallback = newCallBack
  }

  setInitiallyLoaded() {
    this.initiallyLoaded = true
  }

  // ***************************************************************************
  // Update the localstorage
  // ***************************************************************************
  updateResource() {
    this.setWidgetChanged(true)
  }

  resourceUpdateFinished() {
    this.setWidgetChanged(false)
  }

  // ***************************************************************************
  // Add to the list.
  // ***************************************************************************
  addWidgetList(tag: string, state: widgetState) {
    this.widgets.push({ tag, state })
    this.updateCallback()
  }

  // ***************************************************************************
  // Delete from the list
  // ***************************************************************************
  deleteFromWidgetList(id: string) {
    const index = this.widgets.findIndex((el) => el.state.id === id)
    this.widgets.splice(index, 1)
    this.updateCallback()
  }

  // ***************************************************************************
  // Change position of 2 widgets.
  // ***************************************************************************
  swapWidgets(id: string, newIdx: number) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget !== undefined) {
      this.deleteFromWidgetList(id)
      this.widgets.splice(newIdx, 0, widget);
      this.updateResource()
    }
  }

  // ***************************************************************************
  // Create a new widgetstate object for a widget.
  // ***************************************************************************

  rowHasSpace() {
    let res = 0
    const maxY = Math.max(...this.widgets.map((el) => el.state.y))
    const newArray = this.widgets.filter((el) => el.state.y === maxY)
    const maxX = Math.max(...newArray.map((el) => el.state.x))
    const el = newArray.find((el) => el.state.x === maxX)
    if (el) {
      const width = el.state.x + el.state.w
      if (width + 6 <= 12) {
        res = width + 1
      }
    }
    return res
  }

  createWidget(tag: string) {
    const idKey = uuid()
    let widgetTypeData: labeledDataWidget | tableWidget | optimizationWidget | undefined;
    if (tag === 'labeledDataChart' || tag === 'cumulativeCost') {
      widgetTypeData = {
        label: '',
        type: '',
        provider: '',
        optionsOpen: true,
        // operator: 'NOTEQUALS',
        // apiFilterValue: '',
        window: 'lastmonth',
        stepDuration: '1d',
        // valueChosen: 'totalBilledCost',
        // data: [],
        // chartValue: 'serviceName'
      }
    } else if (tag === 'projectTable') {
      widgetTypeData = {
        aggregate: [],
        expanded: {},
        selector: 'fields'

      }
    } else if (tag === 'optimizationWidget') {
      widgetTypeData = {
        view: false,
        checked: []
      }
    }
    const newWidget: widgetState = {
      id: idKey,
      key: idKey,
      comment: '',
      x: this.rowHasSpace(),
      y: 999,
      w: 6,
      h: 15,
      options: [],
      widgetTypeData
    }
    // console.log(newWidget)
    this.addWidgetList(tag, newWidget)
  }

  // ***************************************************************************
  // Following are some functions that allow the mutation of the data inside
  // of a widgetstate, it just needs the id.
  // ***************************************************************************

  // ***************************************************************************
  // All functions for the widgets in general
  // ***************************************************************************

  editXYWH(id: string, x: number, y: number, w: number, h: number) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      widget.state.x = x
      widget.state.y = y
      widget.state.w = w
      widget.state.h = h
      this.updateResource()
    }
  }

  getComment(id: string) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      return widget.state.comment
    } return ''
  }

  getSummary(id: string) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget?.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
        const data: labeledDataWidget = widget.state.widgetTypeData as labeledDataWidget
        return `Summary: Label grouped on = ${data.label},  Provider Chosen = ${data.provider}, During time window = ${data.window}`
      }
      if (widget?.tag === 'projectTable') {
        const data: tableWidget = widget.state.widgetTypeData as tableWidget
        let aggregateString = ''
        data.aggregate.forEach((el, idx) => {
          if (idx === data.aggregate.length - 1) {
            aggregateString = `${aggregateString} ${el}`
          } else {
            aggregateString = `${aggregateString} ${el},`
          }
        })
        return `Summary: Selector = ${data.selector}, Grouped on [${aggregateString}]`
      }
      return ''
    }
    return ''
  }

  editComment(id: string, comment: string) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      widget.state.comment = comment
      this.updateResource()
    }
  }

  getOptions(id: string) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      return widget.state.options
    } return []
  }

  editOptions(id: string, label: string, func: () => void) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      widget.state.options.push({ label, func })
    }
  }

  resetOptions(id: string) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      widget.state.options = []
    }
  }

  // ***************************************************************************
  // All functions for the charts
  // ***************************************************************************
  editLabel(id: string, label: string) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget?.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
        const data: labeledDataWidget = widget.state.widgetTypeData as labeledDataWidget
        data.label = label
      }
    }
    this.updateCallback()
  }

  getLabel(id: string) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
        const data: labeledDataWidget = widget.state.widgetTypeData as labeledDataWidget
        if (data.label) return data.label
        data.label = ''
        return data.label
      } return ''
    } return ''
  }

  getType(id: string) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
        const data: labeledDataWidget = widget.state.widgetTypeData as labeledDataWidget
        if (data.type) return data.type
        data.type = ''
        return data.type
      } return ''
    } return ''
  }

  setType(id: string, type: string) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
        const data: labeledDataWidget = widget.state.widgetTypeData as labeledDataWidget
        data.type = type
      }
    }
    this.updateCallback()
  }

  getProvider(id: string) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
        const data: labeledDataWidget = widget.state.widgetTypeData as labeledDataWidget
        if (data.provider) return data.provider
        data.provider = ''
        return data.provider
      } return ''
    } return ''
  }

  setProvider(id: string, provider: string) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
        const data: labeledDataWidget = widget.state.widgetTypeData as labeledDataWidget
        data.provider = provider
      }
    }
    this.updateCallback()
  }

  getOptionsOpen(id: string): boolean {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
        const data: labeledDataWidget = widget.state.widgetTypeData as labeledDataWidget
        if (data.optionsOpen !== undefined) return data.optionsOpen
        data.optionsOpen = true
        return data.optionsOpen
      } return true
    } return true
  }

  setOptionsOpen(id: string, opened: boolean) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
        const data: labeledDataWidget = widget.state.widgetTypeData as labeledDataWidget
        data.optionsOpen = opened
      }
    }
    this.updateCallback()
  }

  editWindow(id: string, window: string) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget?.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
        const data: labeledDataWidget = widget.state.widgetTypeData as labeledDataWidget
        data.window = window
      }
    }
    this.updateCallback()
  }

  getWindow(id: string): string {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
        const data: labeledDataWidget = widget.state.widgetTypeData as labeledDataWidget
        if (data.window) return data.window
        data.window = ''
        return data.window
      } return ''
    } return ''
  }

  editStepDuration(id: string, stepDuration: string) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget?.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
        const data: labeledDataWidget = widget.state.widgetTypeData as labeledDataWidget
        data.stepDuration = stepDuration
      }
    }
    this.updateCallback()
  }

  getStepDuration(id: string) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget.tag === 'labeledDataChart' || widget?.tag === 'cumulativeCost') {
        const data: labeledDataWidget = widget.state.widgetTypeData as labeledDataWidget
        if (data.stepDuration) return data.stepDuration
        data.stepDuration = ''
        return data.stepDuration
      } return ''
    } return ''
  }

  // ***************************************************************************
  // All functions for the Table widgets
  // ***************************************************************************

  getTableDataAggregate(id: string): string[] {
    const widget = this.widgets.find((el) => el.state.id === id)

    if (widget) {
      if (widget.tag === 'projectTable') {
        const data: tableWidget = widget.state.widgetTypeData as tableWidget
        if (data.aggregate) return data.aggregate
        data.aggregate = []
        return data.aggregate
      } return []
    } return []
  }

  getTableDataExpanded(id: string): MRT_ExpandedState {
    const widget = this.widgets.find((el) => el.state.id === id)

    if (widget) {
      if (widget.tag === 'projectTable') {
        const data: tableWidget = widget.state.widgetTypeData as tableWidget
        if (data.expanded) return data.expanded
        data.expanded = {}
        return data.expanded
      } return {}
    } return {}
  }

  setTableDataExpanded(id: string, value: MRT_ExpandedState) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget.tag === 'projectTable') {
        const data: tableWidget = widget.state.widgetTypeData as tableWidget
        data.expanded = value
      }
    }
    this.updateCallback()
  }

  getTableDataSelector(id: string): string {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget.tag === 'projectTable') {
        const data: tableWidget = widget.state.widgetTypeData as tableWidget
        if (data.selector) return data.selector
        data.selector = 'fields'
        return data.selector
      } return 'fields'
    } return 'fields'
  }

  setTableDataAfterNewCall(id: string, aggregate: string[], selector: string) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget.tag === 'projectTable') {
        const data: tableWidget = widget.state.widgetTypeData as tableWidget
        data.aggregate = aggregate
        data.selector = selector
      }
    }
    this.updateCallback()
  }

  getOptimizationWidgetView(id: string): boolean {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget.tag === 'optimizationWidget') {
        const data: optimizationWidget = widget.state.widgetTypeData as optimizationWidget
        if (data.view) return data.view
        data.view = false
        return data.view
      } return false
    } return false
  }

  changeOptimizationWidgetView(id: string) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget.tag === 'optimizationWidget') {
        const data: optimizationWidget = widget.state.widgetTypeData as optimizationWidget
        data.view = !data.view
      }
    }
    this.updateCallback()
  }

  getOptimizationWidgetChecked(id: string): { resourceName: string, timestamp: string }[] {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget.tag === 'optimizationWidget') {
        const data: optimizationWidget = widget.state.widgetTypeData as optimizationWidget
        if (data.checked) return data.checked
        data.checked = []
        return data.checked
      } return []
    } return []
  }

  addOptimizationWidgetChecked(id: string, newResourceName: string, newTimestamp: string) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget.tag === 'optimizationWidget') {
        const data: optimizationWidget = widget.state.widgetTypeData as optimizationWidget
        data.checked.push({ resourceName: newResourceName, timestamp: newTimestamp })
      }
    }
    this.updateCallback()
  }

  deleteOptimizationWidgetChecked(id: string, oldResourceName: string) {
    const widget = this.widgets.find((el) => el.state.id === id)
    if (widget) {
      if (widget.tag === 'optimizationWidget') {
        const data: optimizationWidget = widget.state.widgetTypeData as optimizationWidget
        data.checked = data.checked.filter((el) => el.resourceName !== oldResourceName)
      }
    }
    this.updateCallback()
  }
}
