import { MdDragIndicator } from 'react-icons/md';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DraggableData } from './propsInterface';

export function Draggable(props: DraggableData) {
  const { id, children, depth } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({
    id
  })
  const style = {
    width: '100%',
    display: 'inline-flex',
    transform: CSS.Transform.toString(transform),
    transition,
    paddingLeft: `${40 * depth}px`

  };
  return (
    <div ref={setNodeRef} style={style} className="pb-2 w-full">
      <div style={{ display: 'inline-flex' }} className="bg-gray-300 border border-gray-500 rounded-lg p-0 w-full">
        <button type="button" {...listeners} {...attributes}>
          <MdDragIndicator size={20} />
        </button>
        {children}
      </div>
    </div>
  );
}
