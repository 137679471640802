import { SetStateAction, useEffect, useState } from 'react'
import Cropper, { Area } from 'react-easy-crop'
import './index.scss'
import getCroppedImg from './cropImage'

interface FotoEditorProps {
  foto: string
  fotoBackup: string
  setProfileImage: (img: string | undefined) => void
  setCropping: (bool: boolean) => void
}

export function FotoEditor({ foto, fotoBackup, setProfileImage, setCropping }: FotoEditorProps) {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>()
  const [, setCroppedArea] = useState<Area>()
  const [croppedImage, setCroppedImage] = useState<string>()

  const onCropComplete = (
    croppedArea: SetStateAction<Area | undefined>,
    croppedAreaPixels: SetStateAction<Area | undefined>
  ) => {
    setCroppedArea(croppedArea)
    setCroppedAreaPixels(croppedAreaPixels)
  }
  const showCroppedImage = async () => {
    let cAP = croppedAreaPixels
    if (!cAP) {
      cAP = {
        x: 0, y: 0, width: 0, height: 0
      }
    }
    try {
      const croppedImage = await getCroppedImg(
        foto,
        cAP,
        0
      )
      // console.log('done', { croppedImage })
      setCroppedImage(croppedImage as string)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (croppedImage) {
      setProfileImage(croppedImage)
      // console.log(croppedImage.length)
      setCropping(false)
    }
  }, [croppedImage])

  return (
    <div className="fotoEditor bg-white">
      <div className="crop-container">
        <Cropper
          image={foto}
          crop={crop}
          zoom={zoom}
          aspect={1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          cropShape="round"
        />
      </div>
      <div className="controls justify-center">
        <button
          type="button"
          className="bg-[#BB0A1E] hover:bg-[#c83a4a] text-white border-2 rounded-lg border-[#BB0A1E] w-16 p-1 m-1"
          onClick={() => {
            setProfileImage(fotoBackup)
            setCropping(false)
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          className="bg-[#668957] border-[#668957] hover:bg-[#93ac89] hover:border-[#93ac89] text-white border-2 rounded-lg w-16 p-1 m-1"
          onClick={() => {
            // console.log('done')
            showCroppedImage()
          }}
        >
          Save
        </button>
      </div>
    </div>
  )
}
