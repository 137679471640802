/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { toast, Bounce } from 'react-toastify'
import { BarChart, LineChart } from '@mantine/charts'
import { Chip, Text } from '@mantine/core'
import { graphQLAxios } from 'utils/axios'
import useAuthentication from 'hooks/useAuthentication'
import { getLastMonth } from 'features/projects/project-datepicker/helpFunction'
import { GET_CHART_COLORS } from 'constants/color.constants'
// import { CHART_COLORS } from 'constants/color.constants'
import { FaCheck, FaX } from 'react-icons/fa6'
import { projectAndTheirApps } from 'features/projects/project-widget/widgetHelpFunctions'
import { anomalousDailySpendingRSNTypeApiCall, anomalousDailySpendingRTypeApiCall, anomalousDailySpendingTypeApiCall } from '../graphQlApiCalls'
import { ChartTooltip } from './ServiceCostsRegion'
import { parseData } from '../../project-labeled-data-widget/chartHelperFunctions'

type anomalousDailySpendingRSNType = {
  dailyEffectiveCost: number
  day: number
  provider: string
  region?: string
  serviceName?: string
  subAccountId: string
}

type chartDataType = {
  [key: string]: string | number
  day: number,
}
export const cloudColors = [
  { provider: 'AWS', color: '#ff9c08' },
  { provider: 'Azure', color: '#3ab7ed' },
  { provider: 'GCP', color: '#3cac57' },
  { provider: 'opencost', color: '#aabab4' }
]
export function getProviderColor(str: string) {
  const idx = cloudColors.findIndex((el) => el.provider === str)
  if (idx !== -1) return cloudColors[idx].color
  return '#668957'
}

type chartDataColors = { name: string, color: string, stackId?: string }

interface AnomalousDailySpendingRSNProps {
  id: string,
  tag: string
  height: number
}
export function AnomalousDailySpendingRSN({ id, tag, height }: AnomalousDailySpendingRSNProps) {
  const timeWindow = getLastMonth()
  const [dataLoaded, setDataLoaded] = useState<boolean>(false)
  const [queryKey] = useState<string>(`initial-${id}`)
  const [data, setData] = useState<anomalousDailySpendingRSNType[]>([])
  const [chartData, setChartData] = useState<chartDataType[]>([])
  const [awsChartData, setAwsChartData] = useState<projectAndTheirApps[]>([])
  const [azureChartData, setAzureChartData] = useState<projectAndTheirApps[]>([])
  const [gcpChartData, setGcpChartData] = useState<projectAndTheirApps[]>([])
  const [rsnChartData, setRsnChartData] = useState<projectAndTheirApps[]>([])
  const [chartDataColors, setChartDataColors] = useState<chartDataColors[]>([])

  const { currency } = useAuthentication()
  const [savedDelimiter, setSavedDelimiter] = useState<string>('en-US');
  const [savedGrouping, setSavedGrouping] = useState<string>('Yes');

  window.addEventListener('storage', () => {
    if (sessionStorage.getItem('delimiter') === ',') {
      setSavedDelimiter('de-DE')
    } else {
      setSavedDelimiter('en-US')
    }
    if (sessionStorage.getItem('grouping') !== null) setSavedGrouping(sessionStorage.getItem('grouping')!)
  })

  useEffect(() => {
    if (sessionStorage.getItem('delimiter') === ',') {
      setSavedDelimiter('de-DE')
    } else {
      setSavedDelimiter('en-US')
    }
    if (sessionStorage.getItem('grouping') !== null) setSavedGrouping(sessionStorage.getItem('grouping')!)
  }, [savedDelimiter, savedGrouping, sessionStorage.getItem('delimiter'), sessionStorage.getItem('grouping')])

  const { error } = useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      try {
        if (!dataLoaded) {
          let query =
            anomalousDailySpendingTypeApiCall(timeWindow[0].toISOString().replace('T', ' ').replace('Z', ''), timeWindow[1].toISOString().replace('T', ' ').replace('Z', ''))
          if (tag === 'R') query = anomalousDailySpendingRTypeApiCall(timeWindow[0].toISOString().replace('T', ' ').replace('Z', ''), timeWindow[1].toISOString().replace('T', ' ').replace('Z', ''))
          if (tag === 'RSN') query = anomalousDailySpendingRSNTypeApiCall(timeWindow[0].toISOString().replace('T', ' ').replace('Z', ''), timeWindow[1].toISOString().replace('T', ' ').replace('Z', ''))
          await graphQLAxios
            .post('', { query })
            .then((res) => {
              if (tag === '') setData(res.data.data.anomalousDailySpending)
              if (tag === 'R') setData(res.data.data.anomalousDailySpendingR)
              if (tag === 'RSN') setData(res.data.data.anomalousDailySpendingRSN)
              setDataLoaded(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                toast.error('No labels have been created.', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  transition: Bounce,
                })
              }
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })

  const [providers, setProviders] = useState<string[]>([])
  const [providersChecked, setProvidersChecked] = useState<string[]>([])

  function getTagValue(obj: anomalousDailySpendingRSNType) {
    if (tag.length === 0) {
      return obj.provider
    }
    if (tag === 'R') {
      return obj.region!
    }
    if (tag === 'RSN') {
      return obj.serviceName!
    }
    return ''
  }

  function getRsnData(data: anomalousDailySpendingRSNType[]) {
    const newAwsData: projectAndTheirApps[] = []
    const newAzureData: projectAndTheirApps[] = []
    const newGcpData: projectAndTheirApps[] = []
    data.forEach((el) => {
      if (el.provider === 'AWS') {
        const idx = newAwsData.findIndex((obj) => obj.label === el.day.toString())
        // const include = providersChecked.findIndex((prov) => prov === el.provider) !== -1
        if (idx === -1) {
          newAwsData.push({
            label: el.day.toString(),
            [getTagValue(el)]: el.dailyEffectiveCost <= 0 ? 0 : el.dailyEffectiveCost
          })
        } else {
          const newObj = newAwsData[idx]
          newObj[getTagValue(el)] = el.dailyEffectiveCost <= 0 ? 0 : el.dailyEffectiveCost
          newAwsData[idx] = newObj
        }
      } else if (el.provider === 'Azure') {
        const idx = newAzureData.findIndex((obj) => obj.label === el.day.toString())
        // const include = providersChecked.findIndex((prov) => prov === el.provider) !== -1
        if (idx === -1) {
          newAzureData.push({
            label: el.day.toString(),
            [getTagValue(el)]: el.dailyEffectiveCost <= 0 ? 0 : el.dailyEffectiveCost
          })
        } else {
          const newObj = newAzureData[idx]
          newObj[getTagValue(el)] = el.dailyEffectiveCost <= 0 ? 0 : el.dailyEffectiveCost
          newAzureData[idx] = newObj
        }
      } else if (el.provider === 'GCP') {
        const idx = newGcpData.findIndex((obj) => obj.label === el.day.toString())
        // const include = providersChecked.findIndex((prov) => prov === el.provider) !== -1
        if (idx === -1) {
          newGcpData.push({
            label: el.day.toString(),
            [getTagValue(el)]: el.dailyEffectiveCost <= 0 ? 0 : el.dailyEffectiveCost
          })
        } else {
          const newObj = newGcpData[idx]
          newObj[getTagValue(el)] = el.dailyEffectiveCost <= 0 ? 0 : el.dailyEffectiveCost
          newGcpData[idx] = newObj
        }
      }
    })
    const parsedAwsData =
      parseData(newAwsData.sort((a, b) => Number(a.label) - Number(b.label)))
    const parsedAzureData =
      parseData(newAzureData.sort((a, b) => Number(a.label) - Number(b.label)))
    const parsedGcpData =
      parseData(newGcpData.sort((a, b) => Number(a.label) - Number(b.label)))
    setAwsChartData(parsedAwsData)
    setAzureChartData(parsedAzureData)
    setGcpChartData(parsedGcpData)
    return parsedAwsData.concat(parsedAzureData.concat(parsedGcpData))
  }
  useEffect(() => {
    const newProviders: string[] = []
    data.forEach((el) => {
      const fIndex = newProviders.findIndex((provider) => provider === el.provider)
      if (fIndex === -1) newProviders.push(el.provider)
    })
    setProviders(newProviders)
    setProvidersChecked(newProviders)
    if (tag === 'RSN' && data.length !== 0 && false) {
      const newRsnDataColors: chartDataColors[] = []
      getRsnData(data).forEach((el) => {
        const properties = Object.getOwnPropertyNames(el).filter((el) => el !== 'label')
        properties.forEach((property) => {
          const findIndex = newRsnDataColors.findIndex((el) => el.name === property)
          if (findIndex === -1) {
            // const find = providerTest.find((pro) => pro.name === property)
            // const provider = find?.provider || ''
            newRsnDataColors.push({
              name: property,
              color: GET_CHART_COLORS(newRsnDataColors.length),
              // stackId: provider
            })
          }
        })
      })
      setChartDataColors(newRsnDataColors)
    }
  }, [data])

  useEffect(() => {
    const newData: chartDataType[] = []
    const providerTest: { provider: string, name: string }[] = []
    const newRsnData: projectAndTheirApps[] = []
    const newDataColors: chartDataColors[] = []
    if (tag !== 'RSN' || true) {
      data.forEach((el) => {
        const idx = tag === 'RSN' ? newRsnData.findIndex((obj) => obj.label === el.day.toString()) : newData.findIndex((obj) => obj.day === el.day)
        const idxColors = tag === 'RSN' ? 1 : newDataColors.findIndex((obj) => obj.name === getTagValue(el))
        const include = providersChecked.findIndex((prov) => prov === el.provider) !== -1
        if (idx === -1 && include) {
          if (tag === 'RSN') {
            providerTest.push({
              provider: el.provider,
              name: getTagValue(el)
            })
            newRsnData.push({
              label: el.day.toString(),
              [getTagValue(el)]: el.dailyEffectiveCost
            })
          } else {
            newData.push({
              day: el.day,
              [getTagValue(el)]: el.dailyEffectiveCost
            })
          }
        } else if (include) {
          if (tag === 'RSN') {
            providerTest.push({
              provider: el.provider,
              name: getTagValue(el)
            })
            const newObj = newRsnData[idx]
            if (newRsnData[idx][getTagValue(el)]) {
              newObj[getTagValue(el)] =
                el.dailyEffectiveCost + Number(newRsnData[idx][getTagValue(el)])
            } else {
              newObj[getTagValue(el)] = el.dailyEffectiveCost
            }
            newRsnData[idx] = newObj
          } else {
            const newObj = newData[idx]
            if (newData[idx][getTagValue(el)]) {
              newObj[getTagValue(el)] =
                el.dailyEffectiveCost + Number(newData[idx][getTagValue(el)])
            } else {
              newObj[getTagValue(el)] = el.dailyEffectiveCost
            }
            newData[idx] = newObj
          }
        }
        if (idxColors === -1) {
          let color = GET_CHART_COLORS(newDataColors.length)
          if (tag.length === 0) color = getProviderColor(getTagValue(el))
          newDataColors.push({
            name: getTagValue(el),
            color
          })
        }
      })
      const parsedRsnData = parseData(newRsnData.sort((a, b) => Number(a.label) - Number(b.label)))
      console.log(parsedRsnData)
      setChartData(newData.sort((a, b) => a.day - b.day))

      if (tag === 'RSN' && data.length !== 0) {
        const newRsnDataColors: chartDataColors[] = []
        parsedRsnData.forEach((el) => {
          const properties = Object.getOwnPropertyNames(el).filter((el) => el !== 'label')
          properties.forEach((property) => {
            const findIndex = newRsnDataColors.findIndex((el) => el.name === property)
            if (findIndex === -1) {
              const find = providerTest.find((pro) => pro.name === property)
              const provider = find?.provider || ''
              newRsnDataColors.push({
                name: property,
                color: GET_CHART_COLORS(newRsnDataColors.length),
                stackId: provider
              })
            }
          })
        })
        setChartDataColors(newRsnDataColors)
      } else {
        setChartDataColors(newDataColors)
      }
      setRsnChartData(parsedRsnData)
    }
  }, [providersChecked])

  if (error) return <div>An error has occurred</div>
  return (
    <div className="h-full">
      <div className="flex w-full">
        <span className="text-gray-500 pr-2 text-sm p-1">Included providers: </span>
        {providers.map((el) => {
          const found = providersChecked.findIndex((provider) => el === provider)
          return (
            <Chip
              className="p-1"
              color={found !== -1 ? getProviderColor(el) : '#868e96'}
              icon={found !== -1 ? <FaCheck /> : <FaX />}
              checked
              onClick={() => {
                if (found !== -1) {
                  const newProvidersChecked =
                    providersChecked.filter((provider) => provider !== el)
                  setProvidersChecked(newProvidersChecked)
                } else {
                  const newProvidersChecked = providersChecked.concat(el)
                  setProvidersChecked(newProvidersChecked)
                }
              }}
            >
              {el}
            </Chip>
          )
        })}
      </div>
      <div>
        {tag !== 'RSN' &&
          (
            <LineChart
              h={height}
              data={chartData}
              className="h-full px-2"
              dataKey="day"
              xAxisLabel="Days of a month"
              series={chartDataColors}
              connectNulls
              valueFormatter={(value) => {
                return new Intl.NumberFormat(savedDelimiter, { useGrouping: savedGrouping === 'Yes' }).format(Number(value.toFixed(2)))
              }}
              tooltipProps={{
                // eslint-disable-next-line react/no-unstable-nested-components
                content: ({ label, payload }) => {
                  const newDate = new Date()
                  newDate.setMonth(newDate.getMonth() - 1)
                  newDate.setDate(label)
                  return (
                    <ChartTooltip
                      label={newDate.toLocaleDateString()}
                      payload={payload}
                      currency={currency}
                    />
                  )
                }
              }}
              unit={currency.symbol}
            />
          )}
        {tag === 'RSN' && (
          <>

            {true && (
              <BarChart
                h={height}
                data={rsnChartData}
                className="h-full px-2"
                dataKey="label"
                xAxisLabel="Days of a month"
                type="stacked"
                withLegend
                legendProps={{ verticalAlign: 'bottom', height: 50 }}
                series={chartDataColors}
                valueFormatter={(value) => {
                  return new Intl.NumberFormat(savedDelimiter, {
                    useGrouping: savedGrouping === 'Yes'
                  }).format(Number(value.toFixed(2)))
                }}
                tooltipProps={{
                  // eslint-disable-next-line react/no-unstable-nested-components
                  content: ({ label, payload }) => {
                    const newDate = new Date()
                    newDate.setMonth(newDate.getMonth() - 1)
                    newDate.setDate(label)
                    return (
                      <ChartTooltip
                        label={newDate.toLocaleDateString()}
                        payload={payload}
                        currency={currency}
                      />
                    )
                  }
                }}
                unit={currency.symbol}
              />
            )}
            {providersChecked.find((el) => el === 'AWS') && false && (
              <div>
                <Text mb="md" pl="md">
                  AWS usage:
                </Text>

                <BarChart
                  h={180}
                  data={awsChartData}
                  dataKey="label"
                  type="stacked"
                  series={chartDataColors}
                  barChartProps={{ syncId: 'tech' }}
                  tooltipProps={{
                    // eslint-disable-next-line react/no-unstable-nested-components
                    content: ({ label, payload }) => {
                      const newDate = new Date()
                      newDate.setMonth(newDate.getMonth() - 1)
                      newDate.setDate(label)
                      return (
                        <ChartTooltip
                          label={newDate.toLocaleDateString()}
                          payload={payload}
                          currency={currency}
                        />
                      )
                    }
                  }}
                />
              </div>
            )}

            {providersChecked.find((el) => el === 'Azure') && false && (
              <div>
                <Text mb="md" pl="md" mt="xl">
                  Azure usage:
                </Text>

                <BarChart
                  h={180}
                  data={azureChartData}
                  dataKey="label"
                  type="stacked"
                  barChartProps={{
                    syncId: 'tech',
                    // barGap: 0,
                    // barCategoryGap: 5,
                    // barSize: 20
                  }}
                  series={chartDataColors}
                  tooltipProps={{
                    // eslint-disable-next-line react/no-unstable-nested-components
                    content: ({ label, payload }) => {
                      return <ChartTooltip label={label} payload={payload} currency={currency} />
                    }
                  }}
                />
              </div>
            )}
            {providersChecked.find((el) => el === 'GCP') && false && (
              <div>
                <Text mb="md" pl="md" mt="xl">
                  GCP usage:
                </Text>

                <BarChart
                  h={180}
                  data={gcpChartData}
                  dataKey="label"
                  type="stacked"
                  barChartProps={{ syncId: 'tech' }}
                  series={chartDataColors}
                  tooltipProps={{
                    // eslint-disable-next-line react/no-unstable-nested-components
                    content: ({ label, payload }) => {
                      return <ChartTooltip label={label} payload={payload} currency={currency} />
                    }
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}
