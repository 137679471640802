/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { getLast6MonthsWithCur, getLastMonthForBillingPeriod } from 'features/projects/project-datepicker/helpFunction'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { toast, Bounce } from 'react-toastify'
import { graphQLAxios } from 'utils'
import { Chip } from '@mantine/core'
import { BarChart, PieChart } from '@mantine/charts'
import { useResizeObserver } from '@mantine/hooks'
// import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts'
import { TPieChartData } from 'services/chart.interface'
import { getSmallestVal, parseData, parsePieData, RenderLegend } from 'features/projects/project-labeled-data-widget/chartHelperFunctions'
import useAuthentication from 'hooks/useAuthentication'
import { FaCheck, FaX } from 'react-icons/fa6'
import { GET_CHART_COLORS } from 'constants/color.constants'
import { projectAndTheirApps } from 'features/projects/project-widget/widgetHelpFunctions'
import { serviceCategoryCostsApiCall } from '../graphQlApiCalls'
import { drawLineIf, drawNumberIf } from '..'
import { ChartTooltip } from './ServiceCostsRegion'
import { getProviderColor } from './AnomalousDailySpendingRSN'

type chartDataColors = { name: string, color: string }
export const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
export const monthNamesShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

type serviceCategoryCostsType = {
  billingCurrency: string
  month: number
  provider: string
  serviceCategory: string
  totalBilledCost: number
}

interface ServiceCategoryCostsProp {
  id: string,
  tag: string,
  height: number,
}
export function ServiceCategoryCosts({ id, tag, height }: ServiceCategoryCostsProp) {
  const { currency } = useAuthentication()
  const timeWindow = tag === 'lastmonth' ? getLastMonthForBillingPeriod() : getLast6MonthsWithCur()
  const [dataLoaded, setDataLoaded] = useState<boolean>(false)
  const [queryKey] = useState<string>(`initial-${id}`)
  const [data, setData] = useState<serviceCategoryCostsType[]>([])
  const [pieChartData, setPieChartData] = useState<TPieChartData[]>([])
  const [providers, setProviders] = useState<string[]>([])
  const [providersChecked, setProvidersChecked] = useState<string[]>([])
  const [lineChartData, setLineChartData] = useState<projectAndTheirApps[]>([])
  const [linechartDataColors, setLineChartDataColors] = useState<chartDataColors[]>([])
  // const [chartDataColors, setChartDataColors] = useState<chartDataColors[]>([])
  const [totalCost, setTotalCost] = useState<number>(0)

  const [savedDelimiter, setSavedDelimiter] = useState<string>('en-US');
  const [savedGrouping, setSavedGrouping] = useState<string>('Yes');

  window.addEventListener('storage', () => {
    if (sessionStorage.getItem('delimiter') === ',') {
      setSavedDelimiter('de-DE')
    } else {
      setSavedDelimiter('en-US')
    }
    if (sessionStorage.getItem('grouping') !== null) setSavedGrouping(sessionStorage.getItem('grouping')!)
  })

  useEffect(() => {
    if (sessionStorage.getItem('delimiter') === ',') {
      setSavedDelimiter('de-DE')
    } else {
      setSavedDelimiter('en-US')
    }
    if (sessionStorage.getItem('grouping') !== null) setSavedGrouping(sessionStorage.getItem('grouping')!)
  }, [savedDelimiter, savedGrouping, sessionStorage.getItem('delimiter'), sessionStorage.getItem('grouping')])

  const { error } = useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      try {
        if (!dataLoaded) {
          const query =
            serviceCategoryCostsApiCall(timeWindow[0].toISOString().replace('T', ' ').replace('Z', ''), timeWindow[1].toISOString().replace('T', ' ').replace('Z', ''))
          await graphQLAxios
            .post('', { query })
            .then((res) => {
              setData(res.data.data.serviceCategoryCosts)
              setDataLoaded(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                toast.error('No labels have been created.', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  transition: Bounce,
                })
              }
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })
  useEffect(() => {
    const newProviders: string[] = []
    data.forEach((el) => {
      const fIndex = newProviders.findIndex((provider) => provider === el.provider)
      if (fIndex === -1) newProviders.push(el.provider)
    })
    setProviders(newProviders)
    setProvidersChecked(newProviders)
  }, [data])

  useEffect(() => {
    if (tag === 'lastmonth') {
      let newTotalCost = 0
      const newChartData: TPieChartData[] = []
      data.forEach((el) => {
        const fIndex = providersChecked.findIndex((provider) => provider === el.provider)
        if (fIndex !== -1) {
          newTotalCost += el.totalBilledCost
          newChartData.push({
            name: `${el.provider}: ${el.serviceCategory}`,
            value: Number(el.totalBilledCost.toFixed(2)),
            color: ''
          })
        }
      })
      setTotalCost(newTotalCost)
      setPieChartData(newChartData)
    } else {
      const newData: projectAndTheirApps[] = []
      data.forEach((el) => {
        const idx = newData.findIndex((obj) => obj.label === el.month.toString())
        if (providersChecked.findIndex((provider) => provider === el.provider) !== -1) {
          if (idx === -1) {
            newData.push({
              label: el.month.toString(),
              [`${el.provider} ${el.serviceCategory}`]: el.totalBilledCost <= 0 ? 0 : el.totalBilledCost
            })
          } else {
            const newObj = newData[idx]
            newObj[`${el.provider} ${el.serviceCategory}`] = el.totalBilledCost <= 0 ? 0 : el.totalBilledCost
            newData[idx] = newObj
          }
          // if (idxColors === -1) {
          //   newDataColors.push({
          //     name: `${el.provider} ${el.serviceCategory}`,
          //     color: GET_CHART_COLORS(newDataColors.length)
          //   })
          // }
        }
      })
      const parsedData = parseData(newData)
      const newDataColors: chartDataColors[] = []
      setLineChartData(parsedData.map((el) => {
        el.label = monthNames[Number(el.label) - 1]
        return el
      }))
      parsedData.forEach((el) => {
        const properties = Object.getOwnPropertyNames(el).filter((el) => el !== 'label')
        properties.forEach((property) => {
          const findIndex = newDataColors.findIndex((el) => el.name === property)
          if (findIndex === -1) {
            newDataColors.push({
              name: property,
              color: GET_CHART_COLORS(newDataColors.length)
            })
          }
        })
      })
      setLineChartDataColors(newDataColors)
    }
  }, [providersChecked])

  const [ref, rect] = useResizeObserver();

  if (error) return <div>An error has occurred</div>
  return (
    <div className="px-2 w-full h-full">
      <div className="flex p-1 pb-2">
        <span className="text-gray-500 pr-2 text-sm p-1">Included providers: </span>
        {providers.map((el) => {
          const found = providersChecked.findIndex((provider) => el === provider)
          return (
            <Chip
              className="p-1"
              color={found !== -1 ? getProviderColor(el) : '#868e96'}
              icon={found !== -1 ? <FaCheck /> : <FaX />}
              checked
              onClick={() => {
                if (found !== -1) {
                  const newProvidersChecked =
                    providersChecked.filter((provider) => provider !== el)
                  setProvidersChecked(newProvidersChecked)
                } else {
                  const newProvidersChecked = providersChecked.concat(el)
                  setProvidersChecked(newProvidersChecked)
                }
              }}
            >
              {el}
            </Chip>
          )
        })}
      </div>
      {tag === 'lastmonth' && (
        <div className="flex h-5/6 w-full">
          <RenderLegend
            data={parsePieData(pieChartData)}
            parentKey="fiojeffhuiiosehfghuiosgfhifhueio"
            currency={currency}
            delimiter={savedDelimiter}
            grouping={savedGrouping}
          />
          <div className="w-2/3">
            <div className="h-5/6 w-5/6 ml-[130px]" ref={ref}>
              <PieChart
                size={getSmallestVal(rect)}
                withLabelsLine
                labelsPosition="outside"
                labelsType="value"
                withLabels
                opacity={60}
                strokeWidth={1}
                data={parsePieData(pieChartData)}
                pieProps={{
                  label: (val) => drawNumberIf(
                    val,
                    totalCost,
                    currency,
                    savedDelimiter,
                    savedGrouping
                  ),
                  labelLine: (val) => drawLineIf(val, totalCost),
                  fillOpacity: '80%',
                  outerRadius: '60%'
                }}
              />
            </div>
          </div>
        </div>
      )}
      {tag === 'last6months' && (
        <div className="w-full">
          <BarChart
            h={height}
            data={lineChartData}
            type="stacked"
            className="h-full"
            dataKey="label"
            // xAxisLabel="Index for the month"
            withLegend
            legendProps={{ verticalAlign: 'bottom', height: 50 }}
            valueFormatter={(value) => {
              return new Intl.NumberFormat(savedDelimiter, { useGrouping: savedGrouping === 'Yes' }).format(Number(value.toFixed(2)))
            }}
            unit={currency.symbol}
            series={linechartDataColors}
            tooltipProps={{
              // eslint-disable-next-line react/no-unstable-nested-components
              content: ({ label, payload }) => {
                return (
                  <ChartTooltip
                    label={label}
                    payload={payload}
                    currency={currency}
                  />
                )
              }
            }}
          />
        </div>
      )}
    </div>
  )
}
