import { useState, useEffect, useCallback } from 'react'
import {
  TLabelRuleDisplay,
  TLabelRule,
  TLabelRuleRulesDisplay,
  TLabelRuleEffectsDisplay
} from 'services/label.rule.interface'
import {
  GraphCardGroup,
  GraphCardWrapper,
  FETCH_STATUS,
  SkeletonWrapper,
  TTableColumn,
} from 'components'

import useLabelRules from 'hooks/useLabelRule'
import useAuthentication from 'hooks/useAuthentication'
import {
  formatNilValue
} from 'utils'
import { TutorialContainer } from 'features/projects/project-tutorial'
import GenericTableCard from './GenericFilterTableCard'
import { MutationNewLabelRulesModal } from './mutation-new-label-rule-model'
import { LABEL_RULES_FILTER_TABLE_COLUMNS, BLANK_DISPLAY_LABEL_RULE, LABEL_RULE_RULES_TABLE_COLUMNS, LABEL_RULE_EFFECTS_TABLE_COLUMNS } from './label.rule.constants'
import { castLabelRuleToDisplay, enableDisableLabelRule } from './label.rule.utils'
import EntityStatusCircle from '../../features/EntityStatusCircle'
import { tourConfig } from './tour'

function LabelRules() {
  const { currency } = useAuthentication()
  const { getLabelRulesFilter, deleteLabelRule, updateLabelRule } = useLabelRules()
  const [tutorialUpdater, setTutorialUpdater] = useState<boolean>(false)

  const changeTutorialUpdater = () => {
    setTutorialUpdater(true)
  }

  // ***********************************
  // Traffic light column for the status
  // ***********************************
  const trafficColumn: TTableColumn = {
    name: 'Status',
    field: 'enabled',
    width: '100px',
    format: formatNilValue,
    render: (__, { row }) => {
      return (
        <div>
          <EntityStatusCircle
            status={{ healthy: row.enabled, message: row.enabled ? `${row.name} Enabled` : `${row.name} Disabled` }}
          />
        </div>
      )
    }
  }

  // **********************
  // states for label rules
  // **********************
  const [initialLoading, setInitialLoading] = useState(false)
  const [fetchLabelRulesStatus, setFetchLabelRulesStatus] = useState(
    FETCH_STATUS.START
  )
  const [showMutationModal, setShowMutationModal] = useState(false)

  // **************
  // Selected Items
  // **************
  const [selectedLabelRuleFilter, setSelectedLabelRuleFilter] =
    useState<TLabelRuleDisplay>(BLANK_DISPLAY_LABEL_RULE)
  const [selectedLabelRuleFilterList, setSelectedLabelRuleFilterList] =
    useState<string[]>([BLANK_DISPLAY_LABEL_RULE.id])

  const [editingLabelRule, SetEditingLabelRule] = useState<
    TLabelRuleDisplay | undefined
  >(undefined)

  // ******************
  // store label rules
  // ******************
  const [labelRuleList, setLabelRuleList] = useState<TLabelRuleDisplay[]>([])

  // *********************************
  // Handlers for selected label rules
  // *********************************
  const handleSelectLabelRule = (id: string) => {
    const newSelected = labelRuleList.find((filter) => filter.id === id)
    if (newSelected) {
      setSelectedLabelRuleFilter(newSelected)
      setSelectedLabelRuleFilterList([newSelected.id])
    } else {
      setSelectedLabelRuleFilter(BLANK_DISPLAY_LABEL_RULE)
      setSelectedLabelRuleFilterList([BLANK_DISPLAY_LABEL_RULE.id])
    }
  }

  // **********************
  // Get the label rules
  // **********************
  const fetchLabelRules = useCallback(async () => {
    const newLabelRuleFilters = await getLabelRulesFilter(
      undefined,
      setFetchLabelRulesStatus
    )
    const result = castLabelRuleToDisplay(newLabelRuleFilters)
    setLabelRuleList(result)
    setInitialLoading(false)
  }, [getLabelRulesFilter])

  useEffect(() => {
    setInitialLoading(true)
    fetchLabelRules()
  }, [fetchLabelRules])

  // ********************************
  // Handlers for updated label rule
  // ********************************
  const handleMutatedLabelRule = async () => {
    fetchLabelRules()
    setSelectedLabelRuleFilter(BLANK_DISPLAY_LABEL_RULE)
    setInitialLoading(true)
  }

  // ********************************
  // Handlers for delete label rule
  // ********************************
  const handleDeleteLabelRule = async (item: TLabelRule) => {
    setSelectedLabelRuleFilter(BLANK_DISPLAY_LABEL_RULE)
    await deleteLabelRule(
      { labelRuleName: item.name },
      setFetchLabelRulesStatus
    )
    setInitialLoading(false)
  }

  // ********************************
  // Handlers for enable disable label rule
  // ********************************
  const handleEnableDisableLabelRule = async (item: TLabelRule) => {
    const updatedItem = enableDisableLabelRule(item)
    await updateLabelRule(
      { labelRuleName: updatedItem.name, body: updatedItem },
      setFetchLabelRulesStatus
    )
    setInitialLoading(false)
  }

  // useEffect(() => {
  //   console.log(labelRuleList)
  // })
  // **********************
  // Render the display
  // **********************

  return (
    <GraphCardWrapper>
      <SkeletonWrapper
        isLoading={initialLoading}
        count={2}
        inline
        height={40}
        className="w-48"
        containerClassName="flex justify-between"
      >
        <div className="flex items-center justify-between">
          <span className="text-xl font-medium">Label Rules</span>
          <button
            type="button"
            onClick={() => setShowMutationModal(true)}
            className="bg-[#668957] hover:bg-[#93ac89] text-white rounded-md py-2.5 px-7"
          >
            Create new label rule
          </button>
        </div>
      </SkeletonWrapper>

      <TutorialContainer
        tag="labelRules"
        updater={tutorialUpdater}
        tourConfig={
          tourConfig(
            // type,
            [
              () => { setShowMutationModal(true) },
              () => { setShowMutationModal(false) },
              changeTutorialUpdater,
            ]
          )
        }
      >
        <div data-tour="LabelRuleSelectMenu">

          <GraphCardGroup grow>
            <GenericTableCard<TLabelRuleDisplay>
              columns={[...LABEL_RULES_FILTER_TABLE_COLUMNS, trafficColumn]}
              showActionColumn
              initialLoading={initialLoading}
              currency={currency}
              records={labelRuleList}
              fetchStatus={fetchLabelRulesStatus}
              onFetch={fetchLabelRules}
              onSelect={handleSelectLabelRule}
              onEdit={SetEditingLabelRule}
              onDelete={handleDeleteLabelRule}
              onGeneric={handleEnableDisableLabelRule}
              selected={selectedLabelRuleFilterList}
            />
          </GraphCardGroup>
          <div className="flex flex-row gap-4">
            <div className="basis-1/2">
              {!!selectedLabelRuleFilter?.name && (
                <div className="w-full flex items-center text-lg font-medium text-gray-800">
                  <span>{selectedLabelRuleFilter.name}</span>
                  {!!selectedLabelRuleFilter.description && <span className="mx-1">-</span>}
                  {!!selectedLabelRuleFilter.description && (
                    <span>{selectedLabelRuleFilter.description}</span>
                  )}
                </div>
              )}
            </div>
          </div>
          <div className="flex flex-row gap-4">
            <div className="basis-1/2">
              <GraphCardGroup grow>
                <GenericTableCard<TLabelRuleRulesDisplay>
                  title="Rules"
                  showActionColumn={false}
                  columns={LABEL_RULE_RULES_TABLE_COLUMNS}
                  initialLoading={initialLoading}
                  currency={currency}
                  records={selectedLabelRuleFilter.rules}
                  fetchStatus={fetchLabelRulesStatus}
                />
              </GraphCardGroup>
            </div>
            <div className="basis-1/2">
              <GraphCardGroup grow>
                <GenericTableCard<TLabelRuleEffectsDisplay>
                  showActionColumn={false}
                  title="Effects"
                  columns={LABEL_RULE_EFFECTS_TABLE_COLUMNS}
                  initialLoading={initialLoading}
                  currency={currency}
                  records={selectedLabelRuleFilter.effects}
                  fetchStatus={fetchLabelRulesStatus}
                />
              </GraphCardGroup>
            </div>
          </div>
          {(showMutationModal || editingLabelRule) && (
            <MutationNewLabelRulesModal
              isOpen
              data={editingLabelRule}
              onMutated={handleMutatedLabelRule}
              onClose={() => {
                SetEditingLabelRule(undefined)
                setShowMutationModal(false)
              }}
            />
          )}
        </div>
      </TutorialContainer>
    </GraphCardWrapper>
  )
}

export default LabelRules
