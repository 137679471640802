/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useState } from 'react'
import { v4 as uuid } from 'uuid'
import { axios } from 'utils/axios'
// import { FaChartPie } from 'react-icons/fa'
import { TFocusGroup } from 'services/costhistory.interface'
import { useQuery } from 'react-query'
// import { IconWorldDollar } from '@tabler/icons-react'
import { FetchStatus } from 'components'
import useProject from 'hooks/useProject'
import { BackgroundImage } from '@mantine/core'
import { CHART_COLORS } from 'constants/color.constants'
import env from 'env'
import { query } from '../project-widget/widgetHelpFunctions'
import imageToAdd from '../../../assets/icons/websiteIcons/worldBackgroundImage.jpg'

export interface RegionPercentageProps {
  id: string,
  key: string
}
export function RegionPercentage({ id, key }: RegionPercentageProps) {
  const {
    fetchProjectsStatus,
  } = useProject()
  const [awsData, setAwsData] = useState<TFocusGroup[]>([])
  const [azureData, setAzureData] = useState<TFocusGroup[]>([])
  const [gcpData, setGcpData] = useState<TFocusGroup[]>([])
  const [awsDataProcessed, setAwsDataProcessed] =
    useState<boolean>(false)
  const [azureDataProcessed, setAzureDataProcessed] =
    useState<boolean>(false)
  const [gcpDataProcessed, setGcpDataProcessed] =
    useState<boolean>(false)
  const [awsQuery,] = useState<query>(
    {
      name: uuid(),
      description: '',
      options: {
        saveQuery: false,
        accumulate: true,
        aggregate: 'region',
        step: '1m',
        window: 'lastmonth'
      },
      filters: [{
        field: 'provider',
        value: 'AWS',
        operator: 'EQUALS'
      }
      ],
      limit: 10
    }
  )
  const [azureQuery,] = useState<query>(
    {
      name: uuid(),
      description: '',
      options: {
        saveQuery: false,
        accumulate: true,
        aggregate: 'region',
        step: '1m',
        window: 'lastmonth'
      },
      filters: [{
        field: 'provider',
        value: 'Azure',
        operator: 'EQUALS'
      }
      ],
      limit: 10
    }
  )
  const [gcpQuery,] = useState<query>(
    {
      name: uuid(),
      description: '',
      options: {
        saveQuery: false,
        accumulate: true,
        aggregate: 'region',
        step: '1m',
        window: 'lastmonth'
      },
      filters: [{
        field: 'provider',
        value: 'Google Cloud',
        operator: 'EQUALS'
      }
      ],
      limit: 10
    }
  )
  const changeQueryId = () => {
    console.log('placeholder')
  }
  const { error } = useQuery({
    queryKey: [`query?${JSON.stringify(awsQuery)}`],
    queryFn: async () => {
      try {
        if (!awsDataProcessed) {
          await axios
            .post('/query', awsQuery)
            .then((res) => {
              setAwsData(res.data.data)
              setAwsDataProcessed(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                setAwsData([])
                setAwsDataProcessed(true)
              }
            })
        }
        if (!azureDataProcessed) {
          await axios
            .post('/query', azureQuery)
            .then((res) => {
              setAzureData(res.data.data)
              setAzureDataProcessed(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                setAzureData([])
                setAzureDataProcessed(true)
              }
            })
        }
        if (!gcpDataProcessed) {
          await axios
            .post('/query', gcpQuery)
            .then((res) => {
              setGcpData(res.data.data)
              setGcpDataProcessed(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                setGcpData([])
                setGcpDataProcessed(true)
              }
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })

  // function getMaxChild(el: newTFocus) {
  //   const childrenNames = el.resources
  //   const bestChild = childrenNames.reduce((prev: TFocus, cur: TFocus) => {
  //     return prev.monthlyCost > cur.monthlyCost ? prev : cur
  //   })
  //   return { label: bestChild.serviceName, obj: bestChild }
  // }

  function getPercentages(data: TFocusGroup[]): { label: string, percentage: number }[] {
    if (data.length !== 0) {
      // const dataToWorkWith = data
      let totalCost = 0
      const results: { label: string, monthlyCost: number }[] = []
      data.forEach((el) => {
        const name = Object.getOwnPropertyNames(el)[0]
        const { totalEffectiveCost } = el[name]
        const cost = Number(totalEffectiveCost)
        const splitted = name.split('-')
        let newName = name === '' ? 'Taxes' : `${splitted[0]}-${splitted[1]}`
        if (splitted[1] === undefined && splitted[0] !== '') newName = `${splitted[0]}`
        const index = results.findIndex((el) => el.label === newName)
        totalCost += cost
        if (index !== -1) {
          results[index].monthlyCost += cost
        } else {
          results.push({ label: newName, monthlyCost: cost })
        }
      })
      const precentages: { label: string, percentage: number }[] = results.map((el) => {
        return { label: el.label, percentage: ((el.monthlyCost / totalCost) * 100) }
      }).filter((el) => Number(el.percentage.toFixed(2)) !== 0)
        .sort((a, b) => b.percentage - a.percentage)
      const splitted = precentages.slice(0, Number(env.REACT_APP_MAX_CHART_ITEMS))
      const restItems = precentages.slice(Number(env.REACT_APP_MAX_CHART_ITEMS))
      let restPercentage = 0
      restItems.forEach((item) => {
        restPercentage += item.percentage
      })
      return splitted.concat({
        label: 'Other',
        percentage: restPercentage
      })
    }
    return [{ label: '', percentage: 0 }]
  }

  const [savedDelimiter, setSavedDelimiter] = useState<string>('en-US');
  const [savedGrouping, setSavedGrouping] = useState<string>('Yes');

  window.addEventListener('storage', () => {
    if (sessionStorage.getItem('delimiter') === ',') {
      setSavedDelimiter('de-DE')
    } else {
      setSavedDelimiter('en-US')
    }
    if (sessionStorage.getItem('grouping') !== null) setSavedGrouping(sessionStorage.getItem('grouping')!)
  })

  if (error) return <div>An error has occurred</div>
  return (
    <div id={id} key={key} className="h-full">
      <div key={key} className="h-full">
        <FetchStatus status={fetchProjectsStatus} retry={changeQueryId}>
          <div className="flex h-full">
            <BackgroundImage
              mx="auto"
              className="h-full"
              src={imageToAdd}
            >
              <div className="w-full h-full p-2">
                <span className="font-semibold text-gray-600">Division of regions spend in last month:</span>
                <div className="flex h-full mt-2">
                  {[
                    { provider: 'AWS', data: awsData },
                    { provider: 'Azure', data: azureData },
                    { provider: 'Google Cloud', data: gcpData }
                  ].map((pair, idx) => {
                    return (
                      <div className={`w-1/3 h-4/5 ${idx !== 2 ? 'border-r border-gray-300' : ''} p-1`}>
                        <span className="text-gray-600 text-xs font-bold">{pair.provider}</span>
                        {
                          getPercentages(pair.data).map((el, idx) => {
                            return (
                              <div
                                className="flex"
                              >
                                <div style={{ border: `2px solid ${CHART_COLORS[idx]}`, borderRadius: '0.5rem' }} />
                                <div className="flex pl-1">
                                  <span className="text-pretty">
                                    <span className="text-gray-700 w-max font-semibold text-md">
                                      {
                                        new Intl.NumberFormat(savedDelimiter, {
                                          useGrouping: savedGrouping === 'Yes'
                                        }).format(Number(el.percentage.toFixed(2)))
                                      }%{' '}
                                    </span>
                                    <span className="text-gray-500 text-sm">
                                      {el.label !== '' ? el.label : 'Taxes'}
                                    </span>
                                  </span>
                                </div>
                              </div>
                            )
                          })
                        }
                      </div>
                    )
                  })}
                </div>
              </div>
            </BackgroundImage>
          </div>
        </FetchStatus>
      </div>
    </div>
  )
}
