import { useState, useEffect, useCallback } from 'react'
import { v4 as uuid } from 'uuid'

import {
  TReport,
  TReportFilter,
  TReportFilterDisplay
} from 'services/report.interface'
import {
  FETCH_STATUS,
  GraphCardGroup,
  GraphCardWrapper,
  SkeletonWrapper
} from 'components'
import useAuthentication from 'hooks/useAuthentication'
import useReport from 'hooks/useReport'
import { TutorialContainer } from 'features/projects/project-tutorial'
import MutationNewReportModal from './mutation-new-report-modal'
import ReportFiltersTableCard from './ReportFiltersTableCard'
// import { ReportEditor } from './ReportEditor'
import { DetailsModal } from './DetailsModal'
import { tourConfig } from './tour'
// import ReportEditor from './ReportEditor'

function AdvancedReporting() {
  const { currency } = useAuthentication()
  const { getReportFilters } = useReport()

  const [initialLoading, setInitialLoading] = useState(true)
  const [fetchReportFiltersStatus, setFetchReportFiltersStatus] = useState(
    FETCH_STATUS.START
  )
  const [showMutationModal, setShowMutationModal] = useState(false)
  const [reportFilters, setReportFilters] = useState<TReportFilterDisplay[]>([])
  const [editingReportFilter, setEditingReportFilter] = useState<
    TReportFilterDisplay | undefined
  >(undefined)
  const [selectedReportFilter, setSelectedReportFilter] =
    useState<TReportFilterDisplay>()
  const [, setReports] = useState<TReport[]>([])
  const [changedItem, setChangedItem] = useState<string>()
  const [tutorialUpdater, setTutorialUpdater] = useState<boolean>(false)

  const changeTutorialUpdater = () => {
    setTutorialUpdater(true)
  }

  // *****************************************
  // Callback to handle getting report filters
  // *****************************************
  const fetchReportFilters = useCallback(async () => {
    const newReportFilters = await getReportFilters(
      undefined,
      setFetchReportFiltersStatus
    )
    const result = newReportFilters.filter((item) => item.chart)
    await setReportFilters(result.map((item) => ({ ...item, id: uuid() })))
    setInitialLoading(false)
  }, [getReportFilters])

  // ********************************
  // Handle when reports are selected
  // ********************************
  const handleSelectReportFilter = (id: string) => {
    const newSelected = reportFilters.find((filter) => filter.id === id)
    // ******************
    // Get the chart data
    // ******************
    if (newSelected) setSelectedReportFilter(newSelected)
  }
  // ***************************************
  // Handle when reports filters are updated
  // ***************************************
  const handleMutatedReportFilter = async (
    { name }: TReportFilter,
    newReports: TReport[]
  ) => {
    // to avoid calling [fetchReportByFilter], don't set [filters]
    await setReports(newReports)
    await fetchReportFilters()
    const theId = reportFilters.find((filter) => filter.name === name)
    if (theId) setSelectedReportFilter(theId)
    setChangedItem(name)
  }

  // *******************************
  // Effect when reports are fetched
  // *******************************
  useEffect(() => {
    setInitialLoading(true)
    fetchReportFilters()
  }, [fetchReportFilters])
  useEffect(() => {
    if (changedItem !== undefined) {
      const theId = reportFilters.find((filter) => filter.name === changedItem)
      if (theId) setSelectedReportFilter(theId)
      setChangedItem(undefined)
    }
  })

  return (
    <GraphCardWrapper className="xs:p-1 tablet:p-5" noPadding>
      <SkeletonWrapper
        isLoading={initialLoading}
        count={2}
        inline
        height={20}
        className="w-48"
        containerClassName="flex justify-between"
      >
        <div className="flex items-center justify-between">
          <span className="text-xl font-medium">Reports</span>
          <button
            type="button"
            onClick={() => {
              const newEl: TReportFilterDisplay = {
                id: uuid(),
                name: ''
              }
              setEditingReportFilter(newEl)
              setShowMutationModal(true)
            }}
            className="bg-primary-color hover:bg-[#93ac89] text-white rounded-md py-2.5 px-7"
          >
            Create new report
          </button>
        </div>
      </SkeletonWrapper>

      <TutorialContainer
        tag="reports"
        updater={tutorialUpdater}
        tourConfig={
          tourConfig(
            // type,
            [
              () => { setShowMutationModal(true) },
              () => { setShowMutationModal(false) },
              changeTutorialUpdater,
            ]
          )
        }
      >
        <div data-tour="ReportSelectMenu">

          <GraphCardGroup>
            <ReportFiltersTableCard
              initialLoading={initialLoading}
              currency={currency}
              reportFilters={reportFilters}
              fetchStatus={fetchReportFiltersStatus}
              onFetch={fetchReportFilters}
              onEdit={setEditingReportFilter}
              onSelect={handleSelectReportFilter}
            />
          </GraphCardGroup>

          {selectedReportFilter && (
            <GraphCardGroup grow>
              <DetailsModal
                reportParam={selectedReportFilter}
                currency={currency}
                onClose={() => {
                  setSelectedReportFilter(undefined)
                }}
                onEdit={() => {
                  setShowMutationModal(true)
                  setEditingReportFilter(selectedReportFilter)
                  setSelectedReportFilter(undefined)
                }}
              />
            </GraphCardGroup>
          )}

          {(showMutationModal || editingReportFilter) && (
            <MutationNewReportModal
              isOpen
              data={editingReportFilter}
              onMutated={handleMutatedReportFilter}
              onClose={() => {
                setShowMutationModal(false)
                setEditingReportFilter(undefined)
              }}
            />
          )}
        </div>
      </TutorialContainer>
    </GraphCardWrapper>
  )
}

export default AdvancedReporting
