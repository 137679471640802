//--------------------------------------------------------------------------
// MakeTable.tsx
// Author: Wietse Van den Hove
// Property of CloudVue by dome.
//--------------------------------------------------------------------------

//--------------------------------------------------------------------------
// Imports
//--------------------------------------------------------------------------
import { useEffect, useMemo, useRef, useState } from 'react';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import 'mantine-react-table/styles.css';
import { Box, MantineProvider } from '@mantine/core';
// import { ModalsProvider, modals } from '@mantine/modals';
import { v4 as uuid } from 'uuid'
import { ruleTypeWithId } from 'pages/label-rules/queryHelperFunctions';
import { useQuery } from 'react-query';
import { graphQLAxios, axios } from 'utils';
import {
  MantineReactTable,
  MRT_Cell,
  MRT_ColumnFiltersState,
  MRT_ColumnOrderState,
  MRT_ExpandedState,
  MRT_GroupingState,
  MRT_PaginationState,
  // MRT_Row,
  MRT_TableInstance,
  MRT_VisibilityState,
  useMantineReactTable,
  type MRT_ColumnDef,
} from 'mantine-react-table';
import { GlobalFilterTableState } from '@tanstack/react-table';
import { costProperties, properties } from './dbMakeData';
import { fontAwesomeIcons } from './icons';
import { jsonData } from './constants';
import { OpenAdditionPopover } from './OpenAdditionPopover';
import { focusAllTrueColumns, focusInitialColumns } from './initialStates';
import { graphqlCallInterval, graphqlInitialCall } from './posts';

//--------------------------------------------------------------------------
// This is the interface for the table function, it accept the height of the
// parent component so that the table can correctly be changed to this length.
//--------------------------------------------------------------------------
interface MakeTableProps {
  height: number
  addLabelRuleCallBack: (rule: ruleTypeWithId) => void,
}
//--------------------------------------------------------------------------
// The function that creates the resource explorer sortable and filterable
// table.
//--------------------------------------------------------------------------
export function MakeTable({
  height,
  addLabelRuleCallBack,
}: MakeTableProps) {
  const [
    globalFilter, setGlobalFilters
  ] = useState<GlobalFilterTableState>()

  const [queryKey, setQueryKey] = useState<string>(uuid)
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
    focusInitialColumns
  );
  const [columnOrder, setColumnOrder] = useState<MRT_ColumnOrderState>(
    properties
  );
  const [cachedData, setCachedData] = useState<jsonData>({})
  const [
    grouping,
    // setGrouping
  ] = useState<MRT_GroupingState>(
    ['provider', 'serviceCategory', 'serviceName']
  );
  const [fullyExpanded, setFullyExpanded] = useState<string>('')
  const [expanded, setExpanded] = useState<MRT_ExpandedState>(
    {}
  );
  const [pagination, setPagination] = useState<MRT_PaginationState>(
    { pageIndex: 0, pageSize: 20 }
  );
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const [tableDataProcessed, setTableDataProcessed] = useState<boolean>(false)
  const [createTableData, setCreateTableData] = useState<boolean>(true)
  const [updateTableData, setUpdateTableData] = useState<boolean>(true)
  const [dataCount, setDataCount] = useState<number>(0)
  const [fetchGraphQlData, setFetchGraphQlData] = useState<boolean>(false)
  const [tableData, setTableData] = useState<jsonData[]>([])
  const [loadMoreData, setLoadMoreData] = useState<boolean>(false)
  const changeLoadMoreData = () => {
    if (fullyExpanded.split('>').length === grouping.length) {
      setQueryKey(uuid())
      setFetchGraphQlData(false)
      setLoadMoreData(true)
    }
  }
  const [applyFilterCtr, setApplyFilterCtr] = useState<number>(0)
  const addToFilterCtr = () => {
    setApplyFilterCtr(applyFilterCtr + 1)
  }
  const [
    columnFilters, setColumnFilters
  ] = useState<MRT_ColumnFiltersState>([
  ])
  useEffect(() => {
    if (columnOrder.length !== 0) {
      setUpdateTableData(false)
      setQueryKey(uuid())
    }
  }, [columnVisibility, columnOrder, grouping, expanded, pagination])

  const [savedDelimiter, setSavedDelimiter] = useState<string>('en-US');
  const [savedGrouping, setSavedGrouping] = useState<string>('Yes');

  window.addEventListener('storage', () => {
    if (sessionStorage.getItem('delimiter') === ',') {
      setSavedDelimiter('de-DE')
    } else {
      setSavedDelimiter('en-US')
    }
    if (sessionStorage.getItem('grouping') !== null) setSavedGrouping(sessionStorage.getItem('grouping')!)
  })
  useEffect(() => {
    if (sessionStorage.getItem('delimiter') === ',') {
      setSavedDelimiter('de-DE')
    } else {
      setSavedDelimiter('en-US')
    }
    if (sessionStorage.getItem('grouping') !== null) setSavedGrouping(sessionStorage.getItem('grouping')!)
  }, [savedDelimiter, savedGrouping, sessionStorage.getItem('delimiter'), sessionStorage.getItem('grouping')])

  useEffect(() => {
    if (cachedData.baseData) {
      setFetchGraphQlData(false)
      setDataCount(0)
      setTableData(cachedData.baseData.data)
      setQueryKey(uuid())
    }
  }, [applyFilterCtr])

  function deleteOldestExpanded(lst: string[]) {
    if (lst.length >= 2) {
      const first = lst[0].split('>')
      const second = lst[1].split('>')
      if (first.length === second.length && first.length === grouping.length) {
        const newExpanded: MRT_ExpandedState = {}
        const popped = lst.filter((el) => el !== fullyExpanded)
        popped.forEach((pop) => {
          newExpanded[pop as keyof typeof newExpanded] = true
        })
        setExpanded(newExpanded)
        setDataCount(0)
        return popped
      }
      return lst
    } return lst
  }

  function onExpandedClick() {
    if (Object.getOwnPropertyNames(expanded).length === 0) return
    const expandedProperties = Object.getOwnPropertyNames(expanded)
    const sortOnDeepestExpanded = expandedProperties.sort((a, b) => {
      const aValue = a.split('>')
      const bValue = b.split('>')
      return bValue.length - aValue.length
    })
    if (sortOnDeepestExpanded[0].split('>').length >= grouping.length) {
      const deletedList = deleteOldestExpanded(sortOnDeepestExpanded)[0]
      setFullyExpanded(deletedList)
    } else {
      setTableData(cachedData.baseData.data)
      setFullyExpanded('')
      setDataCount(0)
    }
  }

  function getExpandedIdValues() {
    if (Object.getOwnPropertyNames(expanded).length === 0) return []
    const expandedProperties = Object.getOwnPropertyNames(expanded)
    const sortOnDeepestExpanded = expandedProperties.sort((a, b) => {
      const aValue = a.split('>')
      const bValue = b.split('>')
      return bValue.length - aValue.length
    })
    const lastToExpand = sortOnDeepestExpanded[0]
    // setFullyExpanded(lastToExpand)
    const splitTheLastExpanded = lastToExpand.split('>')
    const result = splitTheLastExpanded.map((el) => {
      const split = el.split(':')
      const id = split[0]
      const value = split[1]
      return {
        id,
        value
      }
    })
    if (result.length < grouping.length) return []
    return result
  }

  function filtersToStringList() {
    const res: string[] = []
    const splitToFilter: MRT_ColumnFiltersState = getExpandedIdValues()
    splitToFilter.concat(columnFilters).forEach((filter) => {
      res.push(`"${filter.id.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()} LIKE '%${filter.value}%'"`)
    })
    return res
  }
  function generalFiltersToStringList() {
    const res: string[] = []
    if (globalFilter) {
      Object.getOwnPropertyNames(focusInitialColumns).forEach((column) => {
        res.push(`"${column.replace(/([a-z])([A-Z])/g, '$1_$2').toLowerCase()} LIKE '%${globalFilter}%'"`)
      })
    }
    return res
  }
  useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      try {
        if (!tableDataProcessed) {
          await axios
            .get('/store/resourceExplorerTableDetails')
            .then((res) => {
              const data = JSON.parse(res.data.storeValue)
              if (data.columnVisibility) setColumnVisibility(data.columnVisibility)
              // if (data.columnOrder) setColumnOrder(data.columnOrder)
              // if (data.grouping) setGrouping(data.grouping)
              // if (data.expanded) setExpanded(data.expanded)
              // if (data.pagination) {
              //   setPagination(data.pagination)
              //   resetInterval(data.pagination)
              // }
              setTableDataProcessed(true)
            })
            .catch(() => {
              setCreateTableData(false)
              setTableDataProcessed(true)
              setQueryKey(uuid())
            })
        }
        if (!createTableData) {
          setCreateTableData(true)
          await axios
            .post('/store/resourceExplorerTableDetails', {
              columnVisibility,
              columnOrder,
              grouping,
              expanded,
              pagination
            })
            .catch((error) => {
              console.log(error)
            })
        }
        if (!updateTableData) {
          setUpdateTableData(true)
          await axios
            .put('/store/resourceExplorerTableDetails', {
              columnVisibility,
              columnOrder,
              grouping,
              expanded,
              pagination
            })
            .catch((error) => {
              console.log(error)
            })
        }
        if (!fetchGraphQlData && columnFilters) {
          if (tableData.length === 0) {
            await graphQLAxios
              .post('', {
                query:
                  graphqlInitialCall
              })
              .then((res) => {
                setTableData(res.data.data.allFocusGroupings)
                const newCache = cachedData
                newCache.baseData = { data: res.data.data.allFocusGroupings, counter: 0 }
                setCachedData(newCache)
                setFetchGraphQlData(true)
              })
              .catch(
                () => {
                  console.log('cought')
                }
              )
          } else {
            const query = graphqlCallInterval(
              filtersToStringList(),
              generalFiltersToStringList(),
              dataCount,
              dataCount + 200,
              focusAllTrueColumns
            )
            setFetchGraphQlData(true)
            if (cachedData[`${fullyExpanded}${columnFilters}${globalFilter}`] && dataCount === 0) {
              setTableData(cachedData[`${fullyExpanded}${columnFilters}${globalFilter}`].data)
              setDataCount(cachedData[`${fullyExpanded}${columnFilters}${globalFilter}`].counter)
            } else if ((dataCount !== 0 && loadMoreData) || dataCount === 0) {
              await graphQLAxios
                .post('', { query })
                .then((res) => {
                  if (res.data.data.getFilteredIndexedFocusData.length !== 0) {
                    if (dataCount === 0) {
                      const { baseData } = cachedData

                      const idValuePairs = getExpandedIdValues()
                      const provider = idValuePairs.find((pair) => pair.id === 'provider')
                      const serviceCategory = idValuePairs.find((pair) => pair.id === 'serviceCategory')
                      const serviceName = idValuePairs.find((pair) => pair.id === 'serviceName')

                      const index = baseData.data.findIndex((el: jsonData) => {
                        if (provider && serviceCategory && serviceName) {
                          return el.provider === provider.value &&
                            el.serviceCategory === serviceCategory.value &&
                            el.serviceName === serviceName.value
                        }
                        return false
                      })
                      let before = []
                      if (index !== 0) before = baseData.data.slice(undefined, index)
                      const after = baseData.data.slice(index + 1, undefined)
                      const result =
                        before.concat(res.data.data.getFilteredIndexedFocusData).concat(after)
                      setTableData(result)
                      const newCache = cachedData
                      newCache[`${fullyExpanded}${columnFilters}${globalFilter}`] = {
                        data: result,
                        counter: 200
                      }
                      setCachedData(newCache)
                      setDataCount(dataCount + 200)
                    } else if (loadMoreData) {
                      setLoadMoreData(false)
                      const idValuePairs = getExpandedIdValues()
                      const provider = idValuePairs.find((pair) => pair.id === 'provider')
                      const serviceCategory = idValuePairs.find((pair) => pair.id === 'serviceCategory')
                      const serviceName = idValuePairs.find((pair) => pair.id === 'serviceName')

                      const index = tableData.findIndex((el: jsonData) => {
                        if (provider && serviceCategory && serviceName) {
                          return el.provider === provider.value &&
                            el.serviceCategory === serviceCategory.value &&
                            el.serviceName === serviceName.value
                        }
                        return false
                      })
                      const before: jsonData[] = tableData.slice(undefined, index + dataCount)
                      const after = tableData.slice(index + dataCount, undefined)
                      const result =
                        before.concat(res.data.data.getFilteredIndexedFocusData).concat(after)
                      setTableData(result)
                      const newCache = cachedData
                      newCache[`${fullyExpanded}${columnFilters}${globalFilter}`] = {
                        data: result,
                        counter: dataCount + 200
                      }
                      setCachedData(newCache)
                      setDataCount(dataCount + 200)
                    }
                  } else if (dataCount === 0) {
                    const { baseData } = cachedData
                    const newCache = cachedData
                    newCache[`${fullyExpanded}${columnFilters}${globalFilter}`] = {
                      data: baseData.data,
                      counter: 0
                    }
                    setCachedData(newCache)
                    setTableData(baseData.data)
                  }
                })
                .catch(
                  () => {
                    console.log('cought')
                  }
                )
            }
          }
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })
  useEffect(() => {
    if (tableData.length !== 0) {
      onExpandedClick()
      if (getExpandedIdValues().length === grouping.length) {
        setQueryKey(uuid())
        setFetchGraphQlData(false)
      }
    }
  }, [expanded])

  //--------------------------------------------------------------------------
  // Here the system loops over all the properties of the data, so that it
  // can create all the columns for this data.
  //--------------------------------------------------------------------------
  function AddTheLabelRuleInModal(cell: MRT_Cell<jsonData, unknown>) {
    const value: string = cell.getValue() as string
    const newRule = {
      tag: 'focus',
      id: uuid(),
      key: cell.column.id,
      value,
      operand: 'EQUALS'
    }
    addLabelRuleCallBack(newRule)
  }
  function createMeanAggregated(
    cell: MRT_Cell<jsonData, unknown>,
    table: MRT_TableInstance<jsonData>
  ) {
    return (
      <>
        Average by{' '}
        {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '}
        <Box style={{ color: '#668957', fontWeight: 'bold' }}>
          {cell.getValue<number>()?.toLocaleString?.(savedDelimiter, {
            useGrouping: savedGrouping === 'Yes',
            style: 'currency',
            currency: 'USD'
          })}
        </Box>
      </>
    )
  }
  function createCost(
    cell: MRT_Cell<jsonData, unknown>
  ) {
    return (
      <Box>
        <OpenAdditionPopover
          str={Number(cell.getValue<number>()).toLocaleString?.(savedDelimiter, {
            useGrouping: savedGrouping === 'Yes',
            style: 'currency',
            currency: 'USD'
          })}
          addLabelRuleCallBack={() => { AddTheLabelRuleInModal(cell) }}
        />
      </Box>
    )
  }

  function createNormalCell(
    cell: MRT_Cell<jsonData, unknown>
  ) {
    return (
      <Box>
        <OpenAdditionPopover
          str={cell.getValue<string | number>()}
          addLabelRuleCallBack={() => { AddTheLabelRuleInModal(cell) }}
        />
      </Box>
    )
  }

  function toFlat(str: string) {
    const camelCase = str.replace(/([a-z])([A-Z])/g, '$1 $2').split(' ')

    let flat = ''
    let initial = true

    camelCase.forEach((word) => {
      if (initial) {
        flat = flat.concat(word.charAt(0).toUpperCase()).concat(word.slice(1)).concat(' ')
        initial = false
      } else flat = flat.concat(word.charAt(0).toLowerCase()).concat(word.slice(1)).concat(' ')
    })
    return flat
  }

  const columnstest = useMemo<MRT_ColumnDef<jsonData>[]>(() => {
    return Object.getOwnPropertyNames(focusInitialColumns).map((property) => {
      const test = costProperties.findIndex((el) => el === property)
      const test2 = grouping.findIndex((el) => el === property)
      if (test !== -1) {
        return {
          header: toFlat(property),
          accessorKey: property,
          enableColumnFilter: test2 === -1,
          enableHiding: test2 === -1,
          enableGrouping: test2 !== -1,
          accessorFn: (row) => {
            if (row) return row[property]
            return undefined
          },
          aggregationFn: 'mean',
          AggregatedCell: ({ cell, table }) => createMeanAggregated(cell, table),
          Cell: ({ cell }) => createCost(cell),
        }
      }
      return {
        header: toFlat(property),
        accessorKey: property,
        enableColumnFilter: test2 === -1,
        enableHiding: test2 === -1,
        enableGrouping: test2 !== -1,
        accessorFn: (row) => {
          if (row) return row[property]
          return undefined
        },
        Cell: ({ cell }) => createNormalCell(cell),
      }
    })
  }, [tableData, savedDelimiter, savedGrouping])
  //--------------------------------------------------------------------------
  // Here we configure the table with the columns and the data.
  // We give it some extra styling properties, and make sure we can group
  // resize and have multiple pages.
  // here we also load in some custom Icons from font awesome, these can be
  // found in icons.tsx, a file provided in a tutorial on the page for the
  // table library: https://v2.mantine-react-table.com/docs/examples/aggregation-and-grouping
  // important to note is that we used v2 in this project, it is simply better.
  //--------------------------------------------------------------------------
  const tabletest = useMantineReactTable({
    columns: columnstest,
    data: tableData,
    manualGrouping: false,
    enableColumnResizing: true,
    enableRowVirtualization: true,
    enableColumnVirtualization: true,
    enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnOrdering: true,
    autoResetPageIndex: false,
    enablePagination: false,
    manualFiltering: true,
    positionToolbarAlertBanner: 'bottom',
    positionToolbarDropZone: 'bottom',
    onColumnVisibilityChange: setColumnVisibility,
    onColumnOrderChange: setColumnOrder,
    // onGroupingChange: setGrouping,
    onExpandedChange: setExpanded,
    onPaginationChange: setPagination,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilters,
    defaultColumn: {
      size: 200,
    },
    state: {
      isLoading: !fetchGraphQlData && tableData.length === 0,
      showProgressBars: !fetchGraphQlData && tableData.length !== 0,
      columnVisibility,
      columnOrder,
      grouping,
      expanded,
      pagination,
      columnFilters,
      globalFilter
    },
    globalFilterFn: 'contains',
    initialState: {
      density: 'xs',
      expanded: true,
      pagination,
    },
    icons: fontAwesomeIcons,
    mantineTableBodyCellProps: () => ({
      className: 'hover:cursor-pointer'

    }),
    mantineTableBodyRowProps: {
      className: 'p-5'
    },

    mantineToolbarAlertBannerBadgeProps: { color: '#668957', variant: 'outline' },
    mantineTableContainerProps: {
      ref: tableContainerRef,
      style: {
        maxHeight: (height * 7.2) / 10,
      },
    },
    mantinePaperProps: {
      shadow: 'none',
      className: 'rounded-lg',
      style: {
        border: '0px solid white',
      },
    },
  })

  //--------------------------------------------------------------------------
  // Return the configured table.
  //--------------------------------------------------------------------------
  return (
    <div className="w-full" data-tour="Table">
      <MantineProvider
        theme={{
          primaryColor: 'green',
          primaryShade: 8,
          colors: {
            green: [
              '#668957',
              '#668957',
              '#668957',
              '#668957',
              '#668957',
              '#668957',
              '#668957',
              '#668957',
              '#668957',
              '#668957'
            ],
          },
        }}
      >
        <MantineReactTable
          table={tabletest}
        />
      </MantineProvider>
      <div className="flex justify-center p-1 px-2">
        <div className="p-1 pr-4 border border-gray-200 rounded-lg shadow-md">
          <span className="text-sm text-gray-500 font-semibold px-0 text-wrap">
            Summary of the data currently being loaded:
          </span>
          <span className="text-xs text-gray-500 font-semibold px-0 text-wrap">
            {fullyExpanded.split('>').map((el) => {
              const split = el.split(':')
              return (
                <div>
                  {split[0]}: {split[1]}
                </div>
              )
            })}
          </span>
        </div>
        <button
          className={
            fullyExpanded.split('>').length === grouping.length ?
              'h-fit bg-primary-color hover:bg-[#93ac89] text-white p-2 rounded-lg m-1 mt-4' :
              'h-fit bg-gray-300 hover:bg-gray-300 text-white p-2 rounded-lg m-1 mt-4 hover:cursor-default'
          }
          type="button"
          onClick={changeLoadMoreData}
        >
          Load more data
        </button>
        <button
          className="h-fit bg-primary-color hover:bg-[#93ac89] text-white p-2 rounded-lg m-1 mt-4"
          type="button"
          onClick={addToFilterCtr}
        >
          Apply filters
        </button>
      </div>
    </div>
  )
}
