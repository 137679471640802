//--------------------------------------------------------------------------
// MakeTable.tsx
// Author: Wietse Van den Hove
// Property of CloudVue by dome.
//--------------------------------------------------------------------------

//--------------------------------------------------------------------------
// Imports
//--------------------------------------------------------------------------
import { useEffect, useMemo, useState } from 'react';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';
import 'mantine-react-table/styles.css';
import { Box, MantineProvider } from '@mantine/core';
import {
  MantineReactTable,
  MRT_Cell,
  MRT_TableInstance,
  useMantineReactTable,
  type MRT_ColumnDef,
} from 'mantine-react-table';
import { fontAwesomeIcons } from 'pages/resource-explorer/icons';
import { costProperties, numericColumns } from 'pages/resource-explorer/dbMakeData';
import { jsonData } from '../ReportEditor';
//--------------------------------------------------------------------------
// This is the interface for the table function, it accept the height of the
// parent component so that the table can correctly be changed to this length.
//--------------------------------------------------------------------------
interface MakeTableProps {
  height: number,
  tableData: jsonData[]
}
//--------------------------------------------------------------------------
// The function that creates the resource explorer sortable and filterable
// table.
//--------------------------------------------------------------------------
export function MakeTable({
  height,
  tableData
}: MakeTableProps) {
  const [savedDelimiter, setSavedDelimiter] = useState<string>('en-US');
  const [savedGrouping, setSavedGrouping] = useState<string>('Yes');
  useEffect(() => {
    if (sessionStorage.getItem('delimiter') === ',') {
      setSavedDelimiter('de-DE')
    } else {
      setSavedDelimiter('en-US')
    }
    if (sessionStorage.getItem('grouping') !== null) setSavedGrouping(sessionStorage.getItem('grouping')!)
  }, [savedDelimiter, savedGrouping, sessionStorage.getItem('delimiter'), sessionStorage.getItem('grouping')])

  //--------------------------------------------------------------------------
  // Here the system loops over all the properties of the data, so that it
  // can create all the columns for this data.
  //--------------------------------------------------------------------------
  function createMeanAggregated(
    cell: MRT_Cell<jsonData, unknown>,
    table: MRT_TableInstance<jsonData>
  ) {
    return (
      <>
        Average by{' '}
        {table.getColumn(cell.row.groupingColumnId ?? '').columnDef.header}:{' '}
        <Box style={{ color: 'green', fontWeight: 'bold' }}>
          {cell.getValue<number>()?.toLocaleString?.(savedDelimiter, {
            style: 'currency',
            currency: 'USD',
            useGrouping: savedDelimiter === 'Yes'
          })}
        </Box>
      </>
    )
  }
  const columnstest = useMemo<MRT_ColumnDef<jsonData>[]>(() => {
    return Object.getOwnPropertyNames(tableData[0]).map((property) => {
      const test = costProperties.findIndex((el) => el === property)
      if (test !== -1) {
        return {
          header: property,
          accessorKey: property,
          accessorFn: (row) => row[property],
          aggregationFn: 'sum',
          AggregatedCell: ({ cell, table }) => createMeanAggregated(cell, table),
          Cell: ({ cell }) => cell.getValue<number>()?.toLocaleString?.(savedDelimiter, {
            style: 'currency',
            currency: 'USD',
            useGrouping: savedGrouping === 'Yes'
          })
        }
      }
      const test2 = numericColumns.findIndex((el) => el === property)
      if (test2 !== -1) {
        return {
          header: property,
          accessorKey: property,
          accessorFn: (row) => row[property],
          Cell: ({ cell }) => cell.getValue<number>()?.toLocaleString?.(savedDelimiter, {
            useGrouping: savedGrouping === 'Yes'
          })
        }
      }
      return {
        header: property,
        accessorKey: property,
        accessorFn: (row) => row[property],
      }
    })
  }, [])

  //--------------------------------------------------------------------------
  // Here we configure the table with the columns and the data.
  // We give it some extra styling properties, and make sure we can group
  // resize and have multiple pages.
  // here we also load in some custom Icons from font awesome, these can be
  // found in icons.tsx, a file provided in a tutorial on the page for the
  // table library: https://v2.mantine-react-table.com/docs/examples/aggregation-and-grouping
  // important to note is that we used v2 in this project, it is simply better.
  //--------------------------------------------------------------------------
  const tabletest = useMantineReactTable({
    columns: columnstest,
    data: tableData,
    enableColumnResizing: true,
    enableGrouping: true,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableColumnOrdering: true,
    globalFilterFn: 'contains',
    initialState: {
      density: 'xs',
      expanded: true,
      pagination: { pageIndex: 0, pageSize: 20 },
    },
    icons: fontAwesomeIcons,
    mantineTableBodyCellProps: () => ({
      className: 'hover:cursor-pointer'

    }),

    mantineToolbarAlertBannerBadgeProps: { color: '#668957', variant: 'outline' },
    mantineTableContainerProps: { style: { maxHeight: height } }
  })
  //--------------------------------------------------------------------------
  // Return the configured table.
  //--------------------------------------------------------------------------
  return (
    <div className="w-full">
      <MantineProvider
        theme={{
          primaryColor: 'green',
          primaryShade: 8,
          colors: {
            green: [
              '#668957',
              '#668957',
              '#668957',
              '#668957',
              '#668957',
              '#668957',
              '#668957',
              '#668957',
              '#668957',
              '#668957'
            ],
          },
        }}
      >
        <MantineReactTable table={tabletest} />
      </MantineProvider>
    </div>
  )
}
