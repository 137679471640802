/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { getLast6MonthsWithCur } from 'features/projects/project-datepicker/helpFunction';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { toast, Bounce } from 'react-toastify';
import { graphQLAxios } from 'utils';
import { BarChart } from '@mantine/charts';
import { Switch } from '@mantine/core';
import useAuthentication from 'hooks/useAuthentication';
import { GET_CHART_COLORS } from 'constants/color.constants';
import { BudgetsWithBilledCostsApiCall } from '../graphQlApiCalls';
import { ChartTooltip } from './ServiceCostsRegion';
import { monthNames } from './ServiceCategoryCosts';

type BudgetsWithBilledCostsType = {
  billingPeriodEnd: string
  billingPeriodStart: string
  provider: string
  totalBilledCost: number
}
interface BudgetsWithBilledCostsProps {
  id: string,
  height: number
}
type chartDataType = {
  [key: string]: string | number
  month: string,
}
type chartDataColors = { name: string, color: string }
export function BudgetsWithBilledCosts({ id, height }: BudgetsWithBilledCostsProps) {
  const { currency } = useAuthentication()
  const timeWindow = getLast6MonthsWithCur()
  const [dataLoaded, setDataLoaded] = useState<boolean>(false)
  const [queryKey] = useState<string>(`initial-${id}`)
  const [data, setData] = useState<BudgetsWithBilledCostsType[]>([])
  const [chartData, setChartData] = useState<chartDataType[]>([])
  const [chartDataColors, setChartDataColors] = useState<chartDataColors[]>([])

  const [savedDelimiter, setSavedDelimiter] = useState<string>('en-US');
  const [savedGrouping, setSavedGrouping] = useState<string>('Yes');
  const [savedBudget, setSavedBudget] = useState<number>(0);

  window.addEventListener('storage', () => {
    if (sessionStorage.getItem('delimiter') === ',') {
      setSavedDelimiter('de-DE')
    } else {
      setSavedDelimiter('en-US')
    }
    if (sessionStorage.getItem('grouping') !== null) setSavedGrouping(sessionStorage.getItem('grouping')!)
    if (sessionStorage.getItem('budget') !== null) setSavedBudget(Number(sessionStorage.getItem('budget')!))
  })

  useEffect(() => {
    if (sessionStorage.getItem('delimiter') === ',') {
      setSavedDelimiter('de-DE')
    } else {
      setSavedDelimiter('en-US')
    }
    if (sessionStorage.getItem('grouping') !== null) setSavedGrouping(sessionStorage.getItem('grouping')!)
    if (sessionStorage.getItem('budget') !== null) setSavedBudget(Number(sessionStorage.getItem('budget')!))
  }, [savedDelimiter, savedGrouping, sessionStorage.getItem('delimiter'), sessionStorage.getItem('grouping')])

  const { error } = useQuery({
    queryKey: [queryKey],
    queryFn: async () => {
      try {
        if (!dataLoaded) {
          const query =
            BudgetsWithBilledCostsApiCall(timeWindow[0].toISOString().replace('T', ' ').replace('Z', ''), timeWindow[1].toISOString().replace('T', ' ').replace('Z', ''))
          await graphQLAxios
            .post('', { query })
            .then((res) => {
              setData(res.data.data.budgetsWithBilledCosts)
              setDataLoaded(true)
            })
            .catch((error) => {
              if (error.response.data.data) {
                toast.error('No labels have been created.', {
                  position: 'top-right',
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  transition: Bounce,
                })
              }
            })
        }
      } catch (error) {
        throw new Error(`Error code ${error}`)
      }
    }
  })
  useEffect(() => {
    const newData: chartDataType[] = []
    const newDataColors: chartDataColors[] = []
    data.forEach((el) => {
      const month = new Date(el.billingPeriodEnd).getMonth() - 1
      const idx = newData.findIndex((obj) => obj.month === month.toString())
      const idxColors = newDataColors.findIndex((obj) => obj.name === el.provider)
      if (idx === -1) {
        newData.push({
          month: month.toString(),
          [el.provider]: el.totalBilledCost
        })
      } else {
        const newObj = newData[idx]
        newObj[el.provider] = el.totalBilledCost
        newData[idx] = newObj
      }
      if (idxColors === -1) {
        newDataColors.push({
          name: el.provider,
          color: GET_CHART_COLORS(newDataColors.length)
        })
      }
    })
    setChartData(newData.sort((a, b) => Number(a.month) - Number(b.month)).map((el) => {
      el.month = monthNames[Number(el.month)]
      return el
    }))
    setChartDataColors(newDataColors)
  }, [data])

  const [checked, setChecked] = useState(true);

  if (error) return <div>An error has occurred</div>
  return (
    <div>
      <Switch
        className="p-2"
        size="md"
        color="#668957"
        checked={checked}
        onChange={(event) => setChecked(event.currentTarget.checked)}
        offLabel="Off"
        label="Stacked"
        onLabel="On"
      />
      <BarChart
        h={height}
        data={chartData}
        className="h-full"
        dataKey="month"
        type={checked ? 'stacked' : 'default'}
        // xAxisLabel="Index for the month"
        series={chartDataColors}
        valueFormatter={(value) => {
          return new Intl.NumberFormat(savedDelimiter, { useGrouping: savedGrouping === 'Yes' }).format(Number(value.toFixed(2)))
        }}
        unit={currency.symbol}
        referenceLines={[
          {
            y: savedBudget,
            color: 'red.5',
            label: 'Budget',
          },
        ]}
        withLegend
        legendProps={{ verticalAlign: 'bottom', height: 50 }}
        barProps={{ fillOpacity: '80%' }}
        tooltipProps={{
          // eslint-disable-next-line react/no-unstable-nested-components
          content: ({ label, payload }) => {
            return <ChartTooltip label={label} payload={payload} currency={currency} />
          }
        }}
      />
    </div>
  )
}
