/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FetchStatus } from 'components'
import useProject from 'hooks/useProject'
import { TCurrency } from 'services/cost.interface'
// import { FaMoneyBills } from 'react-icons/fa6'
import { useEffect, useState } from 'react'
import { forecasting } from 'components/top-bar'

export interface PredictionSpendProps {
  id: string,
  key: string,
  currency: TCurrency,
}
export function PredictionSpend({
  id, key, currency
}: PredictionSpendProps) {
  const {
    fetchProjectsStatus,
  } = useProject()

  const [savedForecasting, setSavedForecasting] = useState<forecasting[]>();

  window.addEventListener('forecasting', () => {
    if (!savedForecasting) {
      if (sessionStorage.getItem('forecasting') !== null && sessionStorage.getItem('forecasting')) {
        const result: forecasting[] = JSON.parse(sessionStorage.getItem('forecasting')!)
        setSavedForecasting(result)
      }
    }
  })

  useEffect(() => {
    if (!savedForecasting) {
      if (sessionStorage.getItem('forecasting') !== null && sessionStorage.getItem('forecasting')) {
        const result: forecasting[] = JSON.parse(sessionStorage.getItem('forecasting')!)
        setSavedForecasting(result)
      }
    }
  }, [savedForecasting])
  function accumulate() {
    let totalCost = 0
    if (savedForecasting) {
      savedForecasting.forEach((cloud) => {
        totalCost += cloud.forecastMonth
      })
    }
    return totalCost
  }
  const [savedDelimiter, setSavedDelimiter] = useState<string>('en-US');
  const [savedGrouping, setSavedGrouping] = useState<string>('Yes');

  window.addEventListener('storage', () => {
    if (sessionStorage.getItem('delimiter') === ',') {
      setSavedDelimiter('de-DE')
    } else {
      setSavedDelimiter('en-US')
    }
    if (sessionStorage.getItem('grouping') !== null) setSavedGrouping(sessionStorage.getItem('grouping')!)
  })
  return (
    <div id={id} key={key} className="p-2 h-full">
      <div key={key} className="h-full">
        <FetchStatus status={fetchProjectsStatus}>
          <div className="h-full">
            <div className="h-full">
              <div>
                <span className="text-gray-600 w-max font-semibold text-lg">
                  {currency.symbol}
                  {
                    new Intl.NumberFormat(savedDelimiter, {
                      useGrouping: savedGrouping === 'Yes'
                    }).format(accumulate())
                  }
                </span>
                <span className="text-gray-400 text-xs px-2">Predicted spendings for this month</span>
              </div>
              <div className="flex">
                {savedForecasting &&
                  savedForecasting.sort((a, b) => b.forecastMonth - a.forecastMonth)
                    .map((cloud, idx) => {
                      return (
                        <div className="pr-2">
                          <span className="text-gray-600 w-max font-semibold text-md">
                            {currency.symbol}
                            {
                              new Intl.NumberFormat(savedDelimiter, {
                                useGrouping: savedGrouping === 'Yes'
                              }).format(cloud.forecastMonth)
                            }
                          </span>
                          <span className="text-gray-400 text-xs px-1">{cloud.provider} {idx !== 2 ? ',' : ''}</span>
                        </div>
                      )
                    })}
              </div>
            </div>
          </div>
        </FetchStatus>
      </div>
    </div>
  )
}
