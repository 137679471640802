import { useState, ChangeEvent, InputHTMLAttributes, forwardRef, useEffect } from 'react'
// import { ChangeEvent, useMemo, useState } from 'react'
import { mapClasses } from 'utils'

interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  size?: 'sm' | 'md' | 'lg'
  readonlyName?: boolean
  label?: string
  pattern?: string
  errorMessage?: string
  containerClassName?: string
  onValidated?: (isValid: boolean) => void
}
const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      size = 'lg',
      label = '',
      containerClassName,
      className,
      readonlyName,
      pattern,
      errorMessage,
      onValidated,
      ...props
    },
    ref
  ) => {
    const [isValid, setIsValid] = useState<boolean>(props.value?.toString().length !== 0)
    useEffect(() => {
      setIsValid(props.value?.toString().length !== 0)
    }, [props.value])
    const { onChange, ...newProps } = props;
    // const re = /^[a-z0-9][a-z0-9.-]*[a-z0-9]$/
    const handleValidate = (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target
      // Validate the key
      if (pattern) {
        const regex = new RegExp(pattern)
        const isValid = regex.test(value)
        setIsValid(isValid)
        if (onValidated) onValidated(isValid)
      } else {
        setIsValid(true)
        if (onValidated) onValidated(true)
      }
    }
    return (
      <div className={mapClasses('flex flex-col w-full', containerClassName)}>
        {label && (
          <span className="flex text-gray-500 text-xs font-normal mb-1">
            {label}
            {!isValid && (
              <span className="ml-2 block text-xs text-red-500">
                ({errorMessage})
              </span>
            )}
          </span>
        )}
        <input
          ref={ref}
          disabled={readonlyName}
          className={mapClasses(
            'bg-transparent border focus-visible:outline-none ',
            !isValid ? 'border-red-500' : 'border-dark-grey focus:border-blue-500',
            size === 'lg' && 'h- px-3 py-1.5',
            size === 'md' && 'px-3 py-1',
            size === 'sm' && 'px-2 py-1 text-sm',
            'rounded-lg w-full',
            className
          )}
          onChange={(event) => {
            handleValidate(event)
            if (onChange) onChange(event)
          }}
          {...newProps || props}
        />
      </div>
    )
  }
)

export default Input
