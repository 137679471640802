export const tourConfig = (id: string, callbackList: (() => void)[]) => {
  return [
    {
      selector: `[data-tour="groupByWidgetBody${id}"]`,
      content: 'Welcome to our group by widget! This widget will allow you to, as the name suggests, group your data by a specific label or focus group.',
      action: () => {
        callbackList[5]()
      }
    },
    {
      selector: `[data-tour="labelSelectForLabeledDataChart${id}"]`,
      content: 'Here it is possible to select a label or field value. This will then fetch the data for that specific value aggregated over the selected month.'
    },
    {
      selector: `[data-tour="groupByChart${id}"]`,
      content: 'Afterwards your data will be represented here in the form of a pie chart.',
      action: () => {
        callbackList[6]()
      }
    },
    {
      selector: `[data-tour="accumulateTrueChartPath${id}"]`,
      content: 'When having closed the edit view, you can easily see the options selected by looking at this summary.'
    },
    {
      selector: `[data-tour="theOptionsButtonFor${id}"]`,
      content: 'Press this to open options, here you can change and delete the widget.',
      action: () => { callbackList[0]() }
    },
    {
      selector: `[data-tour="anOptionLabelAdd date rangeOf${id}"]`,
      content: 'Pressing this will change the widget to a time interval view of the data.',
      // action: () => { callbackList[0]() }
    },
    {
      selector: `[data-tour="LabeledDataChart${id}"]`,
      content: 'This is said view.',
      action: () => {
        callbackList[5]()
        callbackList[1]()
        callbackList[2]()
      }
    },
    {
      selector: `[data-tour="labelSelectForLabeledDataChart${id}"]`,
      content: 'Here again it is possible to select a label or field value. However a key difference is that you can select a date range and not just "this" or "last" month.'
    },
    {
      selector: `[data-tour="groupByChart${id}"]`,
      content: 'Afterwards your data will be represented here in the form of a stacked bar chart.',
      action: () => {
        callbackList[6]()
      }
    },
    {
      selector: `[data-tour="LabeledDataChart${id}"]`,
      content: 'Enjoy discovering your data',
      action: () => {
        callbackList[3]()
        callbackList[4]()
      }
    }
  ]
}
