// Creates a big amount of colours that have to be different. These colours are a bit guided
// in their values to make them still look good.==
// import { DEFAULT_THEME } from '@mantine/core';
import { colors } from './colors';

function CREATE_CHART_COLORS() {
  // const res: string[] = [
  //   '#658856',
  //   '#1acc1a',
  //   '#943bc6',
  //   '#087394',
  //   '#c78bb9',
  //   '#997cfb',
  //   '#4d8d44',
  //   '#702cb4',
  //   '#58b5e1',
  //   '#465bcc',
  //   '#735377',
  //   '#8b61e3',
  //   '#1c4bb4',
  //   '#306ec3',
  //   '#b956c4',
  //   '#6f6ce3',
  //   '#b849d8',
  //   '#7e47cc',
  //   '#2389ae',
  //   '#349332',
  //   '#3d9fc7',
  //   '#0f7f6d',
  //   '#158c47',
  //   '#dc58ea',
  //   '#4492d2',
  // ];
  return colors
}
export const CHART_COLORS = CREATE_CHART_COLORS()
export function GET_CHART_COLORS(idx: number) {
  const { length } = CHART_COLORS
  const comma = (idx / length).toString().split('.')
  if (Number(`0.${comma[1]}`)) {
    if (Number(comma[0])) return CHART_COLORS[Math.round(Number(`0.${comma[1]}`) * length)][Number(comma[0])]
    return CHART_COLORS[Math.round(Number(`0.${comma[1]}`) * length)][0]
  } if (Number(comma[0])) return CHART_COLORS[0][Number(comma[0])]
  return CHART_COLORS[0][0]
}
