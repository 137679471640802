import { Checkbox, Tabs, Image } from '@mantine/core'
import { IconSettings, IconSettingsOff } from '@tabler/icons-react'
import { FaPlus } from 'react-icons/fa';
import { useEffect, useState } from 'react';
import { addWidgetFilterStrings, addWidgetFilterType, listOfWidgets, widgetAddType } from './data';

interface WidgetAddBarProps {
  addCallback: (str: string) => void
}
export function WidgetAddBar({ addCallback }: WidgetAddBarProps) {
  const iconStyle = { width: 20, height: 20 };
  const [addWidgetFilters, setAddWidgetFilters] = useState<addWidgetFilterType[]>(
    addWidgetFilterStrings.map((el) => {
      return {
        tag: el,
        checked: false
      }
    })
  )
  const [savedPersona, setSavedPersona] = useState<string>('');
  const [tabValue, setTabValue] = useState<string>('Predefined');

  window.addEventListener('storage', () => {
    if (sessionStorage.getItem('grouping') !== null) setSavedPersona(sessionStorage.getItem('grouping')!)
  })

  useEffect(() => {
    if (sessionStorage.getItem('grouping') !== null) setSavedPersona(sessionStorage.getItem('persona')!)
  }, [savedPersona, sessionStorage.getItem('persona')])

  // const { currency } = useAuthentication()

  function displayListOfWidgetsFor(type: string) {
    return (
      [listOfWidgets.find((el) => el.label === type)].map((el) => {
        if (el) {
          return (
            <div className=" xs:h-[260px] md:h-[307px] overflow-auto">
              {el.lst.filter(
                (widget) => widget.widgetTags.find((tag) => tag === savedPersona) !== undefined
              ).filter((widget) => {
                if (addWidgetFilters.find((filter) => filter.checked)) {
                  return addWidgetFilters.filter((filter) => {
                    return widget.widgetTags.find((tag) => tag === filter.tag && filter.checked)
                  }).length !== 0
                }
                return true
              }).map((widget: widgetAddType) => {
                return (
                  <div className="border-gray-300 border rounded-lg m-2 flex shadow-md">
                    <div className="w-3/4 border-gray-300 border-r-2 p-2">
                      <div className="text-lg font-semibold w-full flex justify-between">{widget.widgetName}
                        <button type="button" id={`${widget.widgetName} - addButton`} onClick={() => { addCallback(widget.addType) }}>
                          <FaPlus size={10} />
                        </button>
                      </div>
                      <div className="w-full flex justify-between">
                        <div className="text-sm font-light text-wrap  border-gray-300"> <span className="font-semibold">Description: </span>{widget.widgetDescription}
                          {widget.link && (
                            <span
                              className="font-semibold hover:cursor-pointer"
                              onClick={() => {
                                window.open(widget.link)
                              }}
                            >
                              {' click here for more information'}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="text-sm font-light text-wrap w-1/4 p-2">
                      <Image radius="xs" h={150} w="auto" fit="contain" src={widget.imageString} alt="little mockup" />
                    </div>
                  </div>
                )
              })}
            </div>
          )
        } return <div />
      })
    )
  }
  return (
    <div className="flex w-full justify-center">
      <div className="md:flex flex-row bg-white rounded-lg w-10/12 border-b border-[#5b7b4e] rounded-lg justify-center">
        <div className="bg-gray-200 md:h-[350px] md:w-[250px] rounded-l-lg">
          <span className="text-md font-semibold w-full flex justify-between p-2 shadow-sm">Filters: </span>
          <div className="h-[310px] border-gray-400 border-r-2 pl-4">
            {addWidgetFilters.map((filter, idx) => {
              return (
                <Checkbox
                  className="p-1"
                  size="md"
                  radius="md"
                  checked={filter.checked}
                  color="#668957"
                  onClick={() => {
                    const newFilter = {
                      tag: filter.tag,
                      checked: !filter.checked
                    }
                    const newFilters =
                      addWidgetFilters.slice(0, idx)
                        .concat(newFilter)
                        .concat(addWidgetFilters.slice(idx + 1, addWidgetFilters.length))
                    setAddWidgetFilters(newFilters)
                  }}
                  label={filter.tag}
                />
              )
            })}
          </div>
        </div>
        <div className="bg-white h-[350px] w-full rounded-lg">
          <Tabs defaultValue="Predefined" color="#668957" className=" rounded-lg h-full">
            <Tabs.List className="bg-gray-200 rounded-lg" style={{ '--tab-border-color': '#9ca3af' }}>
              <Tabs.Tab
                value="Predefined"
                className={`text-md font-semibold ${tabValue === 'Predefined' ? 'text-[#668957]' : ''}`}
                leftSection={<IconSettingsOff style={iconStyle} />}
                onClick={() => {
                  setTabValue('Predefined')
                }}
              >
                Predefined
              </Tabs.Tab>
              <Tabs.Tab
                value="Configurable"
                className={`text-md font-semibold ${tabValue === 'Configurable' ? 'text-[#668957]' : ''}`}
                leftSection={<IconSettings style={iconStyle} />}
                onClick={() => {
                  setTabValue('Configurable')
                }}
              >
                Configurable
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="Predefined">
              {displayListOfWidgetsFor('Predefined')}
            </Tabs.Panel>

            <Tabs.Panel value="Configurable">
              {displayListOfWidgetsFor('Configurable')}
            </Tabs.Panel>
          </Tabs>
        </div>
      </div>
    </div>
  )
}
